import clsx from 'clsx'
import React, {FC} from 'react'

// scss
import './ActionsDropdown.scss'

// images
import MenuIcon from '../../../_metronic/assets/icons/menu.svg'

// custom
import {SVGICON} from '../../../_metronic/helpers'
import { enablePermissionForCrud } from '../../utils/PermisisionEnabledForResource'
import { RootState } from '../../../setup'
import { useSelector } from 'react-redux'
import { RoleType } from '../../modules/RolesAndPermissions/types/getRolesResponseType'

interface DropdownOption {
  name: string
  onClick: () => void
  className?: string
}

interface Props {
  options: Array<DropdownOption | null>,
  resource_slug?: string
}

const ActionsDropdown: FC<Props> = ({options,resource_slug}) => {
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, resource_slug))
 const crudPermissionForTask: any =((enablePermissionForCrud(userRoleDetails, "tasks")))

  return (
    <>
     { ((resource_slug==="tasks" && crudPermissionForTask?.read) || (crudPermission?.edit || crudPermission?.delete)) && <button
        type='button'
        className='btn btn-sm btn-icon btn-light btn-active-light-primary me-4 dropdown-toggl'
        id='dropdownMenuButton1'
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
        <SVGICON src={MenuIcon} />
      </button>}
      <div
        className='dropdown-menu  menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4 custom-dropdown-transform'
        aria-labelledby='dropdownMenuButton1'
      >
        {options.map((option, i) => {
          if (option)
            return (
              <div
                key={i}
                className='menu-item px-3'
                onClick={() => {
                  option.onClick?.()
                }}
              >
                <span className={`menu-link px-3 ${option.className || ''}`}>{option.name}</span>
              </div>
            )
        })}
        {/* <div
          className='menu-item px-3'
          onClick={() => {
            onEdituserClick()
          }}
        >
          <span className='menu-link px-3'>Edit</span>
        </div>
        {loggedUser?.id !== userDetails?.id && (
          <div
            className='menu-item px-3'
            onClick={() => {
              showDeleteConfirmModal(
                'Are you sure you want to deletethis user?',
                deleteSelectedUser
              )
            }}
          >
            <span className='menu-link px-3 text-danger'>Delete</span>
          </div> */}
        {/* )} */}
      </div>
    </>
  )
}

export default ActionsDropdown
