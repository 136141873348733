import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  DealPipelineDetailsType,
  DealType,
  LogType,
} from "../types/DealResponseType";
import {
  AcceptApproval,
  addresponsible_Persons,
  changePhase,
  dealMoveToPipeline,
  deleteDeal,
  deleteFieldFile,
  deleteresponsible_Persons,
  getDealDetails,
  getDealPipelineDetailsById,
  getDealsTabByID,
  getTabFieldLogs,
  storeDealFieldValue,
  updateDeal,
  updateTag,
} from "../redux/DealsAPI";
import { useIntl } from "react-intl";
import { KTSVG, SVGICON } from "../../../../_metronic/helpers";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import clsx from "clsx";
import { TabType } from "../../projectMasterData/types/TabsResponseType";
import TabPreview from "../../projectMasterData/components/TabPreview";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import ErrorModal from "./ErrorModal";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../setup";
import { FieldType } from "../../projectMasterData/types/FieldsReponseTypes";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import { actions } from "../../projectMasterData/redux/ContentMasterRedux";
import { actions as DealActions } from "../redux/DealsRedux";
// images
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import FolderIcon from "../../../../_metronic/assets/icons/folder.svg";
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import SelectedUsersModal from "../../teams/components/SelectUsersModal";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
// import Chart from "./Chart";
// import * as am5 from "@amcharts/amcharts5";
import { KTUtil } from "../../../../_metronic/assets/ts";
import SelectResponsibles from "./SelectResponsibles";
import SelectTeamsModal from "./SelectTeamsModal";
import axios from "axios";
import FieldLogsModal from "./FieldLogsModal";
import { SuccessAlert, errorAlert } from "../../../sharedComponents/Alert";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import { TagModalProps, TagsModal } from "../../masterdata/components/Tags";
import { getTagsList } from "../../masterdata/redux/TagsAPI";
import { actions as TagActions } from "../../masterdata/redux/MasterdataRedux";
import EndPhaseReasonModal from "./EndPhaseReasonModal";
import AcceptDealModal from "./AcceptDealModal";
import ActivityComponent from "./ActivityComponent";
import DealProjectFiles from "./DealProjectFiles";
import DealLocation from "./DealLocationCard";
import { FieldLogsContext } from "./context";
import EndPhaseTransitionModal from "./EndPhaseTransitionModal";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { ApprovalModalProps } from "../types/DealPropsType";
import { getPipelineByID } from "../../pipelines/redux/PipelinesAPI";
import { PipelineType } from "../../pipelines/types/PipelinesResponseType";


const EditDeal = () => {
  const { state = {} } = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const pipelineId = useSelector<RootState>(
    ({ auth }) => auth.pipelineId
  ) as number;
  const tabsList = useSelector<RootState>(
    // @ts-ignore
    ({ contentMaster }) => contentMaster.tabsList
  ) as TabType[];
  const pipeline = useSelector<RootState>(
    // @ts-ignore
    ({ deals }) => deals.pipelineDetails
  ) as DealPipelineDetailsType[];
  const singleDeal = useSelector<RootState>(
    // @ts-ignore
    ({ deals }) => deals.dealDetails
  ) as DealType;

  const phaseMoveDetails = useSelector<RootState>(
    // @ts-ignore
    ({ deals }) => deals.phaseTransitionDetails
  ) as any[];

  const tagsList = useSelector<RootState>(
    ({ masterdata }) => masterdata.tagsList
  ) as GroupType[];

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(userRoleDetails, "deals");

  const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;

  // @ts-ignore
  const tempDealInformation: DealType = state.dealDetails || singleDeal || {};
  // @ts-ignore
  const tempTabInformation: {
    name: string;
    pipeline_phase_tab_id: number;
    tab_id: number;
    // @ts-ignore
  }[] = tempDealInformation.tab_data || {};

  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  //   state
  const [loading, setLoading] = useState(false);
  const [transitionLoading, setTransitionLoading] = useState(false);
  const [tabActiveIndex, setTabActiveIndex] = useState<number>(0);
  const [phaseTabActiveIndex, setPhaseTabActiveIndex] = useState<number>(0);
  const { setFieldLogsInEditPage } = useContext<any>(FieldLogsContext);
  const { setPipelineName } = useContext<any>(FieldLogsContext);
  const [currTab, setCurrTab] = useState<TabType>();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showEndPhaseModal, setShowEndPhaseModal] = useState<{
    show: boolean;
    from_phase_info: DealPipelineDetailsType | undefined;
    to_phase_info: DealPipelineDetailsType | undefined;
    pIndex: number;
    dIndex: number;
    hIndex: number;
  }>({
    show: false,
    from_phase_info: undefined,
    to_phase_info: undefined,
    pIndex: 0,
    dIndex: 0,
    hIndex: 0,
  });
  const [modalProps, setModalProps] = useState<{
    show: boolean;
    method: string;
  }>({
    show: false,
    method: "",
  });
  const [dealHeading, setDealHeading] = useState(
    tempDealInformation.name || ""
  );
  const [missingFields, setMissingFields] = useState<
    {
      name: FieldType;
    }[]
  >();
  const [missingPhases, setMissingPhases] = useState<DealPipelineDetailsType[]>(
    []
  );
  const [missingTabIndex, setMissingTabIndex] = useState<number>(0);
  const [dealInformation, setDealInformation] =
    useState<DealType>(tempDealInformation);

  const [tabInformation, setTabInformation] = useState<
    {
      name: string;
      pipeline_phase_tab_id: number;
      tab_id: number;
      // @ts-ignore
    }[]
  >(tempTabInformation);
  const [currPhase, setCurrPhase] = useState<DealPipelineDetailsType>();
  const [fieldLogs, setFieldLogs] = useState<LogType[]>([]);
  const [showLogModal, setShowLogModal] = useState(false);
  const [hasRequiredFields, sethasRequiredFields] = useState<boolean[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [openTagModal, setOpenTagModal] = useState(false);
  const [singlePipeline, setSinglePipeline] = useState<PipelineType>();
  const [openAddTagsModal, setOpenAddTagsModal] = useState<TagModalProps>({
    show: false,
    type: "new",
  });
  const [endReasonModal, setEndReasonModa] = useState<{
    show: boolean;
    phase: DealPipelineDetailsType | undefined;
  }>({
    show: false,
    phase: undefined,
  });
  const [acceptModal, setAcceptModal] = useState<ApprovalModalProps>({
    show: false,
  });
  const [isDealApprovalRejected, setIsDealApprovalRejected] = useState({
    status: false,
    note: "",
  });

  const handleDealMovetoAnotherPipeline = (
    id: number,
    pId: number,
    message?: string
  ) => {
    dealMoveToPipeline(id, pId)
      .then((data) => {
        SuccessAlert(
          intl.formatMessage({
            id: "DEAL_MOVE_ANOTHER_PIPELINE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch((e) => {
        errorAlert(
          intl.formatMessage({
            id: "DEAL_MOVE_ANOTHER_PIPELINE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getDealPhaseAPI();
      });
  };
  // store the updated pipeline details
  const getDealPhaseAPI = () => {
    getDealPipelineDetailsById(pipelineId)
      .then(({ data: { pipeline_deals } }) => {
        const result: DealPipelineDetailsType[] = [];
        if (pipeline_deals?.length > 0) {
          // @ts-ignore
          setPipelineName(pipeline_deals[0]?.pipeline_name);
        }
        pipeline_deals.map((val, index) => {
          if (
            val.condition !== "end" ||
            (val.condition === "end" && val.display_order)
          ) {
            result.push(val);
          }
          if (pipeline_deals.length - 1 === index) {
            result.push(
              pipeline_deals.filter((data) => data.condition === "end")[0]
            );
          }
        });
        const currentPhaseData =
          pipeline_deals.filter(
            (phase) => phase.phase_id === phaseMoveDetails[0]
          )?.[0] || [];
        if (currentPhaseData && currentPhaseData.phase_id) {
          const currentPhaseDealData =
            currentPhaseData.deals.filter(
              (deal) => deal?.id === dealInformation?.id
            )?.[0] || [];
          if (currentPhaseDealData && currentPhaseDealData?.id) {
            setDealInformation(currentPhaseDealData);
            history.push(`/content/deals/editDeal`, {
              dealDetails: currentPhaseDealData,
            });
            dispatch(DealActions.setSingleDealDetails(currentPhaseDealData));
          }
        }

        dispatch(DealActions.setDealsDetails(result));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  // refresh to get pipeline details
  useEffect(() => {
    getDealPhaseAPI();
  }, [pipelineId]);

  useEffect(() => {
    if (pipelineId) {
      setLoading(true);
      getPipelineByID(pipelineId)
        .then(({ data: { pipeline } }) => {
          setSinglePipeline(pipeline);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [pipelineId]);

  // After refresh getting the deal information
  // useEffect(() => {
  //   if (pipeline && pipeline?.length === 0) {
  //     setChartLoading(true);
  //     getDealPipelineDetailsById(pipelineId)
  //       .then(({ data: { pipeline_deals } }) => {
  //         const result: DealPipelineDetailsType[] = [];
  //         pipeline_deals.map((val, index) => {
  //           if (val.condition !== "end") {
  //             result.push(val);
  //           }
  //           if (pipeline_deals?.length - 1 === index) {
  //             result.push(
  //               pipeline_deals.filter((data) => data.condition === "end")[0]
  //             );
  //           }
  //         });
  //         dispatch(DealActions.setDealsDetails(result));
  //       })
  //       .catch((e) => {})
  //       .finally(() => {
  //         setChartLoading(false);
  //       });
  //   }
  // }, [tabInformation, currTab, pipeline]);

  // missing fields for every missing phases
  useEffect(() => {
    if (missingPhases && missingPhases?.length > 0) {
      let allPhasesMissingTabs: {
        name: string;
        pipeline_phase_tab_id: number;
        tab_id: number;
      }[] = [];
      missingPhases.map((phase) => {
        phase.tab_data.map((tab) => {
          if (tab.tab_id) {
            allPhasesMissingTabs.push(tab);
          }
        });
      });
      let allPhasesMissingFields: any = {};
      let tempData: boolean[] = [];
      // setMissingFields(allPhasesMissingFields);
      if (allPhasesMissingTabs && allPhasesMissingTabs?.length > 0) {
        setLoading(true);
        axios
          .all(
            allPhasesMissingTabs.map((tab) =>
              getDealsTabByID(
                tab?.tab_id,
                dealInformation?.id,
                tab?.pipeline_phase_tab_id
              )
            )
          )
          .then((data) => {
            data.map((val) => {
              val.data.tab.panels.map((panel) => {
                panel.sections.map((section) => {
                  const reqData = section.fields_data.filter(
                    (field) => field.is_required === 1
                  );
                  let res = reqData.every(
                    (field) =>
                      field.value?.length > 0 || field.value_json.length > 0
                  );
                  tempData.push(res);
                  sethasRequiredFields(tempData);
                  section.fields_data.map((field) => {
                    if (
                      field.is_required === 1 &&
                      field.value == undefined &&
                      field.value_json?.length === 0
                    ) {
                      if (
                        Object.keys(allPhasesMissingFields).includes(
                          field.pipeline_phase_tab_id
                        )
                      ) {
                        allPhasesMissingFields[
                          field.pipeline_phase_tab_id
                        ].push(field);
                      } else {
                        allPhasesMissingFields[field.pipeline_phase_tab_id] = [
                          field,
                        ];
                      }

                      setMissingFields(allPhasesMissingFields);
                    }
                  });
                });
              });
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
    if (tabInformation?.length === 0) {
      setTabActiveIndex(1);
    }
  }, [tabInformation, currTab, pipelineId]);

  const onSearchText = (value: string) => {
    setSearchText((value.toLocaleLowerCase() || "").trim());
  };

  const handleContactOrPartner = () => {
    if (dealInformation?.partners?.id) {
      history.push(`/crm/organisation/edit`, {
        partnerDetails: dealInformation?.partners,
      });
    } else {
      history.push(`/crm/contacts/editcustomer`, {
        userDetails: dealInformation?.contacts,
      });
    }
  };

  useEffect(() => {
    if (
      phaseMoveDetails &&
      phaseMoveDetails?.length > 0 &&
      pipeline &&
      pipeline?.length > 0
    ) {
      const startIndex = pipeline.findIndex(
        (p) => p.phase_id === phaseMoveDetails[0]
      );
      const endIndex =
        phaseMoveDetails[0] !== phaseMoveDetails[1]
          ? pipeline.findIndex((p) => p.phase_id === phaseMoveDetails[1])
          : startIndex + 1;

      const missingData = [...pipeline.slice(startIndex, endIndex)];
      if (missingData && missingData?.length > 0) {
        setMissingPhases(missingData);
        setCurrPhase(missingData[0]);
        const phaseDeal = missingData[0].deals.filter(
          (deal) => deal?.id === tempDealInformation?.id
        )[0];
        setDealInformation(phaseDeal);
        setTabInformation(phaseDeal?.tab_data);
        setTabActiveIndex(0);
      }
    }
  }, [pipelineId, phaseMoveDetails, pipeline]);

  // for each tab change gets the tab information
  const handleTabData = (id: number, deal_Id: number, phase_tab_id: number) => {
    getDealsTabByID(id, deal_Id, phase_tab_id)
      .then(({ data: { tab } }) => {
        const updatedTabList = tabsList.map((data) => {
          if (data?.id === tab?.id) {
            return { ...data, panels: tab.panels };
          } else {
            return data;
          }
        });
        setCurrTab(tab);
        dispatch(actions.setTabsList(updatedTabList));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (dealInformation && dealInformation?.id) {
      getDealDetailsById(dealInformation?.id);
    }
  }, []);

  const getDealDetailsById = (id: number) => {
    getDealDetails(id)
      .then(({ data: { deals_details } }) => {
        dispatch(DealActions.setSingleDealDetails(deals_details));
        setDealInformation(deals_details);
        const length = deals_details?.approvals?.length;
        if (length > 0) {
          setIsDealApprovalRejected({
            status: deals_details?.approvals[length - 1].rejected_by
              ? true
              : false,
            note: deals_details?.approvals[length - 1].notes,
          });
        }
      })
      .catch((error) => console.log(error?.response?.data?.message));
  };

  useEffect(() => {
    const currentTab = tabInformation?.[phaseTabActiveIndex];
    if (currentTab && currentTab.tab_id) {
      setLoading(true);
      handleTabData(
        currentTab.tab_id,
        dealInformation?.id,
        currentTab?.pipeline_phase_tab_id
      );
    }
  }, [
    phaseTabActiveIndex,
    tabInformation,
    // missingTabIndex,
    // pipeline,
    // dealInformation,
    // phaseMoveDetails,
  ]);

  useEffect(() => {
    if (currTab && currTab?.id) {
      const currentTab = tabInformation?.[phaseTabActiveIndex];
      getTabFieldLogs(dealInformation?.id, currentTab?.pipeline_phase_tab_id)
        .then(({ data: { logs } }) => {
          if (logs && logs.length > 0) {
            setFieldLogs(logs);
            setFieldLogsInEditPage(logs);
          } else {
            setFieldLogs([]);
          }
        })
        .catch((error) => console.error(error?.response?.data?.message));
    }
  }, [currTab]);

  const handleResponsibles = (data: UserType[]) => {
    const ids = data?.map((user) => user?.id);
    const existingUsersIds = dealInformation?.responsible_users.map(
      (data) => data.user_id
    );
    addresponsible_Persons(dealInformation?.id, [...ids, ...existingUsersIds])
      .then((data) => {
        successToast(
          intl.formatMessage({
            id: "DEAL_RESPONSIBLE_USER_ADD_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((e) => {
        errorToast(
          intl.formatMessage({
            id: "DEAL_RESPONSIBLE_USER_ADD_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getDealDetailsById(dealInformation?.id);
      });
  };

  const deleteHandleResponsibles = (id: number) => {
    setDeleteModalLoading(true);
    deleteresponsible_Persons(dealInformation?.id, [id])
      .then((data) => {
        successToast(
          intl.formatMessage({
            id: "DEAL_RESPONSIBLE_USER_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((e) => {
        errorToast(
          intl.formatMessage({
            id: "DEAL_RESPONSIBLE_USER_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getDealDetailsById(dealInformation?.id);
      });
  };

  const handleTeamResponsibles = (team_user_ids: number[]) => {
    const existingUsersIds = dealInformation?.responsible_users.map(
      (data) => data.user_id
    );
    addresponsible_Persons(dealInformation?.id, [
      ...team_user_ids,
      ...existingUsersIds,
    ])
      .then((data) => {
        successToast(
          intl.formatMessage({
            id: "DEAL_RESPONSIBLE_USER_ADD_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((e) => {
        errorToast(
          intl.formatMessage({
            id: "DEAL_RESPONSIBLE_USER_ADD_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getDealDetailsById(dealInformation?.id);
      });
  };

  const handleDealHeading = () => {
    updateDeal(dealInformation?.id, dealHeading)
      .then((data) => {
        successToast(
          intl.formatMessage({ id: "DEAL_NAME_UPDATE_SUCCESS_MESSAGE" })
        );
      })
      .catch((e) => {
        errorToast(
          intl.formatMessage({ id: "DEAL_NAME_UPDATE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        getDealDetailsById(dealInformation?.id);
      });
  };

  // step by step pipeline request approval API
  const handleRequestApproval = () => {
    setTransitionLoading(true);
    const currentPhaseIndex = pipeline.findIndex(
      (phase) => phase.phase_id === phaseMoveDetails[0]
    );
    const fromPhaseId = phaseMoveDetails[0];
    const toPhaseId =
      phaseMoveDetails[0] !== phaseMoveDetails[1]
        ? phaseMoveDetails[1]
        : pipeline[currentPhaseIndex + 1]?.phase_id;
    if (
      pipeline.filter((data) => data.phase_id === toPhaseId)?.[0].condition !==
      "end"
    ) {
      changePhase(dealInformation?.id, fromPhaseId, toPhaseId)
        .then((data) => {
          successToast(
            intl.formatMessage({ id: "DEALS_MOVE_REQUEST_SUCCESS_MESSAGE" })
          );
        })
        .catch((e) => {
          errorToast(intl.formatMessage({ id: "DEALS_MOVE_FAILURE_MESSAGE" }));
        })
        .finally(() => {
          setTransitionLoading(false);
          getDealPhaseAPI();
        });
    } else {
      const endPhase = pipeline.filter(
        (data) => data.phase_id === toPhaseId
      )?.[0];
      setEndReasonModa({
        show: true,
        phase: endPhase,
      });
    }
  };

  // free flow pipeline request approval API
  const handleDealTransition = () => {
    setTransitionLoading(true);
    const currentPhaseIndex = pipeline.findIndex(
      (phase) => phase.phase_id === phaseMoveDetails[0]
    );
    const fromPhaseId = phaseMoveDetails[0];
    const toPhaseId =
      phaseMoveDetails[0] !== phaseMoveDetails[1]
        ? phaseMoveDetails[1]
        : pipeline[currentPhaseIndex + 1]?.phase_id;
    if (
      pipeline.filter((data) => data.phase_id === toPhaseId)?.[0].condition !==
      "end"
    ) {
      dispatch(DealActions.setPhaseTransitionDetails([toPhaseId, toPhaseId]));
      changePhase(dealInformation?.id, fromPhaseId, toPhaseId)
        .then((data) => {
          successToast(
            intl.formatMessage({ id: "DEALS_MOVE_SUCCESS_MESSAGE" })
          );
        })
        .catch((e) => {
          errorToast(intl.formatMessage({ id: "DEALS_MOVE_FAILURE_MESSAGE" }));
        })
        .finally(() => {
          setTransitionLoading(false);
          getDealPhaseAPI();
        });
    } else {
      const endPhase = pipeline.filter(
        (data) => data.phase_id === toPhaseId
      )?.[0];
      setEndReasonModa({
        show: true,
        phase: endPhase,
      });
    }
  };

  useEffect(() => {
    const res = hasRequiredFields?.every((field) => field === true);
    if (res) {
      setShowErrorModal(false);
    }
  }, [hasRequiredFields]);

  // function to change phase API
  const handleDealtypeTransition = (
    id: any,
    pIndex: any,
    hIndex: any,
    message?: any
  ) => {
    const from_phase_info = pipeline?.filter(
      (pipeline) => pipeline.phase_id === pIndex
    )[0];
    const to_phase_info = pipeline?.filter(
      
      (pipeline) => pipeline.phase_id ===  hIndex
    )[0];
      const isBackward =
      from_phase_info?.display_order && to_phase_info?.display_order
      ? Number(from_phase_info.display_order) >
      Number(to_phase_info.display_order)
      : from_phase_info?.condition === "end"
      ? true
      : to_phase_info?.condition === "start"
        ? true
        : false;

  if (isBackward) {
    if ( phaseMoveDetails[0] !== endReasonModal?.phase?.phase_id) {
      changePhase(dealInformation?.id,  pIndex, hIndex, message, 1)
        .then((data) => {
          successToast(
            intl.formatMessage({ id: "DEALS_MOVE_SUCCESS_MESSAGE" })
          );
        })
        .catch((e) => {
          errorToast(e?.response?.data?.message);
        })
        .finally(() => {
          getDealPhaseAPI();
          setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        });
    }
  }else{
    changePhase(id, pIndex, hIndex, message)
      .then((data) => {
        successToast(intl.formatMessage({ id: "DEALS_MOVE_SUCCESS_MESSAGE" }));
      })
      .catch((e) => {
        errorToast(intl.formatMessage({ id: "DEALS_MOVE_FAILURE_MESSAGE" }));
      })
      .finally(() => {
        getDealPhaseAPI();
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
      });
  }
  };

  const handleUpdateTag = (id: number) => {
    updateTag(dealInformation?.id, id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "DEAL_TAG_ADD_SUCCESS_MESSAGE" })
        );
        setSearchText("");
      })
      .catch(() => {
        errorToast(intl.formatMessage({ id: "DEAL_TAG_ADD_FAILURE_MESSAGE" }));
      })
      .finally(() => {
        setOpenTagModal(false);
        getDealDetailsById(dealInformation?.id);
      });
  };

  const getTagsListAPI = () => {
    getTagsList().then(({ data: { data } }) => {
      dispatch(TagActions.setTagsList(data));
    });
  };

  const handleAcceptAPI = (is_approved: number, notes: string) => {
    const approvalDeal = currPhase?.approvals.filter(
      (data) => data?.deals_id === dealInformation?.id
    )?.[0];
    if (is_approved === 1) {
      const currentPhaseIndex = pipeline?.findIndex(
        (phase) => phase.phase_id === phaseMoveDetails[0]
      );
      const nextPhase = pipeline[currentPhaseIndex + 1];
      dispatch(
        DealActions.setPhaseTransitionDetails([
          nextPhase.phase_id,
          nextPhase.phase_id,
        ])
      );
    }
    if (approvalDeal && approvalDeal?.id)
      AcceptApproval(approvalDeal?.id, is_approved, notes)
        .then((data) => {
          successToast(
            intl.formatMessage({ id: "ACCEPT_APPROVAL_SUCCESS_MESSAGE" })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({ id: "ACCEPT_APPROVAL_FAILURE_MESSAGE" })
          );
        })
        .finally(() => {
          getDealPhaseAPI();
        });
  };

  const handleDeleteDeal = () => {
    setDeleteModalLoading(true);

    deleteDeal(dealInformation?.id, pipelineId)
      .then((data) => {})
      .catch((e) => {})
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        history.push("/content/deals/overview");
      });
  };

  const hasFieldData = (currTab: { panels: any[]; }) => {
    return currTab?.panels?.some(panel =>   
      // @ts-ignore
      panel.sections?.some((section: { fieldData: string | any[]; }) => section.fields_data?.length > 0)
    );
  };
  
  return (
    <>
      {openModal && (
        <ErrorModal
          show={openModal}
          closeModal={() => {
            setOpenModal(false);
          }}
        />
      )}

      {showAddUserModal && (
        <SelectResponsibles
          show={showAddUserModal}
          closeModal={() => {
            setShowAddUserModal(false);
          }}
          onSelectMethod={(method) => {
            setShowAddUserModal(false);
            setModalProps({
              show: true,
              method,
            });
          }}
        />
      )}

      {modalProps.show && modalProps.method === "single" && (
        <SelectedUsersModal
          show={modalProps.show}
          closeModal={() => {
            setModalProps({
              ...modalProps,
              show: false,
            });
          }}
          onSelectUsers={(customers) => {
            handleResponsibles(customers);
            setModalProps({
              ...modalProps,
              show: false,
            });
          }}
          isSelectionModal={true}
          selectedContacts={dealInformation?.responsible_users}
        />
      )}

      {modalProps.show && modalProps.method === "team" && (
        <SelectTeamsModal
          show={modalProps.show}
          closeModal={() => {
            setModalProps({
              ...modalProps,
              show: false,
            });
          }}
          onSelectTeams={(teams) => {
            const ids = teams.map((team) =>
              team.team_users.map((user) => user?.id)
            )[0];
            setModalProps({
              ...modalProps,
              show: false,
            });
            handleTeamResponsibles(ids);
          }}
          isSelectionModal={false}
        />
      )}

      {showLogModal && (
        <FieldLogsModal
          show={showLogModal}
          closeModal={() => {
            setShowLogModal(false);
          }}
          fieldLogs={fieldLogs}
        />
      )}

      {openAddTagsModal.show && (
        <TagsModal
          ModalProps={openAddTagsModal}
          closeModal={() => {
            setOpenAddTagsModal({
              show: false,
            });
          }}
          getTagsListAPI={getTagsListAPI}
        />
      )}

      {showEndPhaseModal.show && (
        <EndPhaseTransitionModal
          show={showEndPhaseModal.show}
          closeModal={() => {
            setShowEndPhaseModal({
              ...showEndPhaseModal,
              show: false,
            });
          }}
          onSubmitHandle={(value) => {
            setShowEndPhaseModal({
              ...showEndPhaseModal,
              show: false,
            });
            setEndReasonModa({
              show: false,
              // @ts-ignore
              value: value,
            });
            if (value) {
              if (dealInformation?.id) {
                handleDealMovetoAnotherPipeline(dealInformation?.id, value);
              }
            }
          }}
          id={pipelineId}
        />
      )}

      {endReasonModal.show && (
        <EndPhaseReasonModal
          show={endReasonModal.show}
          closeModal={() => {
            setEndReasonModa({
              ...endReasonModal,
              show: false,
            });
          }}
          onSubmit={(reason: string) => {
            setEndReasonModa({
              ...endReasonModal,
              show: false,
            });
            setShowEndPhaseModal({
              ...showEndPhaseModal,
              show: true,
            });
            if (reason && reason.length > 0 && endReasonModal.phase) {
              dispatch(
                DealActions.setPhaseTransitionDetails([
                  endReasonModal.phase.phase_id,
                  endReasonModal.phase.phase_id,
                ])
              );            
              handleDealtypeTransition(
                dealInformation?.id,
                phaseMoveDetails[0],
                endReasonModal.phase.phase_id,
                reason
              );
            }
          }}
        />
      )}

      {acceptModal && (
        <AcceptDealModal
          modalProps={acceptModal}
          onSubmit={(is_approved: number, notes: string) => {
            setAcceptModal({ show: false });
            handleAcceptAPI(is_approved, notes);
          }}
          closeModal={() => {
            setAcceptModal({ show: false });
          }}
        />
      )}

      <>
        <div className="card borderNone mt-3 bgi-position-y-center bgi-no-repeat mb-8">
          {/* deal heading, contact/organisation information */}
          <div
            className={`px-8 pt-8 d-flex flex-row align-items-center justify-content-between mb-2 ${!crudPermission?.edit && "pe-none"}`}
          >
            <div className="d-flex flex-row align-items-center">
              <input
                type="text"
                value={dealHeading}
                onChange={(e) => {
                  setDealHeading(e.target.value);
                }}
                style={{
                  border: "none",
                  outline: "none",
                  width: dealHeading.length + 5 + "ch",
                }}
                className="panelHeading me-4 fw-bold py-2"
                onBlur={() => {
                  if (dealHeading?.length === 0) {
                    setDealHeading(dealInformation.name || "");
                  } else if (dealInformation.name !== dealHeading) {
                    handleDealHeading();
                  }
                }}
              />
              <div className="position-relative">
                <div>
                  {dealInformation?.tag_details !== null ? (
                    <div className="d-flex  fs-8" role="button">
                      <div
                        style={{
                          backgroundColor: dealInformation?.tag_details?.colour,
                          width: "fit-content",
                          borderRadius: "8px",
                        }}
                        className="p-1 px-4 border text-white ms-4  "
                        onClick={() => {
                          setOpenTagModal(!openTagModal);
                          setSearchText("");
                        }}
                      >
                        {dealInformation?.tag_details?.name}
                      </div>
                    </div>
                  ) : (
                    <div
                      role="button"
                      className="badge badge-primary"
                      onClick={() => {
                        setOpenTagModal(!openTagModal);
                      }}
                      title="Add Tag"
                    >
                      {intl.formatMessage({
                        id: "DEALS_ADD_NEW_LABEL_TITLE",
                      })}
                    </div>
                  )}

                  {openTagModal && (
                    <div
                      className="card position-absolute bg-white d-flex flex-column fs-8 border "
                      style={{
                        right: 0,
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        borderRadius: "10px",
                        zIndex: "999",
                        maxHeight: "300px",
                        minWidth: "250px",
                      }}
                    >
                      <div className="card-header min-h-40px pt-4 d-flex flex-center align-items-center">
                        <h2>
                          {intl.formatMessage({
                            id: "DEALS_ADD_NEW_LABEL_MODAL_TITLE",
                          })}
                        </h2>
                        <i
                          className="bi bi-x-lg mb-2 cursor-pointer"
                          onClick={() => {
                            setOpenTagModal(false);
                            setSearchText("");
                          }}
                        ></i>
                      </div>
                      <div
                        className="card-body"
                        style={{ overflowY: "auto", position: "relative" }}
                      >
                        <div className="d-flex align-items-center">
                          <SVGICON
                            src={SearchIcon}
                            className="svg-icon svg-icon-1 position-absolute ms-6 mb-6"
                          />
                          <input
                            type="text"
                            className="form-control form-control-solid ps-15 mb-2 mt-n5"
                            placeholder={intl.formatMessage({
                              id: "TAGS_SEARCH_PLACEHOLDER",
                            })}
                            autoFocus={true}
                            onChange={(e) => {
                              onSearchText(e.target.value);
                            }}
                          />
                        </div>
                        {tagsList &&
                          tagsList
                            .filter((tag) =>
                              tag.name.toLowerCase().includes(searchText)
                            )
                            .map((tag, index) => {
                              return (
                                <>
                                  <div
                                    className="d-flex mb-2 border px-3 py-1 fs-8 text-white"
                                    role="button"
                                    style={{
                                      background: tag.colour,
                                      borderRadius: "8px",
                                      width: "fit-content",
                                    }}
                                    onClick={() => {
                                      handleUpdateTag(tag?.id);
                                    }}
                                  >
                                    {tag.name}
                                  </div>
                                </>
                              );
                            })}
                      </div>
                      <div className="card-footer d-flex  py-2">
                        <div
                          className="badge badge-primary"
                          role="button"
                          style={{
                            width: "fit-content",
                          }}
                          onClick={() => {
                            setOpenAddTagsModal({
                              show: true,
                              type: "new",
                            });
                          }}
                        >
                          {intl.formatMessage({
                            id: "DEALS_EDIT_ADD_TAG_TITLE",
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center">
              <div className="me-8 borderNone" style={{ position: "relative" }}>
                {/* Finish button and Delete button */}
                <div
                  className="d-flex align-items-center justify-content-end"
                  //style={{position:'relative'}}
                >
                  {crudPermission?.delete && (
                    <button
                      className="btn btn-danger btn-active-danger btn-md"
                      onClick={() => {
                        if (
                          userData.role?.id === 1 ||
                          (currPhase && currPhase?.roles.length === 0) ||
                          (currPhase &&
                            currPhase?.roles.length > 0 &&
                            currPhase?.roles.filter(
                              (role) => role?.id === userData.role?.id
                            ).length > 0)
                        ) {
                          showDeleteConfirmModal(
                            intl.formatMessage({
                              id: "DEAL_DELETE_CONFIRM_MESSAGE",
                            }),
                            () => {
                              handleDeleteDeal();
                            }
                          );
                        }
                      }}
                    >
                      <SVGICON src={DeleteIcon} className="svg-icon-3" />
                      {intl.formatMessage({ id: "DELETE_BUTTON" })}
                    </button>
                  )}
                  {currPhase && currPhase.condition !== "end" && (
                    <>
                      {crudPermission?.edit && (
                        <button
                          className="btn  btn-success btn-active-success btn-md mx-1"
                          onClick={() => {}}
                        >
                          <SVGICON src={DeleteIcon} className="svg-icon-3" />
                          {intl.formatMessage({ id: "DEAL_FINISH_BUTTON" })}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div>
                <button
                  className={`btn btn-icon btn-light btn-active-light-primary btn-md me-1 ${
                    tabActiveIndex === 2
                      ? "btn-active-light-primary active"
                      : ""
                  }`}
                  onClick={(e) => {
                    setTabActiveIndex(2);
                  }}
                  style={{
                    borderRadius: "25px",
                  }}
                  title={intl.formatMessage({ id: "DEAL_PROJECT_FILES_TAB" })}
                >
                  <SVGICON src={FolderIcon} className="svg-icon-2x" />
                </button>
                <button
                  className={`btn btn-icon btn-light btn-active-light-primary ${
                    tabActiveIndex === 1
                      ? "btn-active-light-primary active"
                      : ""
                  }  btn-md me-1`}
                  onClick={(e) => {
                    setTabActiveIndex(1);
                  }}
                  style={{
                    borderRadius: "25px",
                  }}
                  title={intl.formatMessage({ id: "DEAL_ACTIVITY_TAB" })}
                >
                  <SVGICON
                    src={"media/icons/duotune/maps/map001.svg"}
                    className="svg-icon-2x"
                  />
                </button>
                <button
                  className="btn btn-icon btn-light btn-active-light-primary btn-md me-1"
                  onClick={(e) => {}}
                  style={{
                    borderRadius: "25px",
                  }}
                  title="Mail"
                >
                  <SVGICON
                    src={"/media/icons/duotune/communication/com002.svg"}
                    className="svg-icon-2x"
                  />
                </button>
              </div>
            </div>
          </div>
          {/* contact and partner information */}
          <div className="d-flex px-8">
            <div className="d-flex flex-row d-grid gap-2">
              <div className="d-flex align-items-center me-5 mb-2">
                <KTSVG
                  path="/media/icons/duotune/communication/com006.svg"
                  className="svg-icon-1 me-2"
                />
                <a
                  className="d-inline-block text-truncate text-gray-800 fw-bold text-hover-primary fs-8 cursor-pointer"
                  style={{ maxWidth: "300px" }}
                  onClick={handleContactOrPartner}
                >
                  {dealInformation?.partners?.address_1 &&
                    dealInformation?.partners?.name}
                  {dealInformation?.contacts?.id &&
                    dealInformation?.contacts?.firstname +
                      "  " +
                      dealInformation?.contacts?.lastname}
                </a>
              </div>

              {dealInformation?.partners?.address_1 && (
                <>
                  <div className="me-5 mb-2 d-flex align-items-center fs-8">
                    <div className="symbol symbol-30px">
                      <KTSVG
                        path="media/icons/duotune/general/gen018.svg"
                        className="svg-icon-1 me-2 "
                      />
                    </div>
                    <div className="fw-semibold me-5">
                      <div className="text-gray-500 fw-semibold fs-7 text-start ps-0 fs-8">
                        {dealInformation?.partners?.address_1
                          ? dealInformation?.partners?.address_1
                          : "" + ", " + dealInformation?.partners?.address_2
                            ? dealInformation?.partners?.address_2
                            : ""}
                      </div>
                      <div className="text-gray-500 fw-semibold fs-7 text-start ps-0 fs-8">
                        {dealInformation?.partners?.city || ""}
                        {","}
                        {dealInformation?.partners?.zip_code || ""}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {dealInformation?.contacts?.id && (
                <>
                  <div className="me-5 mb-2 d-flex align-items-center fs-8">
                    <div className="symbol symbol-30px">
                      <KTSVG
                        path="media/icons/duotune/general/gen018.svg"
                        className="svg-icon-1 me-2 "
                      />
                    </div>

                    <div className="fw-semibold me-5">
                      <div className="text-gray-500 fw-semibold fs-7 text-start ps-0 fs-8">
                        {dealInformation?.contacts?.address_1 || ""}{" "}
                        {dealInformation?.contacts?.address_2 || ""}
                      </div>
                      <div className="text-gray-500 fw-semibold fs-7 text-start ps-0 fs-8">
                        {dealInformation?.contacts?.city || ""}
                        {dealInformation?.contacts?.city ? "," : ""}
                        {dealInformation?.contacts?.zip_code || ""}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="d-flex align-items-center me-5 mb-2">
                <KTSVG
                  path="/media/icons/duotune/communication/com002.svg"
                  className="svg-icon-1 me-2 "
                />
                <div className="d-flex flex-column">
                <a
                  className="d-inline-block text-truncate text-gray-500 fw-semibold fs-7 text-start ps-0 fs-8"
                  style={{ maxWidth: "300px" }}
                  href={`mailto:${dealInformation?.partners?.email || dealInformation?.contacts?.email}`}
                >
                  {dealInformation?.partners?.address_1 &&
                    dealInformation?.partners?.email}
                  {dealInformation?.contacts?.id &&
                    dealInformation?.contacts?.email}
                </a>
                </div>
              </div>
              <div className="d-flex align-items-center me-5 mb-1 fs-8">
                <KTSVG
                  path="/media/icons/duotune/communication/com014.svg"
                  className="svg-icon-1"
                />
                <div className="d-flex flex-column">
                <a
                  className="d-inline-block text-truncate text-gray-500 fw-semibold fs-7 text-start ps-0 fs-8 mb-2"
                  style={{ maxWidth: "300px" }}
                  // @ts-ignore
                  href={`tel:${dealInformation?.partners?.telephone || dealInformation?.contacts?.telephone}`}
                >
                  {/* @ts-ignore */}
                  {dealInformation?.partners?.telephone || dealInformation?.contacts?.telephone}
                        </a>
                        </div>
              </div>
            </div>
          </div>
          {/* Deal Progress Bar & Deal Percentage Badge */}
          <div
            className={`d-flex p-8 pt-8 col-12 align-items-end gap-7 ${!crudPermission?.edit && "pe-none"}`}
          >
            <div className="col-9 rounded h-10px d-flex position-relative">
              {/* Deal Progress Bar */}
              {pipeline?.map((phase, index) => {
                const currentPhaseIndex = pipeline?.findIndex(
                  (phase) => phase.phase_id === phaseMoveDetails[0]
                );

                const presentPhase = pipeline[currentPhaseIndex];
                const nextPhase = pipeline[currentPhaseIndex + 1];

                const isCurrentPhaseEndPhase =
                  currentPhaseIndex + 1 === pipeline.length ? true : false;

                return (
                  <div key={index} className="w-100">
                    <div
                      data-tooltip-id={`phase-tooltip-${index}`}
                      data-tooltip-content={phase.name}
                      data-tooltip-place="bottom"
                      className="d-flex align-items-center justify-content-center h-100 fw-bold position-relative borderStyle me-1"
                      style={{
                        flex: 1,
                        background:
                          currentPhaseIndex >= index ||
                          (presentPhase?.condition === "end" &&
                            presentPhase?.display_order === null)
                            ? "#3766C7"
                            : KTUtil.getCSSVariableValue("--bs-secondary"),
                      }}
                      role="button"
                      onClick={() => {
                        const handlePhaseTransition = () => {
                          if (phase.condition === "end") {
                            setEndReasonModa({
                              show: true,
                              phase,
                            });
                          } else {
                            dispatch(
                              DealActions.setPhaseTransitionDetails([
                                phase.phase_id,
                                phase.phase_id,
                              ])
                            );
                            handleDealtypeTransition(
                              dealInformation?.id,
                              phaseMoveDetails[0],
                              phase.phase_id
                            );
                          }
                        };

                        const handleApprovalTransition = () => {
                          if (
                            presentPhase?.approvals.length > 0 &&
                            presentPhase.approvals.some(
                              (data) => data.deals_id === dealInformation?.id
                            )
                          ) {
                            if (
                              presentPhase.phaseApprovers.length > 0 &&
                              presentPhase.phaseApprovers.some(
                                (data) => data.users_id === userData?.id
                              )
                            ) {
                              const isUserPrimaryApprover =
                                currPhase?.phaseApprovers.some(
                                  (data) =>
                                    data.users_id === userData?.id &&
                                    data.condition === "primary"
                                );
                              setAcceptModal({
                                show: true,
                                is_primary: isUserPrimaryApprover,
                              });
                            }
                          } else {
                            showDeleteConfirmModal(
                              intl.formatMessage({
                                id: "EDIT_DEAL_APPROVAL_TRANSITION_CONFIRM_MESSAGE",
                              }),
                              () => {
                                handleRequestApproval();
                                hideDeleteConfirmModal();
                              },
                              intl.formatMessage({
                                id: "DEAL_APPROVAL_REQUEST_ACCESS_MESSAGE",
                              })
                            );
                          }
                        };

                        const handleStandardTransition = () => {
                          if (
                            hasRequiredFields?.every((field) => field === true)
                          ) {
                            showDeleteConfirmModal(
                              intl.formatMessage({
                                id: "EDIT_DEAL_TRANSITION_CONFIRM_MESSAGE",
                              }),
                              handlePhaseTransition,
                              intl.formatMessage({ id: "YES_MOVE_MESSAGE" })
                            );
                          } else {
                            setOpenModal(true);
                          }
                        };

                        const handlePresentPhaseTransition = () => {
                          if (
                            presentPhase?.transition_type === "step-by-step"
                          ) {
                            if (
                              nextPhase?.phase_id === phase?.phase_id &&
                              !isDealApprovalRejected.status
                            ) {
                              if (presentPhase?.need_approvals === 1) {
                                handleApprovalTransition();
                              } else {
                                handleStandardTransition();
                              }
                            }
                          } else {
                            if (
                              presentPhase?.phase_id !== phase?.phase_id &&
                              !isCurrentPhaseEndPhase
                            ) {
                              handleStandardTransition();
                            }
                          }
                        };

                        handlePresentPhaseTransition();
                      }}
                    >
                      {/* {phase.category} */}
                    </div>
                    <Tooltip
                      id={`phase-tooltip-${index}`}
                      place="top"
                      style={{
                        backgroundColor: "#f5f8fa",
                        color: "#a1a5b7",
                        zIndex: 99,
                      }}
                      border="1px solid #a1a5b7"
                    />
                  </div>
                );
              })}

              {/* Deal Progress Percentage */}
              <div
                className="position-absolute fw-semibold fs-5"
                style={{
                  right: "5%",
                  top: "-300%",
                }}
              >
                <span>
                  {currPhase?.condition === "end" &&
                  currPhase.display_order === null
                    ? "100%"
                    : `${Math.floor(
                        (pipeline?.slice(
                          0,
                          pipeline?.findIndex(
                            (data) => data.phase_id === currPhase?.phase_id
                          )
                        ).length /
                          pipeline.length) *
                          100
                      )}
                                    %`}
                </span>
              </div>
            </div>

            {/* Move Deal Button */}
            <div className="col-3 h-10px  d-flex align-items-end position-relative">
              {isDealApprovalRejected.status ? (
                <span
                  className="btn bg-danger text-white"
                  data-tooltip-id="rejected-tooltip"
                  data-tooltip-content={isDealApprovalRejected.note}
                  data-tooltip-place="top"
                >
                  {intl.formatMessage({
                    id: "DEAL_APPROVALS_REQUESTED_REJECTED_BUTTON",
                  })}
                  <Tooltip
                    id="rejected-tooltip"
                    place="top"
                    style={{
                      backgroundColor: "#f5f8fa",
                      color: "black",
                    }}
                    border="1px solid green"
                  />
                </span>
              ) : (
                <>
                  {currPhase?.condition !== "end" && (
                    <div className="d-flex justify-content-center">
                      <a
                        data-tooltip-id="move-tooltip"
                        data-tooltip-content={intl.formatMessage({
                          id: "EDIT_DEAL_MOVE_BUTTON_INFO_MESSAGE",
                        })}
                        data-tooltip-place="top"
                      >
                        <button
                          className="btn btn-md btn-primary"
                          onClick={() => {
                            const hasApprovalsForDeal =
                              currPhase?.approvals.some(
                                (data) => data.deals_id === dealInformation?.id
                              );
                            const isUserApprover =
                              currPhase?.phaseApprovers.some(
                                (data) => data.users_id === userData?.id
                              );

                            if (
                              currPhase?.need_approvals === 1 &&
                              currPhase?.transition_type === "step-by-step"
                            ) {
                              if (hasApprovalsForDeal) {
                                if (isUserApprover) {
                                  const isUserPrimaryApprover =
                                    currPhase?.phaseApprovers.some(
                                      (data) =>
                                        data.users_id === userData?.id &&
                                        data.condition === "primary"
                                    );
                                  setAcceptModal({
                                    show: true,
                                    is_primary: isUserPrimaryApprover,
                                  });
                                }
                              } else {
                                handleRequestApproval();
                              }
                            } else {
                              handleDealTransition();
                            }
                          }}
                          disabled={
                            !crudPermission?.edit ||
                            !hasRequiredFields?.every(
                              (field) => field === true
                            ) ||
                            (currPhase?.need_approvals === 1 &&
                            currPhase.transition_type === "step-by-step" &&
                            currPhase?.loggedInUserInPhaseApprovers
                              ? currPhase.approvals.length > 0 &&
                                currPhase.approvals.filter(
                                  (data) =>
                                    data.deals_id === dealInformation?.id
                                ).length > 0
                                ? currPhase.phaseApprovers.length > 0 &&
                                  currPhase.phaseApprovers.filter(
                                    (data) => data.users_id === userData?.id
                                  )?.length > 0
                                  ? false
                                  : true
                                : false
                              : false)
                          }
                        >
                          {!transitionLoading && (
                            <span className="indicator-label">
                              {currPhase?.need_approvals === 1 &&
                              currPhase.transition_type === "step-by-step"
                                ? currPhase.approvals.length > 0 &&
                                  currPhase.approvals.filter(
                                    (data) =>
                                      data.deals_id === dealInformation?.id
                                  ).length > 0
                                  ? currPhase.phaseApprovers.length > 0 &&
                                    currPhase.phaseApprovers.filter(
                                      (data) => data.users_id === userData?.id
                                    )?.length > 0
                                    ? intl.formatMessage({
                                        id: "DEAL_APPROVE_REJECT_REQUEST",
                                      })
                                    : intl.formatMessage({
                                        id: "DEAL_APPROVAL_REQUESTED_ACCESS_MESSAGE",
                                      })
                                  : intl.formatMessage({
                                      id: "DEAL_APPROVAL_REQUEST_ACCESS_MESSAGE",
                                    })
                                : intl.formatMessage({
                                    id: "DEAL_MOVE_DEAL_BUTTON",
                                  })}
                            </span>
                          )}
                          {transitionLoading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              {intl.formatMessage({
                                id: "FORM_VALIDATION_LOADING_MESSAGE",
                              })}
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </a>

                      <Tooltip
                        id="move-tooltip"
                        place="top"
                        style={{
                          backgroundColor: "#f5f8fa",
                          color: "black",
                        }}
                        border="1px solid green"
                      />
                    </div>
                  )}
                  {!hasRequiredFields?.every((field) => field === true) && (
                    <div
                      className="m-2 d-flex justify-content-end"
                      style={{
                        position: "relative",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowErrorModal(!showErrorModal);
                      }}
                    >
                      <a
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={intl.formatMessage({
                          id: "EDIT_DEAL_ISSUES_INFO",
                        })}
                        data-tooltip-place="top"
                      >
                        <i className="bi bi-info-circle-fill fs-3 text-danger"></i>
                      </a>

                      <Tooltip
                        id="my-tooltip"
                        place="top"
                        style={{
                          backgroundColor: "#fff5f8",
                          color: "black",
                        }}
                        border="1px solid red"
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          {missingPhases?.length > 1 && (
            <div className="px-10 ml-10 mb-5">
              <div>
                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-6 fw-bold">
                  {missingPhases?.map((phase, i) => {
                    if (phase) {
                      return (
                        <li
                          className="nav-item d-flex me-4"
                          key={i}
                          onClick={() => {
                            if (missingTabIndex !== i) {
                              setPhaseTabActiveIndex(0);
                              setCurrPhase(phase);
                              setMissingTabIndex(i);
                              setTabInformation(phase.tab_data);
                              // setCurrTab(undefined);
                            }
                          }}
                        >
                          <span
                            className={clsx(
                              "nav-link text-active-primary pb-4 m-0",
                              missingTabIndex === i ? "active" : ""
                            )}
                            role={"button"}
                          >
                            {phase.name}
                          </span>
                        </li>
                      );
                    } else {
                      return null;
                    }
                  })}
                </ul>
              </div>
            </div>
          )}
          {showErrorModal && (
            <>
              {missingFields && Object.keys(missingFields).length > 0 && (
                <div className="px-8">
                  <div className="alert alert-danger d-flex  flex-wrap flex-column">
                    {/* @ts-ignore */}
                    {Object.keys(missingFields)?.map((tab, index) => {
                      if (
                        currPhase?.tab_data.filter(
                          (t) => Number(t.pipeline_phase_tab_id) === Number(tab)
                        )[0]?.name
                      ) {
                        // @ts-ignore
                        return (
                          <div
                            className="alert-text font-weight-bold  "
                            style={{
                              width: "fit-content",
                            }}
                            key={index}
                            role="button"
                          >
                            <div className="d-flex ">
                              {tabInformation?.length > 1
                                ? currPhase?.tab_data.filter(
                                    (t) =>
                                      Number(t.pipeline_phase_tab_id) ===
                                      Number(tab)
                                  )[0]?.name + ":"
                                : ""}
                              <span className="ms-2">
                                {/* @ts-ignore */}
                                {missingFields[tab].map((data, i) => {
                                  return (
                                    <span key={i}>
                                      {data.name}
                                      {/* @ts-ignore */}
                                      {i === missingFields[tab]?.length - 1
                                        ? " are required values"
                                        : ", "}
                                    </span>
                                  );
                                })}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              )}
            </>
          )}
          <div className="px-10 ml-10">
            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-6 fw-bold">
              {tabInformation?.length > 0 &&
                tabInformation?.map((tab, i) => (
                  <li
                    className="nav-item d-flex me-4 pb-8"
                    key={i}
                    onClick={() => {
                      if (
                        phaseTabActiveIndex !== i ||
                        tabActiveIndex === 1 ||
                        tabActiveIndex === 2
                      ) {
                        setTabActiveIndex(0);
                        setPhaseTabActiveIndex(i);
                      }
                    }}
                  >
                    <span
                      className={clsx(
                        "nav-link text-active-primary pb-4 m-0",
                        phaseTabActiveIndex === i && tabActiveIndex === 0
                          ? "active"
                          : ""
                      )}
                      role={"button"}
                    >
                      {tab.name}
                    </span>
                  </li>
                ))}
              <li
                className="nav-item d-flex me-4 pb-8"
                onClick={() => {
                  setTabActiveIndex(1);
                }}
              >
                <span
                  className={clsx(
                    "nav-link text-active-primary pb-4 m-0",
                    tabActiveIndex === 1 ? "active" : ""
                  )}
                  role={"button"}
                >
                  {intl.formatMessage({ id: "DEAL_ACTIVITY_TAB" })}
                </span>
              </li>
              <li
                className="nav-item d-flex me-4 pb-8"
                onClick={() => {
                  setTabActiveIndex(2);
                }}
              >
                <span
                  className={clsx(
                    "nav-link text-active-primary pb-4 m-0",
                    tabActiveIndex === 2 ? "active" : ""
                  )}
                  role={"button"}
                >
                  {intl.formatMessage({ id: "DEAL_PROJECT_FILES_TAB" })}
                </span>
              </li>
            </ul>
          </div>
        </div>

        {/* Left side field cards */}
        <div
          className={`d-flex flex-row gap-7 borderNone ${!crudPermission?.edit && "pe-none"}`}
        >
          {tabActiveIndex === 0 && (
            <div style={{ flex: 1 }}>
              <div>
                {!loading && tabInformation?.length === 0 && (
                  <div className="d-flex justify-content-center align-items-center text-muted">
                    {intl.formatMessage({ id: "DEAL_NO_FIELDS_MESSAGE" })}
                  </div>
                )}

                {loading && (
                  <table className="d-flex justify-content-center align-items-center">
                    <tbody>
                      <tr>
                        <td
                          valign="top"
                          colSpan={5}
                          className="dataTables_empty"
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                )}

                {currTab &&
                  currTab?.panels?.length > 0 &&
                  hasFieldData(currTab) && (
                    <TabPreview
                      currPhase={currPhase}
                      dealTab={currTab}
                      type="edit"
                      onSubmitHandle={(id, fId, value, value_json) => {
                        storeDealFieldValue(
                          dealInformation?.id,
                          pipelineId,
                          id,
                          fId,
                          value,
                          value_json
                        )
                          .then((data) => {
                            successToast(
                              intl.formatMessage({
                                id: "DEAL_FIELD_UPDATE_SUCCESS_MESSAFE",
                              })
                            );
                            const currentTab =
                              tabInformation[phaseTabActiveIndex];
                            if (currentTab && currentTab.tab_id) {
                              handleTabData(
                                currentTab.tab_id,
                                dealInformation?.id,
                                currentTab.pipeline_phase_tab_id
                              );
                            }
                          })
                          .catch(() => {
                            errorToast(
                              intl.formatMessage({
                                id: "DEAL_FIELD_UPDATE_FAILURE_MESSAFE",
                              })
                            );
                          })
                          .finally(() => {});
                        // }
                      }}
                      deleteFile={(tId, fId, field_id) => {
                        setDeleteModalLoading(true);
                        deleteFieldFile(
                          dealInformation?.id,
                          pipelineId,
                          tId,
                          fId,
                          field_id
                        )
                          .then((data) => {
                            successToast(
                              intl.formatMessage({
                                id: "DEAL_FIELD_UPLOAD_FILE_SUCCESS_MESSAGE",
                              })
                            );
                            const currentTab =
                              tabInformation[phaseTabActiveIndex];
                            if (currentTab && currentTab.tab_id) {
                              handleTabData(
                                currentTab.tab_id,
                                dealInformation?.id,
                                currentTab.pipeline_phase_tab_id
                              );
                            }
                          })
                          .catch(() => {
                            errorToast(
                              intl.formatMessage({
                                id: "DEAL_FIELD_UPLOAD_FILE_FAILURE_MESSAGE",
                              })
                            );
                          })
                          .finally(() => {
                            hideDeleteConfirmModal();
                            setDeleteModalLoading(false);
                          });
                      }}
                    />
                  )}

                {(currTab) && (currTab?.panels?.length === 0 ||  !hasFieldData(currTab)) && <>
                <div className="w-100 h-100 firstPanel">
                          <span className="text-muted h5">
                            {intl.formatMessage({
                              id: "PANEL_EMPTY_MESSAGE",
                            })}
                          </span>
                          <span onClick={()=>{
                             history.push(`/projects/pipelines/editPipeline`, {
                              pipelineDetails: singlePipeline,
                            });
                          }} className="text-primary h5 cursor-pointer">{intl.formatMessage({
                              id: "PANEL_CLICK_HERE_MESSAGE",
                            })}</span>
                </div>
                </>}
              </div>
            </div>
          )}

          {tabActiveIndex === 1 && <ActivityComponent deal={dealInformation} />}
          {tabActiveIndex === 2 && (
            <DealProjectFiles deal={dealInformation} pipeline_id={pipelineId} />
          )}

          {/* Right side cards */}
          {tabActiveIndex === 0 && (
            <div
              className=" card borderNone  d-flex flex-column  w-100 w-lg-00px mb-8"
              style={{
                flex: "0 0 400px",
                backgroundColor: "#F5F5F5",
                boxShadow: "none",
              }}
            >
              {/* <div
              className="card mb-8"
              style={{
                minHeight: "250px",
              }}
            >
              <div className="card-body pb-0">
                <div className="d-flex justify-content-between align-items-center position-relative">
                  <h4>{intl.formatMessage({ id: "DEAL_INFO_BOX_TITLE" })}</h4>
                </div>

                <div>
                  <div className="d-flex mt-4">
                    <div className="d-flex flex-column d-grid gap-2">
                      <div className="d-flex align-items-center me-5 mb-2">
                        <KTSVG
                          path="/media/icons/duotune/communication/com006.svg"
                          className="svg-icon-2x me-5"
                        />
                        <a
                          className="d-inline-block text-truncate text-gray-800 fw-bold text-hover-primary fs-6"
                          style={{ maxWidth: "300px" }}
                        >
                          {dealInformation?.created_by.firstname +
                            dealInformation?.created_by.lastname}
                        </a>
                      </div>

                      {dealInformation?.partners?.address_1 && (
                        <>
                          <h6>
                            {intl.formatMessage({
                              id: "DEAL_PARTNER_DETAILS",
                            })}
                          </h6>
                          <div className="me-5 mb-2 d-flex align-items-center">
                            <div className="symbol symbol-30px">
                              <KTSVG
                                path="/media/icons/duotune/general/gen001.svg"
                                className="svg-icon-1 me-5 "
                              />
                            </div>
                            <div className="fw-semibold me-5">
                              <div className="fs-6 fw-bold text-gray-900 text-hover-primary">
                                {dealInformation?.partners?.name}
                              </div>
                              <div className="text-gray-500 fw-semibold fs-7 text-start ps-0">
                                {dealInformation?.partners?.address_1
                                  ? dealInformation?.partners?.address_1
                                  : "" +
                                      ", " +
                                      dealInformation?.partners?.address_2
                                    ? dealInformation?.partners?.address_2
                                    : "" || ""}
                              </div>
                              <div className="text-gray-500 fw-semibold fs-7 text-start ps-0">
                                {dealInformation?.partners?.city || ""}
                                {","}
                                {dealInformation?.partners?.zip_code || ""}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {dealInformation?.contacts?.id && (
                        <>
                          <h6>
                            {intl.formatMessage({
                              id: "DEAL_CONTACT_DETAILS",
                            })}
                          </h6>
                          <div className="me-5 mb-2 d-flex align-items-center">
                            <div className="symbol symbol-30px">
                              <KTSVG
                                path="/media/icons/duotune/general/gen001.svg"
                                className="svg-icon-1 me-5 "
                              />
                            </div>

                            <div className="fw-semibold me-5">
                              <div className="fs-6 fw-bold text-gray-900 text-hover-primary">
                                {dealInformation?.contacts?.firstname +
                                  " " +
                                  dealInformation?.contacts?.lastname}
                              </div>
                              <div className="text-gray-500 fw-semibold fs-7 text-start ps-0">
                                {dealInformation?.contacts?.email || ""}
                              </div>
                              <div className="text-gray-500 fw-semibold fs-7 text-start ps-0">
                                {dealInformation?.contacts?.address_1 || ""}{" "}
                                {dealInformation?.contacts?.address_2 || ""}
                              </div>
                              <div className="text-gray-500 fw-semibold fs-7 text-start ps-0">
                                {dealInformation?.contacts?.city || ""}
                                {dealInformation?.contacts?.city ? "," : ""}
                                {dealInformation?.contacts?.zip_code || ""}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="d-flex align-items-center me-5 mb-2">
                        <KTSVG
                          path="/media/icons/duotune/communication/com002.svg"
                          className="svg-icon-2x me-5 "
                        />
                        <a
                          className="d-inline-block text-truncate text-gray-500 fw-semibold fs-7 text-start ps-0"
                          style={{ maxWidth: "300px" }}
                        >
                          {dealInformation?.created_by.email}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {chartLoading ? <SimpleLoader fullLoader /> : <div></div>}
              </div>
            </div> */}

              <DealLocation dealInformation={dealInformation} />

              {/* Responsible Persons Card */}
              <div
                className="card mb-8 borderNone"
                style={{
                  minHeight: "300px",
                }}
              >
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center fs-5 fw-bolder mb-8">
                    <h4>
                      {intl.formatMessage({ id: "DEAL_LIST_VIEW_COLUMN5" })}
                    </h4>
                    <div>
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-primary"
                        onClick={() => {
                          setShowAddUserModal(true);
                        }}
                      >
                        <SVGICON src={PlusIcon} className="svg-icon-2" />
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      height: "280px",
                      overflow: "scroll",
                    }}
                  >
                    {dealInformation?.responsible_users?.map((resp, index) => {
                      return (
                        <div className="d-flex flex-stack mb-4" key={index}>
                          <div
                            className="border p-4 d-flex align-items-center justify-content-center me-2 bg-secondary"
                            style={{
                              borderRadius: "5px",
                              height: "25px",
                              width: "auto",
                            }}
                          >
                            <span>{resp.firstname[0]}</span>
                          </div>
                          <div className="d-flex flex-stack flex-row-fluid gap-2">
                            <div className="me-5">
                              <a
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6 d-inline-block text-truncate"
                                style={{ maxWidth: "220px" }}
                              >
                                {resp.firstname + resp.lastname}
                              </a>

                              <span
                                className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0 d-inline-block text-truncate"
                                style={{ maxWidth: "220px" }}
                              >
                                {resp.email}
                              </span>
                            </div>

                            <div className="d-flex align-items-center">
                              <button
                                className="btn btn-icon btn-light btn-active-light-danger btn-sm me-1"
                                onClick={() => {
                                  showDeleteConfirmModal(
                                    intl.formatMessage({
                                      id: "DEAL_RESPONSIBLE_PERSON_DELETE_CONFIRM_MESSAGE",
                                    }),
                                    () => {
                                      deleteHandleResponsibles(resp.user_id);
                                    }
                                  );
                                }}
                              >
                                <SVGICON
                                  src={DeleteIcon}
                                  className="svg-icon-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    </>
  );
};

export default EditDeal;
