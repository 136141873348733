import React, { FC, useRef } from "react";
import moment from "moment";
// import clsx from "clsx";
// import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useDrag, useDrop } from "react-dnd";

import { SVGICON } from "../../../../_metronic/helpers";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
// import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import ContactIcon from "../../../../_metronic/assets/icons/user.svg";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";

import { actions } from "../redux/DealsRedux";
import { deleteDeal } from "../redux/DealsAPI";
import { dealProps } from "../types/DealPropsType";

export const MovableDeal: FC<dealProps> = ({
  name,
  index,
  currentPhaseName,
  moveDealHandler,
  getDealPhaseAPI,
  item,
  displayUsers,
  remainingUsers,
  currentPhaseId,
  currentPhase,
  userData,
}) => {
  const ref = useRef(null);
  const endRef = useRef(null);
  // const intl = useIntl();
  const local = useLang();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    // eslint-disable-next-line
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();
  const [, drop] = useDrop({
    accept: "deal",
    hover(item: dealProps, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      //   @ts-ignore
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      //   @ts-ignore
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      //   moveDealHandler(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "deal",
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    options: {
      dropEffect: "copy",
    },
    item: {
      name,
      index,
      currentPhaseName,
      type: "deal",
      currentPhaseId,
      id: item.id,
    },
    end(draggedItem, monitor) {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        // @ts-ignore
        const { index } = dropResult;
        moveDealHandler(draggedItem.currentPhaseId, draggedItem.id, index);
      }
    },
  }));

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  // // eslint-disable-next-line
  // const handleDeleteDeal = () => {
  //   setDeleteModalLoading(true);

  //   deleteDeal(item.id)
  //     .then((data) => {})
  //     .catch((e) => {})
  //     .finally(() => {
  //       setDeleteModalLoading(false);
  //       hideDeleteConfirmModal();
  //       getDealPhaseAPI();
  //     });
  // };

  return (
    <div
      className={`movable-item ${item.can_be_move ? "completed" : "not-completed"} `}
      style={{
        opacity,
      }}
      ref={
        currentPhase.transition_type === "free-flow" &&
        (currentPhase.condition !== "end" ||
          (currentPhase.condition === "end" && currentPhase.display_order)) &&
        (userData.role.id === 1 ||
          currentPhase.roles.filter((role) => role.id === userData.role.id)
            .length > 0)
          ? ref
          : endRef
      }
      key={index}
    >
      <div className=" noBorder w-100">
        <div className="deal-header px-4 py-3 fw-bold">
          <h4
            className="deal-title fs-6 m-0"
            role="button"
            onClick={() => {
              history.push(`/content/deals/editDeal`, {
                dealDetails: item,
              });
              console.log(item,'item')
              dispatch(actions.setSingleDealDetails(item));
              dispatch(
                actions.setPhaseTransitionDetails([
                  currentPhaseId,
                  currentPhaseId,
                ])
              );
            }}
          >
            {name}
          </h4>
          {item?.tag_details?.name && (
            <div
              className="deal-tag p-1 px-4 text-white fs-8 mx-2"
              style={{
                backgroundColor: item.tag_details.colour,
                maxWidth: "180px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {item.tag_details.name}
            </div>
          )}
        </div>

        {/* <div
          className={clsx(
            "d-flex align-items-center justify-content-between p-4 fw-bold ",
            item?.tag_details?.name ? "pt-0" : ""
          )}
        >
          <h4
            className="m-0"
            role="button"
            onClick={() => {
              history.push(`/content/deals/editDeal`, {
                dealDetails: item,
              });
              dispatch(actions.setSingleDealDetails(item));
              dispatch(
                actions.setPhaseTransitionDetails([
                  currentPhaseId,
                  currentPhaseId,
                ])
              );
            }}
          >
            {name}
          </h4>
          <div className="d-flex">
            <button
              className="btn btn-icon btn-light btn-active-light-danger btn-sm "
              onClick={() => {
                if (
                  userData.role.id === 1 ||
                  currentPhase.roles.length === 0 ||
                  (currentPhase.roles.length > 0 &&
                    currentPhase.roles.filter(
                      (role) => role.id === userData.role.id
                    ).length > 0)
                ) {
                  showDeleteConfirmModal(
                    intl.formatMessage({ id: "DEAL_DELETE_CONFIRM_MESSAGE" }),
                    () => {
                      handleDeleteDeal();
                    }
                  );
                }
              }}
            >
              <SVGICON src={DeleteIcon} className="svg-icon-3" />
            </button>
          </div>
        </div> */}
        <div className="card-body p-2 px-4">
          <div className="row mx-0">
            <div className="col-1 ps-0">
              <SVGICON src={ContactIcon} className="svg-icon-2" />
            </div>
            <div className="col-11">
              {item?.partners ? (
                <>
                  {item.partners?.name && (
                    <p className="mb-2">{item.partners.name}</p>
                  )}
                  {item.partners?.telefone && (
                    <p className="mb-2">{item.partners.telefone}</p>
                  )}
                  {item.partners?.email && (
                    <p className="mb-2">{item.partners.email}</p>
                  )}
                </>
              ) : (
                item?.contacts && (
                  <>
                    <p className="mb-2">
                      {item.contacts?.firstname}
                      &nbsp;
                      {item.contacts?.lastname}
                    </p>
                    {item.contacts?.email && (
                      <p className="mb-2">{item.contacts.email}</p>
                    )}
                  </>
                )
              )}
              {item?.created_by && (
                <p className="text-muted">
                  {`Created by ${item?.created_by?.firstname} ${item?.created_by?.lastname}`}
                  <br />
                  <span>
                    {local === "de"
                      ? moment(item?.created_at).format("DD.MM.yyyy")
                      : moment(item?.created_at).format("YYYY-MM-DD")}
                  </span>
                </p>
              )}
            </div>
          </div>
          {/* <div className="text-muted fs-8">
                <span>
                {moment(new Date()).diff(
                    moment(item.created_at),
                    "days",
                    false
                ) === 0
                    ? moment(new Date()).diff(
                        moment(item.created_at),
                        "hours",
                        false
                    ) === 0
                    ? moment(new Date()).diff(
                        moment(item.created_at),
                        "minutes",
                        false
                        ) === 0
                        ? `just now `
                        : `${moment(new Date()).diff(
                            moment(item.created_at),
                            "minutes",
                            false
                        )} minutes`
                    : `${moment(new Date()).diff(
                        moment(item.created_at),
                        "hours",
                        false
                        )} hours`
                    : `${moment(new Date()).diff(
                        moment(item.created_at),
                        "days",
                        false
                    )} days `}
                </span>
                <span className="text-muted ms-1">
                {moment(new Date()).diff(
                    moment(item.created_at),
                    "minutes",
                    false
                ) === 0
                    ? ""
                    : "ago"}
                </span>
            </div> */}
        </div>
        <div className="card-footer flex-wrap pt-1 borderNone">
          <div className="d-flex justify-content-between align-items-center">
            <div className="symbol-group symbol-hover flex-nowrap">
              {displayUsers && displayUsers.length > 0 && (
                <>
                  {displayUsers?.map((user, index) => {
                    return (
                      <div
                        key={index}
                        className="symbol symbol-35px symbol-circle"
                        style={{ position: "relative" }}
                        onMouseOver={() => {
                          const tooltipEle = document.getElementById(
                            `role-user-tooltip-${index}${item.id}`
                          );
                          if (tooltipEle) {
                            tooltipEle.style.display = "flex";
                          }
                        }}
                        onMouseOut={() => {
                          const tooltipEle = document.getElementById(
                            `role-user-tooltip-${index}${item.id}`
                          );
                          if (tooltipEle) {
                            tooltipEle.style.display = "none";
                          }
                        }}
                      >
                        <div
                          id={`role-user-tooltip-${index}${item.id}`}
                          className="position-absolute bg-light px-3 py-2 fs-8"
                          style={{
                            display: "none",
                            backgroundColor: "white",
                            top: -60,
                            borderRadius: 5,
                          }}
                        >
                          {`${user.firstname}  ${user.lastname}`}
                        </div>
                        {
                          <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                            {(user.firstname || " ")[0].toUpperCase()}
                          </span>
                        }
                      </div>
                    );
                  })}
                  {remainingUsers > 0 && (
                    <div
                      className="symbol symbol-35px symbol-circle"
                      onClick={() => {
                        history.push(`/content/deals/editDeal`, {
                          dealDetails: item,
                        });
                        dispatch(actions.setSingleDealDetails(item));
                      }}
                    >
                      <span className="symbol-label bg-dark text-gray-300 fs-8 fw-bold">
                        +{remainingUsers}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            {/* Deal can be moved -> Badge */}
            <div
              className={`badge deal-person-count fw-bold fs-6 py-3 px-5 ${item.can_be_move ? "completed" : "not-completed"}`}
            >
              {item.responsible_users.length}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovableDeal;
