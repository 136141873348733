import clsx from "clsx";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useLayout } from "../../../core/LayoutProvider";
import { usePageData } from "../../../core/PageData";

const DefaultTitle: FC = () => {
  const { pageTitle, pageDescription, pageBreadcrumbs } = usePageData();
  const { config, classes } = useLayout();

  return (
    <div className="d-flex flex-column">
      <div
        id="kt_page_title"
        data-kt-swapper="true"
        data-kt-swapper-mode="prepend"
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        className={clsx(
          "page-title d-flex mx-n2",
          classes.pageTitle.join(" ")
        )}
      >
        {/* begin::Title */}
        {pageTitle && (
          <h1 className="d-flex align-items-center text-dark fw-bolder my-n4 fs-1 text-uppercase">
            {pageTitle}
            {/* {pageDescription && config.pageTitle && config.pageTitle.description && (
            <>
              <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
              <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
            </>
          )} */}
          </h1>
        )}
        {/* end::Title */}
      </div>
      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 1 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <div>
            {config.pageTitle.direction === "row" && (
              <span className="h-20px border-gray-200 border-start"></span>
            )}
            <ul
              className="breadcrumb breadcrumb-separatorless fw-bold fs-7 mx-n2"
              style={{ marginLeft: 0, paddingLeft: 0 }}
            >
              {Array.from(pageBreadcrumbs).map((item, index) => {
                if (
                  item?.title.length > 0 
                ) {
                  return (
                    <li
                      className={clsx({
                        "text-dark": !item.isSeparator && item.isActive,
                        "text-muted": !item.isSeparator && !item.isActive,
                      })}
                      key={`${item.path}${index}`}
                    >
                      {!item.isSeparator &&
                        item?.path !== "" &&
                        item?.title !== "DASHBOARD" && (
                          <>
                            <Link
                              className={clsx(
                                item.isActive
                                  ? "text-dark text-hover-primary"
                                  : "text-muted text-hover-primary"
                              )}
                              to={item.path}
                            >
                              {`${item.title}`}
                            </Link>
                            {index < pageBreadcrumbs.length - 1 && (
                              <span className="mx-1">/</span>
                            )}
                          </>
                        )}
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        )}
    </div>
  );
};

export { DefaultTitle };
