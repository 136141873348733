import React, { FC, useCallback, useEffect, useState } from "react";
import { ProductType, imageURLType } from "../types/ProductsResponseType";
import { useIntl } from "react-intl";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import { useDropzone, FileWithPath } from "react-dropzone";
import { addProductFile, deleteProductFile } from "../redux/ProductsAPI";
import { SuccessAlert, errorAlert } from "../../../sharedComponents/Alert";
import { SVGICON } from "../../../../_metronic/helpers";
import ImageIcon from "../../../../_metronic/assets/icons/imageIcon.svg";
import VideoIcon from "../../../../_metronic/assets/icons/video.svg";
import AudioIcon from "../../../../_metronic/assets/icons/audio.svg";
import PDFIcon from "../../../../_metronic/assets/icons/pdf.svg";
import FileIcon from "../../../../_metronic/assets/icons/defaultFile.svg";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import moment from "moment";
import ProductFilePreviewModal from "./ProductFilePreviewModal";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import Constants from "../../../config/Constants";
import { bytesToSize } from "../../../utils/BytesToSize";
import { getFile } from "../../filemanager/redux";
import Checkbox from "../../../sharedComponents/Checkbox/Checkbox";
import _ from "lodash";
interface Props {
  product: ProductType;
  getProductDetailsAPI: () => void;
}

interface FileUploadProps {
  onFileUpload: (files: FileWithPath[]) => void;
  loading: boolean;
  accept?: any;
  isDisabled?: boolean;
}

export const FileUpload: FC<FileUploadProps> = ({
  onFileUpload,
  loading,
  accept,
  isDisabled,
}) => {
  const intl = useIntl();
  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      onFileUpload(acceptedFiles);
    },
    [onFileUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,

    accept: accept
      ? accept
      : {
        "image/*": [],        // Accept all image types
        "audio/*": [],        // Accept all audio types
        "video/*": [],        // Accept all video types
        "application/pdf": [], // PDF files
        "application/msword": [],  // .doc (Microsoft Word files)
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],  // .docx (Word files)
        "application/vnd.ms-excel": [], // .xls (Excel files)
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],  // .xlsx (Excel files)
        "application/vnd.oasis.opendocument.text": [],  // .odt (OpenDocument text files)
        "text/csv": [],        // .csv (CSV files)
        "text/plain": []       // .txt (Plain text files)
      },
  });
  return (
    <div
      className="m-lg-6  h-100 "
      {...getRootProps()}
      style={dropzoneStyle}
      aria-disabled={loading}
    >
      <input type="file" {...getInputProps()} disabled={loading} />
      {
        true ? (
          <>
            {isDragActive ? (
              <p className="">
                {intl.formatMessage({
                  id: "PRODUCT_DROP_FILES_TITLE",
                })}
              </p>
            ) : (
              <>
                <p className="">
                  {intl.formatMessage({
                    id: "PRODUCT_DRAG_AND_DROP_FILES_TITLE",
                  })}
                </p>

                <button disabled={isDisabled} className="btn btn-primary">
                  {intl.formatMessage({ id: "UPLOAD_FILE_BUTTON" })}
                </button>
              </>
            )}
          </>
        ) : null
        // <>
        //   {loading && (
        //     <span className="indicator-progress" style={{ display: "block" }}>
        //       {intl.formatMessage({
        //         id: "PRODUCT_UPLOADING_FILES_TITLE",
        //       })}

        //       <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        //     </span>
        //   )}
        // </>
      }
    </div>
  );
};

const dropzoneStyle: React.CSSProperties = {
  border: "2px dashed #cccc",
  borderRadius: "10px",
  padding: "40px",
  justifyContent: "center",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  flexFlow: "column",
  textAlign: "center",
};

const ProductDocuments: FC<Props> = ({ product, getProductDetailsAPI }) => {
  // context
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const [previewLoading, setPreviewLoading] = useState<string | undefined>();
  const [previewFileURL, setPreviewFileURL] = useState<string>();
  const [previewFileExtension, setPreviewFileExtension] = useState<any>();
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [activePage, setActivePage] = useState<number>(1);
  const [uploadedData, setUploadedData] = useState<File[]>();
  const [checkedProducts, setCheckedProducts] = useState<imageURLType[]>([]);
  const [checkAllItems, setCheckAllItems] = useState<boolean>(false);

  const handleFileUpload = (files: File[]) => {
    const formData = new FormData();

    Object.keys(files)?.forEach((key) => {
      //@ts-ignore
      formData.append("product_files[]", files[key]);
    });

    setLoading(true);
    addProductFile(product.id, formData)
      .then((data) => {
        SuccessAlert(
          intl.formatMessage({
            id: "PRODUCT_FILE_ADD_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch((e) => {
        errorAlert(
          intl.formatMessage({ id: "PRODUCT_FILE_ADD_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setLoading(false);
        getProductDetailsAPI();
      });
  };
  const handleFileDelete = (files: any[]) => {
    setDeleteModalLoading(true);
    deleteProductFile(product.id, files)
      .then((data) => {})
      .catch((e) => {})
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getProductDetailsAPI();
        setCheckAllItems(false);
        setCheckedProducts([]);
      });
  };

  const deleteSelectedProducts = () => {
    const ids = checkedProducts.map((checkedProduct) => {
      return checkedProduct.id;
    });
    setDeleteModalLoading(true);
    deleteProductFile(product.id, ids)
      .then((data) => {})
      .catch((e) => {})
      .finally(() => {
        setCheckAllItems(false);
        setCheckedProducts([]);
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getProductDetailsAPI();
      });
  };

  function getFileExtensionFromUrl(url: string) {
    const match = url.match(/\.([^.]+)$/);
    return match ? match[1] : null;
  }

  const handlePreviewURL = (fileUrl: imageURLType) => {
    setPreviewLoading(fileUrl.photo_url);
    const fileExtension = getFileExtensionFromUrl(fileUrl.photo_url);
    setPreviewFileExtension(fileExtension);
    getFile(fileUrl.id)
      .then((res) => {
        // console.log(response.data);
        let fileObjURL = (res && res.data.fileDetails.file_url) || undefined;
        // handleTaskFilePreview(true);
        setPreviewFileURL(fileObjURL);
        setShowPreviewModal(true);
      })
      .catch((err) => {})
      .finally(() => {
        setPreviewLoading(undefined);
      });
  };

  const closePreviewModal = () => {
    setShowPreviewModal(false);
    // handleTaskFilePreview(false);
  };
  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  const onAllItemsCheckChange = () => {
    const updatedCheckAllItems = !checkAllItems;
    setCheckAllItems(updatedCheckAllItems);
    if (updatedCheckAllItems) {
      setCheckedProducts(product.image_urls);
    } else {
      setCheckedProducts([]);
    }
  };

  const onCheckedChange = (product: imageURLType) => {
    let checkedProductCopy = _.clone(checkedProducts);
    const index = checkedProductCopy.findIndex(
      (checkedProduct) => checkedProduct.id === product.id
    );
    if (index > -1) {
      checkedProductCopy.splice(index, 1);
      setCheckAllItems(false);
    } else {
      checkedProductCopy.push(product);
    }
    setCheckedProducts(checkedProductCopy);
  };

  const isChecked = (product: imageURLType) => {
    const index = checkedProducts.findIndex(
      (checkedProduct) => checkedProduct.id === product.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedProductDocuments =
    product?.image_urls && product?.image_urls.length > 10
      ? product?.image_urls.slice(startIndex, startIndex + itemsPerPage)
      : product?.image_urls;

  useEffect(() => {
    if (paginatedProductDocuments.length === 0 && product?.image_urls?.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedProductDocuments]);

  return (
    <>
      <div className="card borderNone">
        <div className="card-header  cursor-pointer ">
          <div className="card-title m-0 ">
            <h3 className="fw-bolder m-0">
              {intl.formatMessage({ id: "PRODUCT_DOCUMENTS_TITLE" })}
            </h3>
          </div>
        </div>
        <div className="card-body ">
          <div className="d-flex flex-column-reverse  flex-lg-row h-100 ">
            <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10 mt-md-8 me-lg-8">
              {/* table part */}
              {/* begin::Table container */}
              <div
                className="table-responsive"
                style={{
                  minHeight: "500px",
                }}
              >
                <div className="d-flex align-items-center justify-content-end">
                  {checkedProducts.length > 0 ? (
                    <>
                      <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                        {checkedProducts.length}{" "}
                        {intl.formatMessage({ id: "PARTNERS_LIST_SELECTED" })}
                      </span>
                      <button
                        className={"btn btn-danger"}
                        onClick={() => {
                          showDeleteConfirmModal(
                            intl.formatMessage({
                              id: "PRODUCT_FILE_DELETE_CONFIRMATION_MESSAGE",
                            }),
                            deleteSelectedProducts
                          );
                        }}
                      >
                        {intl.formatMessage({
                          id: "PARTNERS_LIST_DELETE_SELECTED",
                        })}
                      </button>
                    </>
                  ) : null}
                </div>
                {/* begin::Table */}
                <table className="table align-middle  table-row-dashed fs-6 gy-5 dataTable no-footer">
                  {/* begin::Table head */}
                  <thead>
                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 ">
                      <th className="w-25px">
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <Checkbox
                            onChange={onAllItemsCheckChange}
                            checked={checkAllItems}
                          />
                        </div>
                      </th>
                      <th className="min-w-125px">
                        {intl.formatMessage({
                          id: "PRODUCT_DOCUMENTS_TABLE_COLUMN1",
                        })}
                      </th>
                      {/* <th className='min-w-125px'>{intl.formatMessage({id: 'PRODUCTS_ID_NAME'})}</th> */}
                      {/* <th className="min-w-125px">
                      {intl.formatMessage({
                        id: "PRODUCT_DOCUMENTS_TABLE_COLUMN2",
                      })}
                    </th> */}
                      {/* <th className="min-w-125px">
                      {intl.formatMessage({
                        id: "PRODUCT_DOCUMENTS_TABLE_COLUMN3",
                      })}
                    </th> */}
                      <th className="min-w-125px">
                        {intl.formatMessage({
                          id: "PRODUCT_DOCUMENTS_TABLE_COLUMN4",
                        })}
                      </th>
                      <th className="min-w-125px"></th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {!product && (
                      <tr>
                        <td
                          valign="top"
                          colSpan={7}
                          className="dataTables_empty"
                        >
                          <SimpleLoader />
                        </td>
                      </tr>
                    )}
                    {/* no data */}
                    {product?.image_urls?.length === 0 && (
                      <tr className="odd">
                        <td
                          valign="top"
                          colSpan={7}
                          className="dataTables_empty"
                        >
                          <div className="d-flex flex-column flex-center">
                            <img
                              alt="not-found"
                              src={notFound}
                              className="mw-400px"
                            />
                            <div className="fs-1 fw-bolder text-dark mb-4">
                              {intl.formatMessage({
                                id: "PRODUCT_DOCUMENTS_LIST_NOITEMS_FOUND",
                              })}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                    <>
                      {paginatedProductDocuments?.length > 0 &&
                        paginatedProductDocuments?.map(
                          (product: imageURLType, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <div className="form-check form-check-sm form-check-custom form-check-solid me-2">
                                    <Checkbox
                                      onChange={() => {
                                        onCheckedChange(product);
                                      }}
                                      checked={isChecked(product)}
                                      disabled={false}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                      <div className="symbol-label">
                                        <div className="symbol-label fs-3 bg-light-primary text-danger text-uppercase">
                                          <SVGICON
                                            src={
                                              product.type === "pdf"
                                                ? PDFIcon
                                                : product.type === "mp4" ||
                                                    product.type === "m4v"
                                                  ? VideoIcon
                                                  : product.type === "mp3"
                                                    ? AudioIcon
                                                    : product.type === "jpg" ||
                                                        product.type ===
                                                          "png" ||
                                                        product.type === "jpeg"
                                                      ? ImageIcon
                                                      : FileIcon
                                            }
                                            className="svg-icon-primary svg-icon-2x "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex flex-column"
                                      onClick={() => {
                                        handlePreviewURL(product);
                                      }}
                                    >
                                      <span
                                        className="text-gray-800 fw-bold text-hover-primary  mb-1"
                                        role="button"
                                        title={product.photo_url}
                                        style={{
                                          maxWidth: "450px",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {product.name}
                                      </span>
                                      <div className="d-flex  align-items-center">
                                        <span className="badge badge-light-success fw-bolder    me-2">
                                          {bytesToSize(product.size, 1)}
                                        </span>
                                        <span className="badge badge-light-success fw-bolder    mx-2">
                                          {getFileExtensionFromUrl(
                                            product.photo_url
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                {/* <td>
                                <div className="d-flex flex-column ">
                                  <span className=" fw-bold me-2">
                                    {"Vishwanath Sunkari"}
                                  </span>
                                  <span className="text-gray-600 fw-bold ">
                                    {"Vishwanathsunkari@gmail.com"}
                                  </span>
                                </div>
                              </td> */}
                                <td>
                                  <div className="d-flex flex-column ">
                                    <span className="text-gray-600 fw-bold me-2">
                                      {moment(product.created_at).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex flex-column ">
                                    <button
                                      className="btn btn-icon btn-light btn-active-light-danger btn-sm me-1"
                                      onClick={() => {
                                        showDeleteConfirmModal(
                                          intl.formatMessage({
                                            id: "PRODUCT_FILE_DELETE_CONFIRMATION_MESSAGE",
                                          }),
                                          () => {
                                            handleFileDelete([product.id]);
                                          },
                                          intl.formatMessage({
                                            id: "COMMON_YES_DELETE",
                                          })
                                        );
                                      }}
                                    >
                                      <SVGICON
                                        src={DeleteIcon}
                                        className="svg-icon-3"
                                      />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </>
                  </tbody>
                  {/* end::Table body */}
                </table>
              </div>
              {/* end::Table container */}
            </div>
            <div
              className="d-flex  gap-7 gap-lg-10 flex-column  min-h-100 w-md-100 "
              style={{
                flex: "0 0 300px",
                background: "#F5F8FA",
                borderRadius: "10px",
                height: "600px ",
              }}
            >
              {/* upload file part */}
              <div className="h-lg-200px h-md-100 w-md-100  mb-lg-8">
                <FileUpload
                  onFileUpload={(files: File[]) => {
                    setUploadedData(files);
                    handleFileUpload(files);
                  }}
                  loading={loading}
                />
              </div>
              <div
                className="p-3 d-md-none d-lg-block w-lg-300px"
                style={{
                  overflow: "scroll",
                }}
              >
                {loading && (
                  <div className="mb-4 px-7">
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      {intl.formatMessage({
                        id: "PRODUCT_UPLOADING_FILES_TITLE",
                      })}

                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </div>
                )}
                {loading &&
                  uploadedData &&
                  uploadedData.length > 0 &&
                  uploadedData.map((data: File) => (
                    <div className=" pb-1">
                      <div className="d-flex align-items-center border border-dashed border-gray-300 rounded  px-7 py-3 mb-5">
                        <a className="fs-5 text-dark text-hover-primary fw-bold w-375px min-w-200px">
                          {data.name}
                        </a>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {previewFileURL && (
        <div>
          <ProductFilePreviewModal
            show={showPreviewModal}
            previewFileURL={previewFileURL}
            previewFileExtension={previewFileExtension}
            closeModal={closePreviewModal}
          />
        </div>
      )}
      {product && product?.image_urls?.length > 0 && (
        <div>
          <Pagination
            totalPages={Math.ceil(product?.image_urls.length / itemsPerPage)}
            activePage={
              Math.ceil(product?.image_urls.length / itemsPerPage) === 1
                ? 1
                : activePage
            }
            onPageClick={onPageClick}
            noCard
            itemsPerPage={itemsPerPage}
            showItemsPerPage
            setItemsPerPage={(count) => {
              setItemsPerPage(count);
            }}
          />
        </div>
      )}
    </>
  );
};

export default ProductDocuments;
