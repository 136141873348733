import clsx from "clsx";
import React, { FC, useEffect, useRef, useState } from "react";
// import {findDOMNode} from 'react-dom'
import Tag from "../tag/Tag";

// styles
import "./Dropdown.scss";

interface Item {
  id: number;
  name: string;
  pipeline?: string;
  isGroupLabel?: boolean;
}

interface Props {
  id: string;
  displayValue?: string;
  items: Item[];
  selectedItem?: Item;
  onSelectItem?: (item: Item) => void;
  displayFunc: (item: Item) => string | React.ReactNode;
  enableHoverBackground?: boolean;
  hideSelectedItems?: boolean;
  searchPlaceholder?: string;
  placeholder?: string;
  disabled?: boolean;
  hideSearch?: boolean;
  selectedItems?: Item[];
  multiSelect?: boolean;
  onMultiSelectBlur?: () => void;
  addEmptySelect?: boolean;
  defaultIndex?: number;
  searchkey?: string | string[];
  matchKey?: string;
  loading?: boolean;
  showCloseIcon?: boolean;
  customDisplay?: boolean;
  customDisplayFunc?: (item: Item) => any;
  size?: "small" | "large";
  enableClearValue?: boolean;
  onClearValue?: () => void;
  customChevronClass?: string;
  customMessage?: string;
  onEnterCreateElement?: (name: string) => void;
}

const DropDown: FC<Props> = ({
  id,
  displayValue = "",
  items,
  selectedItem,
  onSelectItem,
  displayFunc,
  searchPlaceholder,
  placeholder,
  disabled,
  hideSearch,
  selectedItems,
  enableHoverBackground,
  hideSelectedItems,
  multiSelect,
  onMultiSelectBlur,
  addEmptySelect,
  defaultIndex,
  searchkey,
  matchKey = "id",
  loading,
  showCloseIcon,
  customDisplay,
  customDisplayFunc,
  size,
  enableClearValue,
  onClearValue,
  customChevronClass = "",
  customMessage,
  onEnterCreateElement,
}) => {
  // ref
  const inputEl = useRef(null);
  const searchInputRef = useRef(null);
  const timerRef = useRef(null);
  const firstRender = useRef(true);

  // state
  const [displayItems, setDisplayItems] = useState(items);
  const [searchText, setSearchText] = useState<string>("");
  // const [inputElFocus, setInputElFocus] = useState(false)

  useEffect(() => {
    const filteredItems = items?.filter(
      (item) =>
        !selectedItems?.some((selectedItem) => selectedItem.id === item.id)
    );

    const listOfItems = hideSelectedItems ? filteredItems : items;

    const updatedItems = listOfItems?.filter((item) => {
      let key = "";
      //@ts-ignore
      if (searchkey && item[searchkey] && !Array.isArray(searchkey)) {
        key = searchkey;
        //@ts-ignore
      } else if (item["title"]) {
        key = "title";
      } else if (item["name"]) {
        key = "name";
      } else {
        return false;
      }

      if (Array.isArray(searchkey)) {
        return (
          //@ts-ignore
          (item["firstname"] || "")
            .toLocaleLowerCase()
            .includes((searchText || "").toLocaleLowerCase()) ||
          //@ts-ignore
          (item["lastname"] || "")
            .toLocaleLowerCase()
            .includes((searchText || "").toLocaleLowerCase()) ||
          //@ts-ignore
          (item["salutation"] || "")
            .toLocaleLowerCase()
            .includes((searchText || "").toLocaleLowerCase())
        );
      } else {
        //@ts-ignore
        return (item[key] || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase());
      }
    });
    const finalItems = addEmptySelect
      ? [{ id: 0, name: "Please select" }, ...(updatedItems || [])]
      : updatedItems;

    if (searchText) {
      setDisplayItems(finalItems);
    } else {
      if (hideSelectedItems) {
        const filteredItems = items?.filter(
          (item) =>
            !selectedItems?.some((selectedItem) => selectedItem.id === item.id)
        );
        setDisplayItems(filteredItems);
      } else {
        const resetItems = addEmptySelect
          ? [{ id: 0, name: "Please select" }, ...(filteredItems || [])]
          : filteredItems;

        setDisplayItems(resetItems);
      }
    }
  }, [items, searchText, selectedItems]);

  useEffect(() => {
    typeof defaultIndex === "number" &&
      defaultIndex > -1 &&
      onSelectItem?.(displayItems[defaultIndex]);
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    onMultiSelectBlur?.();
  }, [selectedItems]);

  const onSearchTextChange = (text?: string) => {
    setSearchText(text || "");
  };

  return (
    <div className="dropdown">
      <div
        id={`${id}-container`}
        className={clsx(
          "form-control  d-flex flex-row justify-content-between align-items-center form-control-solid py-2",
          disabled ? "pe-none" : "",
          size === "small" ? "form-control-sm" : "form-control-lg"
        )}
        data-bs-toggle="dropdown"
        aria-expanded="false"
        role="button"
        onClick={() => {
          // @ts-ignore
          inputEl?.current?.focus();
          // @ts-ignore
          searchInputRef?.current?.focus();
        }}
        style={disabled ? { backgroundColor: "#eff2f5" } : {}}
      >
        <div className="d-flex flex-row align-items-center flex-wrap">
          {multiSelect ? (
            <>
              {!showCloseIcon &&
                selectedItems?.map((item, i) => {
                  return (
                    <div className="mt-2 me-2" key={i}>
                      <Tag
                        text={
                          //@ts-ignore
                          item.title
                            ? //@ts-ignore
                              item.title
                            : item.name
                              ? item.name
                              : //@ts-ignore
                                item.firstname
                        }
                        onRemove={(e: React.MouseEvent) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onSelectItem?.(item);
                          // @ts-ignore
                          inputEl?.current?.focus();
                        }}
                      />
                    </div>
                  );
                })}
              <textarea
                id={`${id}-textarea`}
                ref={inputEl}
                className="mt-1"
                aria-label="Search"
                aria-describedby="select2-h5wl-container"
                // @ts-ignore
                // placeholder={selectedItems?.length > 0 ? '' : 'select an option'}
                style={{
                  // @ts-ignore
                  width: selectedItems?.length > 0 ? "1em" : "100%",
                  border: "none",
                  backgroundColor: "transparent",
                  resize: "none",
                  WebkitTapHighlightColor: "transparent",
                  outline: "none",
                }}
                rows={1}
                onFocus={() => {
                  // setInputElFocus(true)
                  // @ts-ignore
                  timerRef.current && clearTimeout(timerRef.current);
                }}
                // onBlur={() => {
                //   setInputElFocus(false)
                //   // @ts-ignore
                //   timerRef.current = timer()
                // }}
                value={""}
                onChange={() => {}}
              ></textarea>
            </>
          ) : (
            <span
              className={clsx(
                size === "small" ? "fs-7" : "",
                "me-1",
                !displayValue && placeholder && "text-muted"
              )}
              style={{ wordBreak: "break-word" }}
            >
              {displayValue || placeholder}
            </span>
          )}
        </div>
        {loading ? (
          <div
            className="spinner-border spinner-border-sm text-primary"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            {enableClearValue && displayValue ? (
              <div className="d-flex align-items-center">
                <div
                  className="bg-secondary d-flex justify-content-center align-items-center me-2"
                  style={{
                    width: "15px",
                    height: "15px",
                    borderRadius: "15px",
                  }}
                  role={"button"}
                  onClick={(e) => {
                    onClearValue?.();
                  }}
                >
                  <i className="bi bi-x text-dark"></i>
                </div>
                <i className="bi bi-chevron-down text-dark"></i>
              </div>
            ) : (
              <i
                className={clsx(
                  "bi bi-chevron-down text-dark ",
                  customChevronClass
                )}
              ></i>
            )}
          </>
        )}
      </div>
      {/* //removed class me-2 due to popper margin warning */}
      <div
        className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-100 py-4"
        aria-labelledby={id}
        id={id}
      >
        {!hideSearch && (
          <div className="px-5 mb-5">
            <input
              ref={searchInputRef}
              type="search"
              placeholder={searchPlaceholder}
              autoComplete="off"
              className={clsx("form-control")}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
                e.stopPropagation();
              }}
              value={searchText}
              onKeyDown={(e) => {
                if (
                  e.key === "Enter" &&
                  customMessage &&
                  customMessage?.length > 0
                ) {
                  onEnterCreateElement?.(searchText);
                  setSearchText("");
                }
              }}
            />
          </div>
        )}

        {multiSelect ? (
          <div style={{ maxHeight: "200px", overflow: "auto" }}>
            {displayItems && displayItems.length > 0 ? (
              <>
                {(showCloseIcon ? displayItems.slice(0, 20) : displayItems).map(
                  (item, i) => {
                    const isSelected =
                      selectedItems?.findIndex((a) => a.id === item.id) !== -1;
                    return (
                      <div
                        className={clsx(
                          "px-5 py-3  fw-normal text-gray-700",
                          isSelected && !item.isGroupLabel
                            ? "buttonPrimary"
                            : "bgBrandLightBlueHover",
                          item.isGroupLabel ? "pe-none" : ""
                        )}
                        onClick={(e) => {
                          if (!item.isGroupLabel) {
                            // @ts-ignore
                            inputEl?.current?.focus();
                            onSelectItem?.(item);
                            setSearchText("");
                            e.stopPropagation();
                          }
                        }}
                        key={i}
                        role="button"
                      >
                        <div
                          className={clsx(
                            item.isGroupLabel
                              ? "fw-bolder fs-6 mt-2"
                              : hideSearch
                                ? "fs-13px"
                                : "fs-7",
                            isSelected && !item.isGroupLabel ? "text-white" : ""
                          )}
                        >
                          {displayFunc(item)}
                        </div>
                      </div>
                    );
                  }
                )}
              </>
            ) : (
              <>
                <div className="px-5 py-2 text-gray-600 fw-normal fs-7 ">
                  <span>{"No results found"}</span>
                </div>
              </>
            )}
          </div>
        ) : (
          <div style={{ maxHeight: "200px", overflow: "auto" }}>
            {displayItems && displayItems.length > 0 ? (
              <>
                {displayItems.map((item, i) => {
                  return (
                    <div
                      className={clsx(
                        "px-5 py-3  fw-normal text-gray-700",
                        // @ts-ignore
                        !!selectedItem?.[matchKey] &&
                          // @ts-ignore
                          selectedItem?.[matchKey] === item[matchKey]
                          ? "buttonPrimary text-white"
                          : enableHoverBackground
                            ? "remaining-options-hover"
                            : "bgBrandLightBlueHover"
                      )}
                      onClick={(e) => {
                        onSelectItem?.(item);
                        setSearchText("");
                      }}
                      key={i}
                      role="button"
                    >
                      {customDisplay ? (
                        customDisplayFunc?.(item)
                      ) : (
                        <span
                          className={clsx(
                            hideSearch ? "fs-13px" : "fs-7",
                            // @ts-ignore
                            !!selectedItem?.[matchKey] &&
                              // @ts-ignore
                              selectedItem?.[matchKey] === item[matchKey]
                              ? "text-white"
                              : ""
                          )}
                        >
                          {displayFunc(item)}
                        </span>
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div className="px-5 py-2 text-gray-600 fw-normal fs-7 ">
                  <span>
                    {" "}
                    {customMessage ? customMessage : "No results found"}
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDown;
