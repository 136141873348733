import React, { FC } from "react";
import { useIntl } from "react-intl";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";

import ActionsDropdown from "../../../sharedComponents/ActionsDropdown/ActionsDropdown";
import { TeamType } from "../types/TeamsResponseType";
import { deleteTeam } from "../redux/TeamsAPI";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";

interface Props {
  team: TeamType;
  getTeamsListAPI: () => void;
  onEdit: (editObject: TeamModalProps) => void;
}

interface TeamModalProps {
  show: boolean;
  type?: string;
  team?: TeamType;
}

const TeamListActionsDropdown: FC<Props> = ({
  team,
  getTeamsListAPI,
  onEdit,
}) => {
  const intl = useIntl();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, "teams"))

  const onEdituserClick = () => {
    onEdit({
      show: true,
      type: "edit",
      team: team,
    });
  };

  const deleteSelectedCustomer = () => {
    setDeleteModalLoading(true);
    deleteTeam(team.id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "TEAMS_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "TEAMS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getTeamsListAPI?.();
      });
  };

  const dropdownOptions = [
    ...(crudPermission?.edit ? [{
      name: intl.formatMessage({ id: "COMMON_EDIT" }),
      onClick: onEdituserClick,
    }] : []),
    ...(crudPermission?.delete ? [ {
      name: intl.formatMessage({ id: "COMMON_DELETE" }),
      onClick: () => {
        showDeleteConfirmModal(
          intl.formatMessage({
            id: "TEAM_DELETE_CONFIRM_MESSAGE",
          }),
          () => {
            deleteSelectedCustomer();
          }
        );
      },
      className: "text-danger",
    }]: []),
  ];

  return (
    <div className="dropdown d-flex flex-row justify-content-end">
      <ActionsDropdown options={dropdownOptions} resource_slug="teams"/>
    </div>
  );
};

export default TeamListActionsDropdown;
