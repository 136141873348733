import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// styles
import "./Filemanager.scss";

// images
import AudioIcon from "../../../_metronic/assets/icons/audio.svg";
import curvyTickIcon from "../../../_metronic/assets/icons/curvyTick.svg";
import downloadZip from "../../../_metronic/assets/icons/downloadZip.svg";
import failedIcon from "../../../_metronic/assets/icons/failed.svg";
import FolderIcon from "../../../_metronic/assets/icons/folder.svg";
import NewFolderIcon from "../../../_metronic/assets/icons/newfolder.svg";
import notFound from "../../../_metronic/assets/icons/notFound.png";
import PDFIcon from "../../../_metronic/assets/icons/pdf.svg";
import SearchIcon from "../../../_metronic/assets/icons/search.svg";
import UploadFilesIcon from "../../../_metronic/assets/icons/uploadfiles.svg";
import VideoIcon from "../../../_metronic/assets/icons/video.svg";

// custom
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../setup";
import { SVGICON } from "../../../_metronic/helpers";
import Constants from "../../config/Constants";
import { errorAlert, SuccessAlert } from "../../sharedComponents/Alert";
import Checkbox from "../../sharedComponents/Checkbox/Checkbox";
import DisplayFileModal from "../../sharedComponents/displayFileModal/DisplayFileModal";
import SimpleLoader from "../../sharedComponents/Loader/SimpleLoader";
import { useGlobalModalContext } from "../../sharedComponents/modals/ModalContext";
import Pagination from "../../sharedComponents/pagination/Pagination";
import { bytesToSize } from "../../utils/BytesToSize";
import { isImageType } from "../../utils/isFileType";
import { NotificationType } from "../Notifications/types/getNotificationsResponseType";
import { UserType } from "../userManagement/types/getUsersListResponseType";
import FilemanagerHeader from "./components/FilemanagerHeader";
import FilemanagerPath from "./components/FilemanagerPath";
import FilemanagerActionsDropdown from "./components/FilemangerActionsDropdown";
import FolderActionsModal from "./components/FolderActionsModal";
import MoveFolderModal from "./components/MoveFoldeFilesModal";
import UploadFilesModal from "./components/UploadFilesModal";
import {
  getFilemanagerData,
  massDeleteFoldersOrFiles,
  optimizeFiles,
  zipFile,
} from "./redux/FilemanagerAPI";
import { actions } from "./redux/FilemanagerRedux";
import {
  FilemanagerDataType,
  FileType,
  FolderType,
} from "./types/getFilemanagerDataResponseType";
import { RoleType } from "../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../utils/PermisisionEnabledForResource";

interface FolderModalProps {
  show: boolean;
  type?: string;
  item?: FolderType | FileType;
  format?: "folder" | "file";
}

interface UploadFilesModalProps {
  show: boolean;
  folder?: FolderType;
}

interface Props {
  type?: "selectfile";
  onSelectFile?: (item: FileType | FileType[]) => void;
  allowedMimetypes?: string[];
  isMultiple?: boolean;
  isSelectionModal?: boolean;
  selectedFiles?: any[];
}

const Filemanager: FC<Props> = ({
  type,
  onSelectFile,
  allowedMimetypes,
  isMultiple,
  isSelectionModal,
  selectedFiles,
}) => {
  const filemanagerData = useSelector<RootState>(
    ({ filemanager }) => filemanager
  ) as FilemanagerDataType;
  const userNotifications = useSelector<RootState>(
    ({ auth }) => auth.notifications
  ) as NotificationType[];
  const user = useSelector<RootState>(({ auth }) => auth.user) as UserType;

  const dispatch = useDispatch();
  const intl = useIntl();
  const location = useLocation();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, 'file-manager'))

  // state
  const [showFolderActionsModal, setShowFolderActionsModal] =
    useState<FolderModalProps>({
      show: false,
    });
  const [showUploadFilesModal, setShowUploadFilesModal] =
    useState<UploadFilesModalProps>({
      show: false,
    });
  // @ts-ignore
  const [getFilemanagerDataAPICompleted, setGetFilemanagerDataAPICompleted] =
    useState<boolean>(false);
  const [checkedFolders, setCheckedFolders] = useState<FolderType[]>([]);
  const [checkedFiles, setCheckedFiles] = useState<FileType[]>([]);
  const [checkAllItems, setCheckAllItems] = useState<boolean>(false);
  const [displayFolders, setDisplayFolders] = useState<FolderType[]>([]);
  const [displayFiles, setDisplayFiles] = useState<FileType[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [activePage, setActivePage] = useState<number>(1);
  const [showDisplayFileModal, setShowDisplayFileModal] =
    useState<boolean>(false);
  const [displayFile, setDisplayFile] = useState<FileType>();
  const [showMoveModal, setShowMoveModal] = useState<boolean>(false);
  // const [prevNotifications, setPrevNotifications] = useState<NotificationType[]>(userNotifications)
  const [currentParentFolderId, setCurrentParentFolderId] = useState<
    number | null
  >(null);
  const [zipLoading, setZipLoading] = useState(false);

  // useEffects
  useEffect(() => {
    getFilemanagerDataAPI();

    return () => {
      setCurrentParentFolderId(null);
    };
  }, []);

  useEffect(() => {
    updateDisplayData();

    return () => {
      setDisplayFolders([]);
      setDisplayFiles([]);
    };
  }, [filemanagerData, searchText]);

  useEffect(() => {
    userNotifications.forEach((userNotification) => {
      if (
        userNotification.notification_type === "image_optimization" &&
        userNotification.status === "success"
      ) {
        // @ts-ignore
        paginatedItems.forEach((item: FileType) => {
          if (
            item.id === userNotification.data_id &&
            item.optimizing_status === "processing" &&
            item.optimizing_status !== userNotification.status
          ) {
            getFilemanagerDataAPI(currentParentFolderId);
          }
        });
      }
    });
  }, [userNotifications]);

  const isSelectFileType = () => {
    return type === "selectfile";
  };

  const getFilemanagerDataAPI = (folder_id?: number | null) => {
    setGetFilemanagerDataAPICompleted(false);
    const id = folder_id ? folder_id : null;
    setCurrentParentFolderId(id);
    getFilemanagerData(id)
      .then(({ data }) => {
        dispatch(actions.setFilemanagerData(data));
      })
      .catch((e) => {
        // console.log(`ERROR: getFilemanagerData`, e)
      })
      .finally(() => {
        setGetFilemanagerDataAPICompleted(true);
        setSearchText("");
        setActivePage(1);
      });
  };

  const filesAndFoldersZip = () => {
    setZipLoading(true);
    let tempFolderIds: any = [];
    let tempFileIds: any = [];
    if (checkedFolders && checkedFolders.length > 0) {
      tempFolderIds = checkedFolders.map((data) => data.id);
    }
    if (checkedFiles && checkedFiles.length > 0) {
      tempFileIds = checkedFiles.map((data) => data.id);
    }

    zipFile(tempFolderIds, tempFileIds)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const date = new Date().getTime().toString();
        const { REACT_APP_NAME } = process.env;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${REACT_APP_NAME || ""}_${date}.zip`);
        document.body.appendChild(link);
        link.click();
        SuccessAlert(
          intl.formatMessage({ id: "FILEMANAGER_DOWNLOAD_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "FILEMANAGER_DOWNLOAD_ERROR_MESSAGE" })
        );
      })

      .finally(() => {
        setZipLoading(false);
      });
  };

  const updateDisplayData = () => {
    const updatedFolders = filemanagerData.folders.filter((folder) => {
      if (
        (folder.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    const updatedFiles = /^[.]+[\d\w]*/.test(searchText)
      ? filemanagerData.files.filter((file) => {
          if (
            (file.type || "")
              .toLocaleLowerCase()
              .includes((searchText.slice(1) || "").toLocaleLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        })
      : filemanagerData.files.filter((file) => {
          if (
            (file.name || "")
              .toLocaleLowerCase()
              .includes((searchText || "").toLocaleLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        });
    // save updatedlist
    setDisplayFolders(updatedFolders);
    setDisplayFiles(updatedFiles);
  };

  const getCurrentFolder = () => {
    // @ts-ignore
    let currFolder: FolderType = {};
    filemanagerData.breadcrumbs?.forEach((crumb) => {
      currFolder = crumb;
    });
    return currFolder;
  };

  const currentFolder = getCurrentFolder();

  const closeFolderActionsModal = () => {
    setShowFolderActionsModal({
      show: false,
      type: "",
      item: undefined,
    });
  };
  const closeUploadFilesModal = () => {
    setShowUploadFilesModal({
      show: false,
      folder: undefined,
    });
  };
  const closeDisplayFileModal = () => {
    setShowDisplayFileModal(false);
  };

  const closeMOveModal = () => {
    setShowMoveModal(false);
    setCheckedFolders([]);
    setCheckedFiles([]);
  };

  const onFolderClick = (folder: FolderType) => {
    getFilemanagerDataAPI(folder.id);
  };

  const onBreadcrumbClick = (folder?: FolderType) => {
    getFilemanagerDataAPI(folder?.id);
  };

  const onFolderCheckChange = (folder: FolderType) => {
    let checkedFolderCopy = _.clone(checkedFolders);
    const index = checkedFolderCopy.findIndex(
      (checkedFolder) => checkedFolder.id === folder.id
    );
    if (index > -1) {
      checkedFolderCopy.splice(index, 1);
      setCheckAllItems(false);
    } else {
      checkedFolderCopy.push(folder);
    }
    setCheckedFolders(checkedFolderCopy);
  };

  const onFilesCheckChange = (file: FileType) => {
    let checkedFilesCopy = _.clone(checkedFiles);
    if (isSelectFileType() && !isMultiple) {
      if (checkedFiles[0]?.id === file.id) {
        setCheckedFiles([]);
      } else {
        setCheckedFiles([file]);
      }
    } else {
      const index = checkedFilesCopy.findIndex(
        (checkedFile) => checkedFile.id === file.id
      );
      if (index > -1) {
        checkedFilesCopy.splice(index, 1);
        setCheckAllItems(false);
      } else {
        checkedFilesCopy.push(file);
      }
      setCheckedFiles(checkedFilesCopy);
    }
  };

  const onAllItemsCheckChange = () => {
    const updatedCheckAllItems = !checkAllItems;
    setCheckAllItems(updatedCheckAllItems);
    if (updatedCheckAllItems) {
      setCheckedFiles(displayFiles);
      setCheckedFolders(displayFolders);
    } else {
      setCheckedFiles([]);
      setCheckedFolders([]);
    }
  };

  const isFolderChecked = (folder: FolderType) => {
    const index = checkedFolders.findIndex(
      (checkedFolder) => checkedFolder.id === folder.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const isFileChecked = (file: FileType) => {
    const index = checkedFiles.findIndex(
      (checkedFile) => checkedFile.id === file.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const deleteSelectedItems = () => {
    const folders_id = checkedFolders.map((checkedFolder) => {
      return checkedFolder.id;
    });
    const files_id = checkedFiles.map((checkedFile) => {
      return checkedFile.id;
    });
    setDeleteModalLoading(true);
    massDeleteFoldersOrFiles(folders_id, files_id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "FILEMANAGER_MASS_DELETE_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
        setCheckedFolders([]);
        setCheckedFiles([]);
        setCheckAllItems(false);
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "FILEMANAGER_MASS_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getFilemanagerDataAPI(currentFolder.id);
      });
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || ""));
    setActivePage(1);
  };

  const isPdf = (file: FileType) => {
    if ((file.type || "").toLocaleLowerCase() === "pdf") return true;
    return false;
  };

  const isVideo = (file: FileType) => {
    if ((file.type || "").toLocaleLowerCase() === "mp4") return true;
    return false;
  };

  const isAudio = (file: FileType) => {
    if ((file.type || "").toLocaleLowerCase() === "mp3") return true;
    return false;
  };

  const onPageClick = (page: number) => {
    setGetFilemanagerDataAPICompleted(false);
    setTimeout(() => {
      setActivePage(page);
      setGetFilemanagerDataAPICompleted(true);
    }, 300);
  };

  const isFolder = (item: FolderType | FileType) => {
    if (item.hasOwnProperty("type")) {
      return false;
    }
    return true;
  };

  const hasAllowedMimeType = (item: FolderType | FileType) => {
    if (!allowedMimetypes || allowedMimetypes.length === 0) {
      return true;
    }
    // @ts-ignore
    return allowedMimetypes.includes(item.type);
  };

  const showOptimizeFilesButton = () => {
    let bool = true;
    if (totalCheckedItems && _.isEmpty(checkedFolders)) {
      checkedFiles.forEach((file) => {
        if (!isImageType(file)) {
          bool = false;
        }
      });
      return bool;
    }
    return false;
  };

  const optimiseFilesAPI = () => {
    const files_id = checkedFiles.map((file) => {
      return file.id;
    });
    optimizeFiles(user.id, files_id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "FILEMANAGER_OPTIMIZE_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "FILEMANAGER_OPTIMIZE_ERROR_MESSAGE" })
        );
      })
      .finally(() => {
        getFilemanagerDataAPI(currentParentFolderId);
      });
  };

  const alreadySelected = (id: number) => {
    if (selectedFiles) {
      return selectedFiles.map((item) => item.id).includes(id);
    }
  };

  const numberOfFilesInFolder = displayFiles.length || 0;
  const totalCheckedItems = checkedFolders.length + checkedFiles.length;

  //paginated records
  const displayItems = [...displayFolders, ...displayFiles];
  const paginationCount = isSelectFileType()
    ? Constants.selectFileDefaultPaginationCount
    : Constants.defaultPaginationCount;

  const startIndex = paginationCount * (activePage - 1);
  const paginatedItems =
    displayItems && displayItems.length > 10
      ? displayItems.slice(startIndex, startIndex + paginationCount)
      : displayItems;

  useEffect(() => {
    if (paginatedItems.length === 0 && displayItems.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedItems]);

  return (
    <div>
      {!isSelectFileType() && (
        <FilemanagerHeader
          total_size={filemanagerData.total_size}
          total_items={filemanagerData.total_items}
        />
      )}
      {showFolderActionsModal.show && (
        <FolderActionsModal
          modalProps={showFolderActionsModal}
          closeModal={closeFolderActionsModal}
          getFilemanagerDataAPI={getFilemanagerDataAPI}
          parent_id={getCurrentFolder().id || null}
        />
      )}
      {showUploadFilesModal.show && (
        <UploadFilesModal
          modalProps={showUploadFilesModal}
          closeModal={closeUploadFilesModal}
          getFilemanagerDataAPI={getFilemanagerDataAPI}
        />
      )}
      {showDisplayFileModal && (
        <DisplayFileModal
          show={showDisplayFileModal}
          file={displayFile}
          closeModal={closeDisplayFileModal}
        />
      )}

      {showMoveModal && (
        <MoveFolderModal
          show={showMoveModal}
          closeModal={closeMOveModal}
          getFilemanagerDataAPI={getFilemanagerDataAPI}
          checkedFiles={checkedFiles}
          checkedFolders={checkedFolders}
        />
      )}

      <div
        className={`borderNone card card-flush ${
          !isSelectFileType() ? "mt-10" : ""
        }`}
      >
        <div className={`card-header pt-8 ${isSelectionModal ? "pb-8" : ""}`}>
          <div className="card-title">
            <SVGICON
              src={SearchIcon}
              className="svg-icon svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              className="form-control form-control-solid w-250px ps-15"
              placeholder={intl.formatMessage({
                id: "FILEMANAGER_SEARCH_PLACEHOLDER",
              })}
              value={searchText || ""}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
            />
          </div>

          <div className="card-toolbar">
            {totalCheckedItems ? (
              <>
                {isSelectFileType() ? (
                  <button
                    className="btn  btn-primary"
                    onClick={() => {
                      if (isMultiple) {
                        // @ts-ignore
                        onSelectFile?.(checkedFiles);
                      } else {
                        onSelectFile?.(checkedFiles[0]);
                      }
                    }}
                  >
                    {intl.formatMessage({ id: "FILEMANAGER_APPLY_BUTTON" })}
                  </button>
                ) : (
                  <>
                    <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4 mt-2 mt-md-0">
                      {totalCheckedItems}{" "}
                      {intl.formatMessage({ id: "SELECTED" })}
                    </span>
                    <button
                      className="btn  btn-danger d-flex align-items-center me-3 mt-2 mt-md-0"
                      onClick={() => {
                        showDeleteConfirmModal(
                          intl.formatMessage({
                            id: "FILEMANAGER_DELETE_MESSAGE",
                          }),
                          deleteSelectedItems
                        );
                      }}
                    >
                      <i className={"bi bi-trash3-fill fs-6"}></i>
                      {intl.formatMessage({ id: "FILEMANAGER_DELETE_BUTTON" })}
                    </button>
                    <button
                      className={
                        "btn  btn-primary d-flex align-items-center me-3 mt-2 mt-md-0"
                      }
                      onClick={() => {
                        setShowMoveModal(true);
                      }}
                    >
                      <SVGICON src={UploadFilesIcon} className="svg-icon-2" />
                      {intl.formatMessage({
                        id: "FILEMANAGER_MOVE_BUTTON_TITLE",
                      })}
                    </button>
                    <button
                      disabled={zipLoading}
                      className={
                        "btn  btn-primary d-flex align-items-center mt-2 mt-md-0"
                      }
                      onClick={() => {
                        // setShowMoveModal(true)
                        filesAndFoldersZip();
                      }}
                    >
                      {!zipLoading && (
                        <>
                          <SVGICON src={downloadZip} className="svg-icon-2" />
                          {intl.formatMessage({ id: "DOWNLOAD_ZIP" })}
                        </>
                      )}
                      {zipLoading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {intl.formatMessage({
                            id: "WEBSITE_GENERAL_LOADING_MESSAGE",
                          })}{" "}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                    {showOptimizeFilesButton() && (
                      <button
                        className="btn  btn-primary d-flex align-items-center ms-3 mt-2 mt-md-0"
                        onClick={() => {
                          optimiseFilesAPI();
                        }}
                      >
                        <SVGICON src={UploadFilesIcon} className="svg-icon-2" />
                        {intl.formatMessage({
                          id: "FILEMANAGER_OPTIMIZE_BUTTON_TITLE",
                        })}
                      </button>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {crudPermission?.create && 
                <>
                <button
                  type="button"
                  className="btn btn-light-primary me-3"
                  onClick={() => {
                    setShowFolderActionsModal({
                      show: true,
                      type: "new",
                      item: getCurrentFolder(),
                      format: "folder",
                    });
                  }}
                >
                  <SVGICON src={NewFolderIcon} className="svg-icon-2" />
                  {intl.formatMessage({ id: "FILEMANAGER_NEW_FOLDER_TITLE" })}
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setShowUploadFilesModal({
                      show: true,
                      folder: getCurrentFolder(),
                    });
                  }}
                >
                  <SVGICON src={UploadFilesIcon} className="svg-icon-2" />
                  {intl.formatMessage({ id: "FILEMANAGER_UPLOAD_FILES_TITLE" })}
                </button>
                </>
                }
              </>
            )}
          </div>
        </div>

        <div
          className="card-body"
          style={
            isSelectionModal
              ? { overflowY: "scroll", maxHeight: "calc(100vh - 400px)" }
              : {}
          }
        >
          <div className="d-flex flex-stack">
            {/* path breadcrumbs */}
            <FilemanagerPath
              breadcrumbs={filemanagerData.breadcrumbs}
              onBreadcrumbClick={onBreadcrumbClick}
              setSearchText={setSearchText}
            />
            {/* no of items */}
            <div className="badge badge-lg badge-primary">
              <span id="kt_file_manager_items_counter">
                {numberOfFilesInFolder} {intl.formatMessage({ id: "ITEMS" })}
              </span>
            </div>
          </div>

          <div>
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer mh-100px">
              <thead>
                <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                 {crudPermission?.delete && <th className="fm-checkbox-table-header">
                    <Checkbox
                      onChange={onAllItemsCheckChange}
                      disabled={isSelectFileType()}
                      checked={checkAllItems}
                    />
                  </th>}
                  <th className="">
                    {intl.formatMessage({
                      id: "FILEMANAGER_TABLE_COLUMN1_TITLE",
                    })}
                  </th>
                  {/* <th className="text-center">
                    {intl.formatMessage({
                      id: "FILEMANAGER_TABLE_COLUMN4_TITLE",
                    })}
                  </th> */}
                  <th className="">
                    {intl.formatMessage({
                      id: "FILEMANAGER_TABLE_COLUMN2_TITLE",
                    })}
                  </th>
                  <th className="">
                    {intl.formatMessage({
                      id: "FILEMANAGER_TABLE_COLUMN3_TITLE",
                    })}
                  </th>

                  <th></th>
                </tr>
              </thead>
              <tbody className="fw-bold text-gray-600">
                {/* folder */}
                {getFilemanagerDataAPICompleted &&
                  paginatedItems.map((item, i) => {
                    const isItemFolder = isFolder(item);
                    // @ts-ignore
                    const isItemPDF = isPdf(item);
                    // @ts-ignore
                    const isItemVideo = isVideo(item);
                    // @ts-ignore
                    const isItemAudio = isAudio(item);
                    return (
                      <tr className="fs-6 text-gray-800" key={i}>
                        {crudPermission?.delete && <td className="">
                          <Checkbox
                            onChange={() => {
                              isItemFolder
                                ? // @ts-ignore
                                  onFolderCheckChange(item)
                                : // @ts-ignore
                                  onFilesCheckChange(item);
                            }}
                            checked={
                              isItemFolder
                                ? // @ts-ignore
                                  isFolderChecked(item)
                                : // @ts-ignore
                                  isFileChecked(item)
                            }
                            disabled={
                              (isSelectFileType() &&
                                (isItemFolder || !hasAllowedMimeType(item))) ||
                              (type === "selectfile" &&
                                selectedFiles &&
                                alreadySelected(item.id))
                            }
                          />
                        </td>}
                        <td className="">
                          {Constants.mimeTypes.image.includes(item.type) ? (
                            <img
                              src={
                                //@ts-ignore
                                (item.resolutions &&
                                  //@ts-ignore
                                  item.resolutions.length > 0 &&
                                  //@ts-ignore
                                  item.resolutions[0].file_url) ||
                                item.file_path
                              }
                              alt=""
                              style={{
                                objectFit: "contain",
                                width: "50px",
                                height: "28.1px",
                              }}
                              className="me-3"
                            />
                          ) : (
                            <SVGICON
                              // @ts-ignore
                              src={
                                isItemFolder
                                  ? FolderIcon
                                  : isItemPDF
                                    ? PDFIcon
                                    : isItemVideo
                                      ? VideoIcon
                                      : isItemAudio
                                        ? AudioIcon
                                        : null
                              }
                              className={clsx(
                                isSelectFileType() &&
                                  !isItemFolder &&
                                  !hasAllowedMimeType(item)
                                  ? "svg-icon-2x me-3"
                                  : "svg-icon-primary svg-icon-2x me-3"
                              )}
                            />
                          )}

                          <span
                            className={clsx(
                              isSelectFileType() &&
                                !isItemFolder &&
                                !hasAllowedMimeType(item)
                                ? "text-muted pe-none"
                                : "text-gray-800 text-hover-primary"
                            )}
                            role="button"
                            onClick={() => {
                              if (isItemFolder) {
                                setSearchText("");
                                // @ts-ignore
                                onFolderClick(item);
                              } else {
                                // @ts-ignore
                                setDisplayFile(item);
                                setShowFolderActionsModal?.({
                                  show: true,
                                  type: location.pathname.includes("file")
                                    ? "edit"
                                    : "temp",
                                  item: item,
                                  format: "file",
                                });
                              }
                            }}
                          >
                            {item.name}
                          </span>
                        </td>
                        {/* <td>
                          {!isItemFolder && (
                            <div className="text-center">
            
                              {item.optimizing_status === "success" && (
                                <SVGICON
                                  src={curvyTickIcon}
                                  className="svg-icon-success svg-icon-2x"
                                />
                              )}
            
                              {item.optimizing_status === "processing" && (
                                <div
                                  className="spinner-border spinner-border-sm text-primary"
                                  role="status"
                                ></div>
                              )}
            
                              {item.optimizing_status === "failed" && (
                                <SVGICON
                                  src={failedIcon}
                                  className="svg-icon-danger svg-icon-2x"
                                />
                              )}
            
                              {!item.optimizing_status && "-"}

                              
                            </div>
                          )}
                        </td> */}
                        <td className="text-gray-600">{item.type}</td>
                        <td className="text-gray-600">
                          {/* @ts-ignore */}
                          {isItemFolder ? "-" : bytesToSize(item.size, 1)}
                        </td>

                        <td>
                          {!isSelectFileType() && (
                            <>
                              {isItemFolder ? (
                                <FilemanagerActionsDropdown
                                  item={item}
                                  format={"folder"}
                                  closeFolderActionsModal={
                                    closeFolderActionsModal
                                  }
                                  getFilemanagerDataAPI={getFilemanagerDataAPI}
                                  setShowFolderActionsModal={
                                    setShowFolderActionsModal
                                  }
                                  // @ts-ignore
                                  patent_id={item.parents_id}
                                />
                              ) : (
                                <FilemanagerActionsDropdown
                                  item={item}
                                  format={"file"}
                                  closeFolderActionsModal={
                                    closeFolderActionsModal
                                  }
                                  getFilemanagerDataAPI={getFilemanagerDataAPI}
                                  setShowFolderActionsModal={
                                    setShowFolderActionsModal
                                  }
                                  // @ts-ignore
                                  patent_id={item.folders_id}
                                />
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}

                {/* files */}
                {/* {displayFiles.map((file, i) => {
                return (
                  <tr className='fs-6 text-gray-800' key={i}>
                    <td className=''>
                      <Checkbox
                        onChange={() => {
                          onFilesCheckChange(file)
                        }}
                        checked={isFileChecked(file)}
                      />
                    </td>
                    <td className=''>
                      <SVGICON
                        src={isPdf(file) ? PDFIcon : ImageIcon}
                        className='svg-icon-primary svg-icon-2x me-3'
                      />
                      <span
                        className='text-gray-800 text-hover-primary'
                        role='button'
                        onClick={() => {
                          // onFolderClick(folder)
                          window?.open(file.file_path, '_blank')
                        }}
                      >
                        {file.name}
                      </span>
                    </td>
                    <td className='text-gray-600'>{bytesToSize(file.size)}</td>
                    <td>
                      <FilemanagerActionsDropdown
                        item={file}
                        format={'file'}
                        closeFolderActionsModal={closeFolderActionsModal}
                        getFilemanagerDataAPI={getFilemanagerDataAPI}
                        setShowFolderActionsModal={setShowFolderActionsModal}
                        patent_id={file.folders_id}
                      />
                    </td>
                  </tr>
                )
              })} */}

                {!getFilemanagerDataAPICompleted && (
                  <tr>
                    <td valign="top" colSpan={5} className="dataTables_empty">
                      <SimpleLoader />
                    </td>
                  </tr>
                )}

                {/* no files and folders*/}
                {getFilemanagerDataAPICompleted &&
                  displayItems.length === 0 && (
                    <tr className="odd">
                      <td valign="top" colSpan={5} className="dataTables_empty">
                        <div className="d-flex flex-column flex-center">
                          <img
                            alt="not-found"
                            src={notFound}
                            className="mw-400px"
                          />
                          <div className="fs-1 fw-bolder text-dark mb-4">
                            {intl.formatMessage({ id: "NO_ITEMS_FOUND" })}
                          </div>
                          <div className="fs-6">
                            {intl.formatMessage({
                              id: "FILEMANAGER_NEW_FILE_UPLOAD_MESSAGE",
                            })}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
            {displayItems.length > 0 && getFilemanagerDataAPICompleted && (
              <Pagination
                totalPages={Math.ceil(displayItems.length / paginationCount)}
                activePage={
                  Math.ceil(displayItems.length / paginationCount) === 1
                    ? 1
                    : activePage
                }
                onPageClick={onPageClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filemanager;
