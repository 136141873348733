import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  SectionType,
  TabType,
  panels,
  tabPanelType,
} from "../types/TabsResponseType";
import { SVGICON } from "../../../../_metronic/helpers";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import { sortGroupsParams } from "../types/request";
// images
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import CloseIcon from "../../../../_metronic/assets/icons/close.svg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import {
  deletePanels,
  deleteSection,
  getTabByID,
  sortFieldSections,
  sortPanels,
  storeSectionFields,
  storeTabPanels,
  updateIsRequired,
  updateTab,
} from "../redux/TabsAPI";
import { useIntl } from "react-intl";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import {
  SuccessAlert,
  TwoButtonConfirmAlert,
  errorAlert,
} from "../../../sharedComponents/Alert";
import { FieldType } from "../types/FieldsReponseTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import TabDropzone from "./TabDropzone";
import "../ContentMasterPage.scss";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import clsx from "clsx";
import {
  deleteSelectedField,
  getFieldsList,
  updatePanelHeading,
} from "../redux/FieldsAPI";
import TabPreview from "./TabPreview";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import { FieldsModal, FieldsModalProps } from "./Fields";
import { useDispatch } from "react-redux";
import { actions } from "../redux";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";
import withScrolling from "react-dnd-scrolling";
import SelectFieldModal, { FieldSelectModalProps } from "./SelectFieldsModal";
import { Tooltip } from "react-tooltip";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";

interface selectFieldModalProps {
  show: boolean;
  section: number | undefined;
  panel: number | undefined;
}

const EditTabs = () => {
  const { state = {} } = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  const ScrollingComponent = withScrolling("div");

  const MASTER_DATA_PANELS_TYPE = JSON.parse(
    intl.formatMessage({ id: "MASTER_DATA_PANELS_TYPE" })
  ) as any[];

  const fieldsList = useSelector<RootState>(
    // @ts-ignore
    ({ contentMaster }) => contentMaster.fieldsList
  ) as FieldType[];
  const singleTab = useSelector<RootState>(
    // @ts-ignore
    ({ contentMaster }) => contentMaster.tabDetails
  ) as TabType;

  // @ts-ignore
  const tab: TabType = state.tabDetails || singleTab || {};

  // state
  const [searchText, setSearchText] = useState<string>("");
  const [fieldData, setFieldData] = useState<FieldType[]>(fieldsList);
  const [showPreview, setPreview] = useState(false);
  const [tabHeading, setTabHeading] = useState(tab.name || "");
  const [loading, setLoding] = useState(false);
  const [tabHeight, setTabHeight] = useState<any>();
  const [openAddFieldModal, setOpenAddFieldModal] = useState<FieldsModalProps>({
    show: false,
    type: "new",
  });
  const [selectFieldModal, setSelectFieldModal] =
    useState<selectFieldModalProps>({
      show: false,
      section: undefined,
      panel: undefined,
    });

    
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, "tabs"))

  const getTabDetailsByID = () => {
    if (tab && tab.id) {
      getTabByID(tab.id)
        .then(({ data }) => {
          history.replace({ state: { tabDetails: data.tab } });
        })
        .finally(() => {
          setLoding(false);
          // setSearchText("");
        });
    }
  };

  useEffect(() => {
    setLoding(true);
    getTabDetailsByID();
  }, []);

  const onPanelsSortChange = (sortedList: panels[]) => {
    const newSortedTabsArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedTabsArr.push({
        id: sortedListItem.tab_panel_id,
        newposition: i + 1,
      });
    });
    // sort existing tags API Call
    sortPanels(tab.id, newSortedTabsArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getTabDetailsByID();
      });
  };

  // draggable tags list
  const list =
    tab && tab.panels && tab.panels.length > 0
      ? tab.panels.map((panel, i) => {
          return {
            content: <div className="mt-4" key={i}></div>,
            panel: panel,
          };
        })
      : [];

  const handleDrop = useCallback(
    (
      dropZone: { path: any; childrenCount: any; panel?: any; section?: any },
      item: { id: any; type: any; isNew: boolean; path?: any }
    ) => {
      // console.log("dropZone", dropZone);
      // console.log("item", item);

      if (item.type === "panel") {
        const isSortingPanel = item.isNew ? false : true;
        if (!isSortingPanel) {
          storeTabPanels(tab.id, item.id)
            .then((data) => {
              successToast(
                intl.formatMessage({
                  id: "MASTERDATA_TAB_PANEL_ADD_SUCCESS_MESSAGE",
                })
              );
            })
            .catch((e) => {
              errorToast(
                intl.formatMessage({
                  id: "MASTERDATA_TAB_PANEL_ADD_FAILURE_MESSAGE",
                })
              );
            })
            .finally(() => {
              getTabDetailsByID();
            });
        }
      }
      if (item.type === "field" && item.isNew) {
        const isSortingField = item.isNew ? false : true;
        if (!isSortingField) {
          TwoButtonConfirmAlert(
            intl.formatMessage({ id: "FIELD_REQUIRED_FIELD_CONFIRM_MESSAGE" }),
            (selected: boolean) => {
              const isRequiredField = selected;
              const newSectionField: {
                field_id: number;
                is_required: number;
              }[] = [];
              newSectionField.push({
                field_id: item.id,
                is_required: isRequiredField ? 1 : 0,
              });
              storeSectionFields(
                dropZone.panel,
                dropZone.section,
                newSectionField
              )
                .then((data) => {
                  successToast(
                    intl.formatMessage({
                      id: "MASTERDATA_TAB_PANEL_FIELD_ADD_SUCCESS_MESSAGE",
                    })
                  );
                })
                .catch((e) => {
                  errorToast(
                    intl.formatMessage({
                      id: "MASTERDATA_TAB_PANEL_FIELD_ADD_FAILURE_MESSAGE",
                    })
                  );
                })
                .finally(() => {
                  getTabDetailsByID();
                });
            }
          );
        }
      }
    },
    []
  );
  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const updateDisplayTabsList = () => {
    const updatedTabsList = fieldsList.filter((tab) => {
      if (
        (tab.name || "")
          .toLowerCase()
          .includes((searchText || "").toLowerCase())
      ) {
        return true;
      }
      return false;
    });

    // save updatedlist to display
    setFieldData(updatedTabsList);
  };

  useEffect(() => {
    updateDisplayTabsList();

    if (searchText.length == 0) {
      setFieldData(fieldsList);
    }

    return () => {
      setFieldData(fieldsList);
    };
  }, [fieldsList, searchText]);

  const renderSection = (panel: panels, currentPath: any) => {
    return (
      <Section
        panel={panel}
        components={tab}
        handleDrop={handleDrop}
        path={currentPath}
        getTabDetailsByID={getTabDetailsByID}
        onSelectModal={(data: selectFieldModalProps) => {
          setSelectFieldModal(data);
        }}
      />
    );
  };

  const dragPanel = useRef<number>(0);
  const draggedOverPanel = useRef<number>(0);
  // console.log(fieldData);

  const handleTabHeading = () => {
    updateTab(tab.id, tabHeading)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_TABS_EDIT_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((err) => {})
      .finally(() => {
        getTabDetailsByID();
      });
  };

  useEffect(() => {
    // @ts-ignore
    if (document.getElementById("mainTabContent").clientHeight) {
      const value =
        // @ts-ignore
        document.getElementById("mainTabContent").clientHeight;

      setTabHeight(value);
    }
  }, []);

  const getFieldsListAPI = () => {
    // getGroupsList()
    getFieldsList().then(({ data: { fields } }) => {
      dispatch(actions.setFieldssList(fields));
    });
  };

  // console.log("isMobile", isMobile);

  const hasNative = document && document.elementsFromPoint;

  function getDropTargetElementsAtPoint(x: any, y: any, dropTargets: any) {
    return dropTargets.filter((t: any) => {
      const rect = t.getBoundingClientRect();
      return (
        x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
      );
    });
  }

  // use custom function only if elementsFromPoint is not supported
  const backendOptions = {
    getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint,
  };

  return (
    <>
      {openAddFieldModal.show && (
        <FieldsModal
          ModalProps={openAddFieldModal}
          closeModal={() => {
            setOpenAddFieldModal({
              show: false,
            });
          }}
          getFieldsListAPI={getFieldsListAPI}
        />
      )}
      {selectFieldModal.show && (
        <SelectFieldModal
          show={selectFieldModal.show}
          closeModal={() => {
            setSelectFieldModal({
              show: false,
              section: undefined,
              panel: undefined,
            });
          }}
          onSelectedFields={(fields: FieldSelectModalProps[]) => {
            if (selectFieldModal.section && selectFieldModal.panel)
              storeSectionFields(
                selectFieldModal?.panel,
                selectFieldModal?.section,
                fields
              )
                .then((data) => {
                  setSelectFieldModal({
                    show: false,
                    section: undefined,
                    panel: undefined,
                  });
                  successToast(
                    intl.formatMessage({
                      id: "MASTERDATA_TAB_PANEL_FIELD_ADD_SUCCESS_MESSAGE",
                    })
                  );
                })
                .catch((e) => {
                  errorToast(
                    intl.formatMessage({
                      id: "MASTERDATA_TAB_PANEL_FIELD_ADD_FAILURE_MESSAGE",
                    })
                  );
                })
                .finally(() => {
                  getTabDetailsByID();
                });
          }}
          isSelectionModal={true}
          selectedFields={() => {}}
        />
      )}
      <DndProvider
        backend={isMobile ? TouchBackend : HTML5Backend}
        options={backendOptions}
      >
        <div className="mt-3">
          <div
            className={`card mt-5 p-4 d-flex flex-row align-items-center justify-content-between`}
            id="panel-card"
          >
            <div
              className="d-flex flex-row align-items-center"
              style={{
                flex: 1,
              }}
            >
              <input
                type="text"
                value={tabHeading}
                onChange={(e) => {
                  setTabHeading(e.target.value);
                }}
                style={{
                  flex: 1,
                  border: "none",
                  outline: "none",
                }}
                className={`panelHeading mx-5 fw-bold py-4 ${!crudPermission?.edit && "pe-none"}`}
                onBlur={() => {
                  if (tabHeading.length === 0) {
                    setTabHeading(tab.name || "");
                  } else if (tab.name !== tabHeading) {
                    handleTabHeading();
                  }
                }}
              />
            </div>
            <div>
              {showPreview ? (
                <button
                  className="btn  btn-light btn-primary btn-sm me-2"
                  onClick={() => {
                    setPreview(false);
                  }}
                >
                  {intl.formatMessage({ id: "GO_BACK_BUTTON" })}
                </button>
              ) : (
                <button
                  className="btn  btn-light btn-primary btn-sm me-2"
                  onClick={() => {
                    setPreview(true);
                  }}
                >
                  {intl.formatMessage({ id: "PREVIEW_BUTTON" })}
                </button>
              )}
              {/* <button
              className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
              onClick={() => {}}
            >
              <SVGICON src={EditIcon} className="svg-icon-3" />
            </button> */}
            </div>
          </div>
          {!showPreview ? (
            <div className="row">
              {loading ? (
                <SimpleLoader />
              ) : (
                <div className="d-flex flex-column-reverse flex-lg-row  col-lg-12">
                  {/* main content */}
                  <ScrollingComponent
                    className=" d-flex flex-column flex-row-fluid  col-md--9 "
                    id="mainTabContent"
                  >
                    {list && list.length > 0 ? (
                      <ScrollingComponent className="card borderNone card-body mt-4">
                        {tab && tab.panels && tab.panels.length > 0
                          ? tab.panels.map((panel, index) => {
                              const currentPath = `${index}`;
                              return (
                                <div
                                  className={`draggable ${!crudPermission?.edit && "pe-none"}`}
                                  onDragStart={() =>
                                    (dragPanel.current = index)
                                  }
                                  onDragEnter={() =>
                                    (draggedOverPanel.current = index)
                                  }
                                  key={index}
                                  onDragEnd={() => {
                                    if (
                                      dragPanel.current !==
                                      draggedOverPanel.current
                                    ) {
                                      const clonePanels = [...tab.panels];
                                      const deletedSlice = clonePanels.splice(
                                        dragPanel.current,
                                        1
                                      )[0];
                                      clonePanels.splice(
                                        draggedOverPanel.current,
                                        0,
                                        deletedSlice
                                      );
                                      // const temp = clonePanels[dragPanel.current];
                                      // clonePanels[dragPanel.current] =
                                      //   clonePanels[draggedOverPanel.current];
                                      // clonePanels[draggedOverPanel.current] =
                                      //   temp;
                                      onPanelsSortChange(clonePanels);
                                    }
                                  }}
                                  onDragOver={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <React.Fragment key={panel.tab_panel_id}>
                                    <TabDropzone
                                      accept={["panel"]}
                                      data={{
                                        path: currentPath,
                                        childrenCount: panel.sections.length,
                                        panel: panel.tab_panel_id,
                                      }}
                                      onDrop={handleDrop}
                                      path={currentPath}
                                    />
                                    {renderSection(panel, currentPath)}
                                  </React.Fragment>
                                </div>
                              );
                            })
                          : []}
                        <TabDropzone
                          accept={["panel"]}
                          data={{
                            path: `${tab.panels.length}`,
                            childrenCount: tab.panels.length,
                          }}
                          onDrop={handleDrop}
                          isLast
                        />
                      </ScrollingComponent>
                    ) : (
                      <div className="w-100 h-100 card card-body d-flex justify-content-center align-items-center mt-4">
                        <div className="w-100 h-100 firstPanel">
                          <span className="text-muted h5">
                            {intl.formatMessage({
                              id: "TABS_EMPTY_MESSAGE",
                            })}
                          </span>
                          <React.Fragment>
                            <TabDropzone
                              accept={["panel"]}
                              data={{
                                path: "0",
                                childrenCount: "0",
                              }}
                              onDrop={handleDrop}
                              path={"0"}
                            />
                            <div className="w-100 h-100 flex-column d-flex justify-content-center align-items-center">
                              <button
                                type="button"
                                className="btn btn-sm btn-icon btn-primary me-4"
                                onClick={() => {}}
                              >
                                <SVGICON
                                  src={PlusIcon}
                                  className="svg-icon-2"
                                />
                                {/* {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_ADD_CONTACT_TITLE'})} */}
                              </button>
                              <div className="  text-muted fs-6">
                                {intl.formatMessage({
                                  id: "MASTERDATA_TABS_PANELS_DRAG_INFO",
                                })}
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </ScrollingComponent>
                  {/* side bar content */}
                  <div
                    className={clsx(
                      `d-flex flex-column   ms-lg-10 col-lg-3 ${!crudPermission?.edit && "pe-none"}`,
                      tab && tab.panels && tab.panels.length === 0
                        ? "mt-0"
                        : "mt-0"
                    )}
                  >
                    <div
                      className="card card-body mt-4"
                      // style={{
                      //   position: "fixed",
                      //   right: "32px",
                      // }}
                    >
                      <h2 className="">
                        {intl.formatMessage({
                          id: "MASTERDATA_TABS_PANELS_TITLE",
                        })}
                      </h2>
                      {MASTER_DATA_PANELS_TYPE.map((panel, index) => (
                        <div className="sideBar" key={index}>
                          <TabSideBar field={panel} type="panel" />
                        </div>
                      ))}
                      <div className="mt-4 ">
                        <div className="d-flex justify-content-between align-items-center">
                          <h2 className="">
                            {intl.formatMessage({
                              id: "MASTERDATA_TABS_PANELS_FIELDS_TITLE",
                            })}
                          </h2>
                          <button
                            type="button"
                            className="btn btn-sm btn-icon btn-primary"
                            onClick={() => {
                              setOpenAddFieldModal({
                                show: true,
                                type: "new",
                              });
                            }}
                            title="Add Field"
                          >
                            <SVGICON src={PlusIcon} className="svg-icon-2" />
                          </button>
                        </div>

                        <div className="d-flex align-items-center">
                          <SVGICON
                            src={SearchIcon}
                            className="svg-icon svg-icon-1 position-absolute ms-6"
                          />
                          <input
                            type="text"
                            className="form-control form-control-solid ps-15 m-4"
                            placeholder={intl.formatMessage({
                              id: "USERS_SEARCH_PLACEHOLDER",
                            })}
                            onChange={(e) => {
                              onSearchTextChange(e.target.value);
                            }}
                            autoFocus
                            value={searchText || ""}
                          />
                        </div>
                        <ScrollingComponent
                          style={{
                            height: "1000px",
                            overflow: "scroll",
                          }}
                        >
                          {fieldData.map((field: FieldType, index) => (
                            <div className="sideBar" key={index}>
                              <TabSideBar field={field} type="field" />
                            </div>
                          ))}
                        </ScrollingComponent>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <TabPreview dealTab={tab} type="new" currPhase={undefined} />
          )}
        </div>
      </DndProvider>
    </>
  );
};

export default EditTabs;

export const TabSideBar: FC<{
  field: FieldType | { id: number; name: string } | TabType;
  type: string;
}> = ({ field, type }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: type,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    options: {
      dropEffect: "copy",
    },
    item: {
      id: field.id,
      type: type,
      isNew: true,
    },
  }));
  return (
    <div
      className="p-4 my-2 border border-secondary border-rounded bg-white sideBarItem"
      ref={drag}
      style={{
        opacity: isDragging ? 0.4 : 1,
        cursor: "move",
      }}
    >
      {field.name}
    </div>
  );
};

export const Section: FC<{
  panel: panels;
  components: any;
  handleDrop: any;
  path: any;
  getTabDetailsByID: () => void;
  onSelectModal: (data: selectFieldModalProps) => void;
}> = ({
  panel,
  components,
  handleDrop,
  path,
  getTabDetailsByID,
  onSelectModal,
}) => {
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();
  const ref = useRef(null);
  const intl = useIntl();
  // state
  const [panelHeading, setPanelHeading] = useState(
    panel.heading || panel.name || ""
  );

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "panel",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      type: "panel",
      id: panel.tab_panel_id,
    },
  }));

  const opacity = isDragging ? 0 : 1;
  drag(ref);
  const style = {
    // border: "1px solid red",
  };

  const handleDeletePanel = (id: number) => {
    setDeleteModalLoading(true);
    deletePanels([id])
      .then((data) => {
        SuccessAlert(
          intl.formatMessage({
            id: "MASTERDATA_TAB_PANEL_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch((e) => {
        errorAlert(
          intl.formatMessage({
            id: "MASTERDATA_TAB_PANEL_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getTabDetailsByID();
      });
  };

  const renderSection = (section: SectionType, currentPath: any) => {
    return (
      <SectionFields
        section={section}
        components={section}
        handleDrop={handleDrop}
        path={currentPath}
        panel={panel}
        getTabDetailsByID={getTabDetailsByID}
        onSelectModal={onSelectModal}
      />
    );
  };

  const handlePanelHeading = () => {
    updatePanelHeading(panel.tab_panel_id, panelHeading)
      .then((data) => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_TABS_PANEL_HEADING_UPDATE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((e) => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_TABS_PANEL_HEADING_UPDATE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getTabDetailsByID();
      });
  };

  return (
    <>
      <div
        ref={ref}
        style={{ ...style, opacity }}
        className="base draggable row"
      >
        <div className="card  card-flush mb-4">
          <div className="card-header d-flex">
            <div
              className="card-title"
              style={{
                flex: 1,
              }}
            >
              <div className="me-5">
                <SVGICON
                  src={DragIcon}
                  className="svg-icon-2 svg-hover-primary"
                />
              </div>
              <input
                style={{
                  flex: 1,
                  outline: "none",
                  border: "none",
                }}
                type="text"
                value={panelHeading}
                className="panelHeading py-4"
                onChange={(e) => {
                  e.preventDefault();
                  setPanelHeading(e.target.value);
                }}
                onBlur={() => {
                  if (panelHeading.length === 0) {
                    setPanelHeading(panel.heading || panel.name || "");
                  } else if (panel.heading !== panelHeading) {
                    handlePanelHeading();
                  }
                }}
              />
            </div>
            <div className="card-toolbar">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-icon btn-light btn-active-light-danger btn-sm me-1"
                  onClick={() => {
                    showDeleteConfirmModal(
                      intl.formatMessage({
                        id: "TAB_SECTION_DEELTE_CONFORM_MESSAGE",
                      }),
                      () => {
                        handleDeletePanel(panel.tab_panel_id);
                      }
                    );
                  }}
                >
                  <SVGICON src={DeleteIcon} className="svg-icon-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="d-flex flex-xl-row flex-lg-column gap-12">
              {panel.sections.map((section, index) => {
                const currentPath = `${path}-${index}`;

                return (
                  <div
                    style={{
                      flex: 1,
                    }}
                    key={index}
                  >
                    <React.Fragment key={index}>
                      {/* <TabDropzone
                    data={{
                      path: currentPath,
                      childrenCount: section.id === 1 ? 1 : 2,
                    }}
                    onDrop={handleDrop}
                    path={currentPath}
                  /> */}
                      {renderSection(section, currentPath)}
                    </React.Fragment>
                  </div>
                );
              })}
              {/* <TabDropzone
            accept={[]}
              data={{
                path: `${panel.sections.length}`,
                childrenCount: panel.sections.length,
              }}
              onDrop={handleDrop}
              isLast
            /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const SectionFields: FC<{
  section: SectionType;
  components: any;
  handleDrop: () => void;
  path: any;
  panel: panels;
  getTabDetailsByID: () => void;
  onSelectModal: (data: selectFieldModalProps) => void;
}> = ({
  section,
  components,
  handleDrop,
  path,
  panel,
  getTabDetailsByID,
  onSelectModal,
}) => {
  const ref = useRef(null);
  const [cardFields, setCardFields] = useState<FieldType[]>(
    section.fields_data
  );

  useEffect(() => {
    setCardFields(section.fields_data);
  }, [section]);
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();
  const intl = useIntl();
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "field",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      id: section.id,
      type: "section",
    },
  }));

  const opacity = isDragging ? 0 : 1;
  drag(ref);
  const style = {
    // border: "1px solid blue",
  };

  const sortPanelFields = (sortedList: FieldType[]) => {
    const newSortedTabsArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedTabsArr.push({
        id: sortedListItem.id,
        newposition: i + 1,
      });
    });
    sortFieldSections(panel.tab_panel_id, section.id, newSortedTabsArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getTabDetailsByID();
      });
  };

  const renderSection = (field: FieldType, currentPath: any, index: number) => {
    return (
      <SidebarField
        field={field}
        components={index}
        path={currentPath}
        getTabDetailsByID={getTabDetailsByID}
        panel={panel}
        section={section}
        // moveField={moveField}
      />
    );
  };

  const handleSectionDelete = () => {
    setDeleteModalLoading(true);
    deleteSection(panel.tab_panel_id, section.id)
      .then((data) => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_TAB_PANEL_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((e) => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_TAB_PANEL_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getTabDetailsByID();
      });
  };

  const dragField = useRef<number>(0);
  const draggedOverField = useRef<number>(0);

  const draggable = document.getElementById("draggable");

  let isDraggingElement = false;

  draggable?.addEventListener("touchstart", (event) => {
    isDraggingElement = true;
  });

  document.addEventListener("touchmove", (event) => {
    if (isDraggingElement) {
      const touch = event.touches[0];
    }
  });

  document.addEventListener("touchend", () => {
    isDraggingElement = false;
  });

  return (
    <>
      <div
        // ref={ref}
        style={{ ...style, opacity }}
        className={clsx("base draggable row position-relative")}
      >
        {section.fields_data.length > 0 && (
          <div
            className="position-absolute bg-light btn btn-icon btn-active-light-danger d-flex justify-content-center align-items-center"
            style={{
              right: -15,
              top: -10,
              zIndex: 99,
              borderRadius: "50px",
              height: "25px",
              width: "25px",
            }}
            role={"button"}
            onClick={() => {
              showDeleteConfirmModal(
                intl.formatMessage({
                  id: "TAB_SECTION_FIELDS_DELETE_CONFIRM_MESSAGE",
                }),
                () => {
                  handleSectionDelete();
                }
              );
            }}
          >
            <SVGICON
              src={CloseIcon}
              className="svg-icon svg-icon-1 svg-icon-grey-800"
            />
          </div>
        )}
        <div className="card  card-flush mb-4">
          <div className="d-flex card-toolbar justify-content-end align-items-center mt-3">
            <div
              role="button"
              className="text-primary me-4"
              onClick={(e) => {
                onSelectModal({
                  show: true,
                  section: section.id,
                  panel: panel.tab_panel_id,
                });
              }}
            >
              {intl.formatMessage({ id: "TAB_DETAILS_ADD_FIELD_TEXT" })}
            </div>
          </div>
          <div className="card-body py-0">
            <div>
              {cardFields.length > 0 ? (
                cardFields.map((field, index) => {
                  const currentPath = `${path}-${index}`;

                  return (
                    <div
                      className="draggable"
                      onDragOver={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      id="draggable"
                      key={index}
                      onDragStart={() => (dragField.current = index)}
                      onDragEnter={() => (draggedOverField.current = index)}
                      onDragEnd={() => {
                        // console.log(dragField.current, draggedOverField.current);
                        if (dragField.current !== draggedOverField.current) {
                          const cloneFields = [...cardFields];
                          const deletedSlice = cloneFields.splice(
                            dragField.current,
                            1
                          )[0];
                          cloneFields.splice(
                            draggedOverField.current,
                            0,
                            deletedSlice
                          );
                          // const temp = cloneFields[dragField.current];
                          // cloneFields[dragField.current] =
                          //   cloneFields[draggedOverField.current];
                          // cloneFields[draggedOverField.current] = temp;
                          sortPanelFields(cloneFields);
                        }
                      }}
                    >
                      <React.Fragment key={index}>
                        {index === 0 && (
                          <TabDropzone
                            accept={["field"]}
                            data={{
                              path: currentPath,
                              childrenCount: cardFields.length,
                              panel: panel.tab_panel_id,
                              section: section?.id,
                            }}
                            onDrop={handleDrop}
                            path={currentPath}
                          />
                        )}
                        {renderSection(field, currentPath, index)}
                      </React.Fragment>
                    </div>
                  );
                })
              ) : (
                <TabDropzone
                  accept={["field"]}
                  data={{
                    path: `${section.fields_data.length}`,
                    childrenCount: section.fields_data.length,
                    panel: panel.tab_panel_id,
                    section: section?.id,
                  }}
                  onDrop={handleDrop}
                  isLast
                />
              )}
              <TabDropzone
                accept={["field"]}
                data={{
                  path: `${section.fields_data.length}`,
                  childrenCount: section.fields_data.length,
                  panel: panel.tab_panel_id,
                  section: section?.id,
                }}
                onDrop={handleDrop}
                isLast
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const SidebarField: FC<{
  field: FieldType;
  components: any;
  path: any;
  getTabDetailsByID: () => void;
  panel: panels;
  section: SectionType;
  // moveField: (dragI: any, hoverI: any, item: any) => void;
}> = ({
  field,
  components,
  path,
  getTabDetailsByID,
  panel,
  section,
  // moveField,
}) => {
  const style = {
    // border: "1px dashed black",
    padding: "0.5rem 1rem",
    backgroundColor: "white",
    cursor: "move",
    marginBottom: ".5rem",
  };

  // context
  const {
    showDeleteConfirmModal,
    setDeleteModalLoading,
    hideDeleteConfirmModal,
  } = useGlobalModalContext();

  const ref = useRef(null);
  const intl = useIntl();

  const [, drop] = useDrop({
    accept: ["field"],
  });

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "field",
    end(draggedItem, monitor) {
      const res = monitor.getDropResult();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      id: field?.id,
      type: "field",
      path: path,
      fieldType: field?.type,
      name: field?.name,
      index: components,
      isFieldSorting: true,
    },
  }));

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const handleFieldDelete = () => {
    setDeleteModalLoading(true);
    deleteSelectedField([field.tab_panels_fields_id])
      .then((data) => {})
      .catch((e) => {})
      .finally(() => {
        hideDeleteConfirmModal();
        setDeleteModalLoading(false);
        getTabDetailsByID();
      });
  };

  return (
    <div
      ref={ref}
      style={{ opacity }}
      className="position-relative"
      key={field?.id}
    >
      <div className="d-flex">
        <div className="me-5 mt-4">
          <SVGICON src={DragIcon} className="svg-icon-2 svg-hover-primary" />
        </div>
        <input
          type="text"
          className="form-control form-control-lg form-control-solid mb-3 "
          value={field?.placeholder ? field.placeholder : field?.name}
          disabled
        />
        {field?.tooltip && (
          <div
            style={{
              position: "relative",
              cursor: "pointer",
            }}
          >
            <button className="btn btn-icon btn-light  btn-md ms-1 me-3 ">
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content={field.tooltip}
                data-tooltip-place="top"
              >
                <i className="bi bi-info-circle-fill fs-3 svg-icon"></i>
              </span>
            </button>

            <Tooltip
              id="my-tooltip"
              place="top"
              style={{
                color: "black",
                backgroundColor: "#e9ecef",
                zIndex: 999,
                whiteSpace: "normal",
                maxWidth: "250px",
                wordWrap: "break-word",
              }}
              className="btn text-dark"
              border="1px solid #6c757d"
            />
          </div>
        )}
        {
          <button
            className="btn btn-icon btn-light btn-active-light-danger btn-md ms-1 "
            onClick={() => {
              showDeleteConfirmModal(
                intl.formatMessage({ id: "TAB_FIELD_DEELTE_CONFIRM_MESSAGE" }),
                () => {
                  handleFieldDelete();
                }
              );
            }}
          >
            <SVGICON src={DeleteIcon} className="svg-icon-3" />
          </button>
        }
      </div>
      <div
        className="form-check form-switch form-switch-sm form-check-custom form-check-solid position-absolute"
        style={{ top: "15px", right: "95px" }}
      >
        <input
          className={clsx(
            "form-check-input",
            field?.tooltip ? "position-relative" : "position-absolute"
          )}
          type="checkbox"
          name="notifications"
          value=""
          checked={field?.is_required === 1 ? true : false}
          style={{ width: "45px", height: "22px" }}
          onChange={(e) => {
            updateIsRequired(
              panel?.tab_panel_id,
              section?.id,
              field?.id,
              e.target.checked ? 1 : 0
            ).finally(() => {
              getTabDetailsByID();
            });
          }}
        />
      </div>
    </div>
  );
};
