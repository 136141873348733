import React, { FC, useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";

// images
import LinkIcon from "../../../../_metronic/assets/icons/link.svg";
import CopyIcon from "../../../../_metronic/assets/icons/copy.svg";

// custom
import { SVGICON } from "../../../../_metronic/helpers";
import { FolderType, FileType } from "../types/getFilemanagerDataResponseType";
import { SuccessAlert, errorAlert } from "../../../sharedComponents/Alert";
import { deleteFile, deleteFolder, optimizeFiles } from "../redux";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { useIntl } from "react-intl";
import { successToast } from "../../../sharedComponents/toasts/Toasts";
import { isImageType } from "../../../utils/isFileType";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import config from "../../../config";
import ActionsDropdown from "../../../sharedComponents/ActionsDropdown/ActionsDropdown";
import Cookies from "js-cookie";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";

interface FolderModalProps {
  show: boolean;
  type?: string;
  item?: FolderType | FileType;
  format?: "folder" | "file";
}

interface FilemanagerActionsDropdownProps {
  item: FolderType | FileType;
  format: "folder" | "file";
  patent_id?: number | null;
  getFilemanagerDataAPI?: (folder_id?: number | null) => void;
  closeFolderActionsModal?: () => void;
  setShowFolderActionsModal?: (values: FolderModalProps) => void;
}

const FilemanagerActionsDropdown: FC<FilemanagerActionsDropdownProps> = ({
  item,
  format,
  patent_id,
  getFilemanagerDataAPI,
  closeFolderActionsModal,
  setShowFolderActionsModal,
}) => {
  const user = useSelector<RootState>(({ auth }) => auth.user) as UserType;
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();
  const intl = useIntl();

  const [loader, setLoader] = useState(false);
  const timerRef = useRef(null);
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, 'file-manager'))

  const deleteExistingFile = (id: number) => {
    setDeleteModalLoading(true);
    deleteFile(id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "FILEMANAGER_DELETE_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "FILEMANAGER_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        closeFolderActionsModal?.();
        // recall  get filemanager  API
        getFilemanagerDataAPI?.(patent_id);
      });
  };
  const deleteExistingFolder = (id: number) => {
    setDeleteModalLoading(true);
    deleteFolder(id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "FILEMANAGER_FOLDER_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "FILEMANAGER_FOLDER_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        closeFolderActionsModal?.();
        // recall  get categories list API
        getFilemanagerDataAPI?.(patent_id);
      });
  };

  const downloadFile = (file: FileType) => {
    const accessToken = Cookies.get("accessToken");

    fetch(`${config.API_URL}/mediaManager/downloadFile/` + file.id, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((data) => {
        successToast(intl.formatMessage({ id: "DOWNLOAD_SUCCESS_MESSAGE" }));
        return data;
      })
      .then((data) => data.blob())
      .then((res) => blobToBase64(res, file));
    // .then((result) => console.log('this is base64', result))
  };

  const blobToBase64 = (blob: any, file: FileType) => {
    var reader = new FileReader();
    reader.onload = function () {
      const readUrl = reader.result?.toString() || "";
      saveAs(readUrl, file.name);
    };
    return reader.readAsDataURL(blob);
  };

  useEffect(() => {
    const d = document.getElementById("link-generate-dropdown");
    if (d) {
      d.addEventListener("show.bs.dropdown", (e) => {
        setLoader(true);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      });
      // d.addEventListener('shown.bs.dropdown', (e) => console.log('shown.bs.dropdown'))
      d.addEventListener("hide.bs.dropdown", (e) => {
        setLoader(false);
        // @ts-ignore
        timerRef.current && clearTimeout(timerRef.current);
      });
      // d.addEventListener('hidden.bs.dropdown', (e) => console.log('hidden.bs.dropdown'))
    }
  });

  const optimiseFilesAPI = (file: FileType) => {
    const files_id = [file.id];
    optimizeFiles(user.id, files_id)
      .then(() => {})
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "FILEMANAGER_OPTIMIZATION_ERROR_MESSAGE" })
        );
      })
      .finally(() => {
        getFilemanagerDataAPI?.(patent_id);
      });
  };

  const onEditClick = () => {
    if (format === "folder") {
      setShowFolderActionsModal?.({
        show: true,
        type: "edit",
        item: item,
        format: "folder",
      });
    } else {
      setShowFolderActionsModal?.({
        show: true,
        type: "edit",
        item: item,
        format: "file",
      });
    }
  };

  const onDelete = () => {
    if (format === "folder") {
      showDeleteConfirmModal(
        intl.formatMessage({ id: "FILEMANAGER_DELETE_CONFIRM_MESSAGE" }),
        () => {
          deleteExistingFolder(item.id);
        }
      );
    } else {
      showDeleteConfirmModal(
        intl.formatMessage({ id: "FILEMANAGER_FILE_DELETE_CONFIRM_MESSAGE" }),
        () => {
          deleteExistingFile(item.id);
        }
      );
    }
  };

  const dropdownOptions = [
    ...(crudPermission?.edit ? [{ name: intl.formatMessage({ id: "COMMON_EDIT" }), onClick: onEditClick }]:[]),
    // @ts-ignore
    format === "file" && isImageType(item)
      ? {
          name: intl.formatMessage({
            id: "FILEMANAGER_ACTIONS_DROPDOWN_OPTIMIZE",
          }),
          onClick: () => {
            // @ts-ignore
            optimiseFilesAPI(item);
            document.getElementById("kt_body")?.click();
          },
        }
      : null,
    format === "file"
      ? {
          name: intl.formatMessage({ id: "DOWNLOAD" }),
          onClick: () => {
            // @ts-ignore
            downloadFile(item);
          },
        }
      : null,
      ...(crudPermission?.delete ? [{
        name: intl.formatMessage({ id: "COMMON_DELETE" }),
        onClick: onDelete,
        className: "text-danger",
      },]:[]),
    
  ];

  return (
    <div className="d-flex flex-row  justify-content-end">
      {/* generate link button */}
      {format === "file" && (
        <div className="dropdown " id="link-generate-dropdown">
          <button
            type="button"
            className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <SVGICON src={LinkIcon} />
          </button>
          <div
            className="dropdown-menu  menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-300px py-4"
            aria-labelledby="dropdownMenuButton1"
          >
            {!loader ? (
              <div
                className="d-flex flex-column text-start px-4"
                data-kt-filemanger-table="copy_link_result"
              >
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="d-flex">
                    <span className="svg-icon svg-icon-2 svg-icon-success me-3">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                    <div className="fs-6 text-dark">
                      {intl.formatMessage({
                        id: "FILEMANAGER_FILE_SHARE_LINK",
                      })}
                    </div>
                  </div>
                  <div
                    role="button"
                    onClick={() => {
                      //@ts-ignore
                      navigator.clipboard
                        ?.writeText(item.file_path)
                        .then(() => {
                          successToast(
                            intl.formatMessage({
                              id: "FILEMANAGER_FILE_LINK_COPY_SUCCESS_MESSAGE",
                            })
                          );
                        });
                    }}
                  >
                    <SVGICON src={CopyIcon} className="svg-icon-2" />
                  </div>
                </div>
                <div className="form-control form-control-sm">
                  {item.file_path || ""}
                </div>
              </div>
            ) : (
              <div className="menu-item px-10 d-flex align-items-center">
                <div className="me-4">
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
                <span className="fs-7 text-gray-800">
                  {intl.formatMessage({
                    id: "FILEMANAGER_FILE_SHARE_LINK_GENERATING",
                  })}
                </span>
              </div>
            )}
          </div>
        </div>
      )}

      {/* actions button */}
      <div className="dropdown">
        <ActionsDropdown options={dropdownOptions} resource_slug="file-manager" />
      </div>
    </div>
  );
};

export default FilemanagerActionsDropdown;
