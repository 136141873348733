import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";

import { useLang } from "../../../../_metronic/i18n/Metronici18n";

// css
import "../Tasks.scss";

import { SVGICON } from "../../../../_metronic/helpers";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import CloseIcon from "../../../../_metronic/assets/icons/close.svg";
import FilterIcon from "../../../../_metronic/assets/icons/filter.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import notFound from "../../../../_metronic/assets/icons/notFound.png";

import Tabs from "../../../sharedComponents/tabs/Tabs";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import Pagination from "../../../sharedComponents/pagination/Pagination";

import TasksListItem from "./TasksListItem";
import AddTaskModal from "./AddEditTaskModal";
import TasksFilterModal from "./TasksFilterModal";
import DateRangeFilterModal from "./DateRangeFilterModal";
import { FilterTag } from "./FilterTag";
import TaskResponsibleUsersModal from "./TaskResponsibleUsersModal";

import { RootState } from "../../../../setup";
import { getUsers, actions } from "../../userManagement/redux";
import { getAllDeals, getTasks } from "../redux";
import { Assignee, TaskType } from "../types/TasksResponseType";
import {
  TaskAddEditModalProps,
  TaskFilterModalProps,
} from "../types/TasksPropsType";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";

interface Props {
  deals_id?: number;
  type?: string;
  isMenuPoint?: boolean;
}

const TasksList: FC<Props> = ({ deals_id, isMenuPoint = false }) => {
  const intl = useIntl();
  const local = useLang();
  const dispatch = useDispatch();

  // Filter Tabs Options
  const FILTER_TABS = JSON.parse(
    intl.formatMessage({ id: "TASKS_FILTER_TABS" })
  ) as any[];

  // Custom Pagination Items
  const customPaginationItems = JSON.parse(
    intl.formatMessage({ id: "TASKS_NUMBER_ITEMS_PER_PAGE_OPTIONS" })
  ) as any[];

  // selectors
  const usersList = useSelector<RootState>(
    // @ts-ignore
    ({ userManagement }) => userManagement.users
  ) as UserType[];
  const user = useSelector<RootState>(({ auth }) => auth.user) as UserType;

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(userRoleDetails, "tasks");

  // filters state
  // const [isPageMenuPoint, setIsPageMenuPoint] = useState(isMenuPoint);
  const [filteredTasks, setFilteredTasks] = useState<TaskType[]>([]);
  const [filters, setFilters] = useState<any>({});
  const [tabFilters, setTabFilters] = useState<any>({});
  const [isFilterBadgeClicked, setIsFilterBadgeClicked] =
    useState<boolean>(false);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  // modal state
  const [showAddTaskModal, setShowAddTaskModal] =
    useState<TaskAddEditModalProps>({
      show: false,
      deals_id: deals_id,
      usersDeals: [],
    });
  const [showAllUsersModal, setShowAllUsersModal] = useState<{
    show: boolean;
    users: undefined | Assignee[];
  }>({
    show: false,
    users: undefined,
  });
  const [showTasksFilterModal, setShowTasksFilterModal] =
    useState<TaskFilterModalProps>({
      show: false,
      isPageMenuPoint: isMenuPoint,
      usersDeals: [],
    });

  // component data state
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tasks, setTasks] = useState<TaskType[]>([]);

  // search state
  const [searchText, setSearchText] = useState<string>("");
  const [tempSearchText, setTempSearchText] = useState<string>("");

  // tabs and pagination state
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    isSuperAdmin ? 0 : 1
  );
  const [activePage, setActivePage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    customPaginationItems[0].value
  );

  // data API
  const getTasksListAPI = () => {
    setLoading(true);
    getTasks(deals_id)
      .then(({ data }) => {
        setTasks(data?.data);
        setIsSuperAdmin(data?.isSuperAdmin);
        setActiveTabIndex(data?.isSuperAdmin ? 0 : 1);
        setShowTasksFilterModal((prev) => ({
          ...prev,
          isPageMenuPoint: isMenuPoint,
        }));
      })
      .catch((error) => console.error(error?.response?.data?.message))
      .finally(() => {
        setLoading(false);
      });
  };

  const updateTasksListOnStatusChangeAPI = () => {
    getTasks(deals_id)
      .then(({ data }) => {
        setTasks(data?.data);
      })
      .catch((error) => console.error(error?.response?.data?.message));
  };

  const getAllDealsAPI = () => {
    getAllDeals()
      .then(({ data }) => {
        setShowAddTaskModal((prev) => ({
          ...prev,
          user: user,
          usersDeals: data?.deals_details,
        }));
        setShowTasksFilterModal((prev) => ({
          ...prev,
          usersDeals: data?.deals_details,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // effects
  useEffect(() => {
    if (tempSearchText.length === 0) {
      setTempSearchText("");
      setSearchText("");
    }
  }, [tempSearchText]);

  useEffect(() => {
    handleSetTabFilters();
  }, [activeTabIndex, itemsPerPage]);

  useEffect(() => {
    handleFilterSubmit();
  }, [tabFilters, filters, searchText, tasks]);

  useEffect(() => {
    handleFilterSubmit();
    setIsFilterBadgeClicked(false);
  }, [isFilterBadgeClicked]);

  useEffect(() => {
    getTasksListAPI();
    getAllDealsAPI();
    handleSetTabFilters();
    if (usersList.length === 0) {
      getUsers()
        .then(({ data: { users } }) => {
          dispatch(actions.setUsersList(users));
        })
        .catch((error) => console.log(error?.response?.data?.message));
    }
  }, []);

  // handlers
  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  const onSearchTextChange = (text: string) => {
    setTempSearchText(text || "");
  };

  const onTabChange = (index: number) => {
    if (index !== activeTabIndex) {
      setActiveTabIndex(index);
      setActivePage(1);
    }
    if (index === 7) {
      setShowDatePicker(true);
    }
  };

  // const removeUserFromFiltersHandle = (userId: number) => {
  //   const remainingUsers =
  //     filters?.responsibles?.filter((id: number) => id !== userId) || [];
  //   setFilters(
  //     remainingUsers.length
  //       ? { ...filters, responsibles: remainingUsers }
  //       : _.omit(filters, "responsibles")
  //   );
  //   setIsFilterBadgeClicked(true);
  // };

  // const removeDealFromFiltersHandle = (dealId: number) => {
  //   const remainingUsers =
  //     filters?.deals?.filter((item: any) => item.id !== dealId) || [];
  //   setFilters(
  //     remainingUsers.length
  //       ? { ...filters, deals: remainingUsers }
  //       : _.omit(filters, "deals")
  //   );
  //   setIsFilterBadgeClicked(true);
  // };

  const handleSetTabFilters = () => {
    const filterOptions: Record<number, object> = {
      0: {},
      1: { showAdminTasks: isSuperAdmin },
      2: { status: "to-do" },
      3: { status: "overdue" },
      4: { date: moment().format("YYYY-MM-DD") },
      5: { date: moment().add(1, "days").format("YYYY-MM-DD") },
      6: {
        week_start_date: moment().startOf("week").format("YYYY-MM-DD"),
        week_end_date: moment().endOf("week").format("YYYY-MM-DD"),
      },
      7: {},
    };
    setTabFilters(filterOptions[activeTabIndex] || ((prev: any) => prev));
  };

  const handleFilterSubmit = () => {
    let filteredTasks = [...tasks];

    // Filter by task type
    if (filters?.type) {
      filteredTasks = filteredTasks.filter(
        (task) => task.type === filters.type
      );
    }

    // Filter by task priority
    if (filters?.priority) {
      filteredTasks = filteredTasks.filter(
        (task) => task.priority === filters.priority
      );
    }

    // Filter by deals
    if (filters?.deals) {
      filteredTasks = filteredTasks.filter((task) =>
        filters.deals.some((item: { id: number }) => {
          return item.id === task?.deal?.id;
        })
      );
    }

    // Filter by responsible persons
    if (filters?.responsibles?.length) {
      filteredTasks = filteredTasks.filter((task) =>
        filters.responsibles.every((person: number) =>
          task.assignees.some((assignee) => assignee.id === person)
        )
      );
    }

    // Filter superadmin's tasks
    if (tabFilters?.showAdminTasks === true) {
      filteredTasks = filteredTasks.filter(
        (task) =>
          task.created_user_details.createdUserid === user.id ||
          task.assignees.some((item) => item.id === user.id)
      );
    }

    // Filter by overdue tasks
    if (tabFilters?.status === "overdue") {
      const now = moment();
      filteredTasks = filteredTasks.filter(
        (task) =>
          moment(task.due_date, "YYYY-MM-DD HH:mm:ss").isBefore(now) &&
          task.status === "to-do"
      );
    }

    // Filter by "to-do" tasks
    if (tabFilters?.status === "to-do") {
      filteredTasks = filteredTasks.filter((task) => task.status === "to-do");
    }

    // Filter by specific date
    if (tabFilters?.date) {
      const formattedDate = moment(tabFilters.date).format("YYYY-MM-DD");
      filteredTasks = filteredTasks.filter(
        (task) => moment(task.due_date).format("YYYY-MM-DD") === formattedDate
      );
    }

    // Filter by this week date range
    if (tabFilters?.week_start_date && tabFilters?.week_end_date) {
      const startDate = moment(tabFilters.week_start_date);
      const endDate = moment(tabFilters.week_end_date);
      filteredTasks = filteredTasks.filter((task) =>
        moment(task.due_date).isBetween(startDate, endDate, undefined, "[]")
      );
    }

    // Filter by date range
    if (tabFilters?.start_date && tabFilters?.end_date) {
      const startDate = moment(tabFilters.start_date);
      const endDate = moment(tabFilters.end_date);
      filteredTasks = filteredTasks.filter((task) =>
        moment(task.due_date).isBetween(startDate, endDate, undefined, "[]")
      );
    }

    /**
     * Filter by search text in -
     * - task title or task description or
     * - task deal contact firstname or task deal contact lastname or
     * - task deal partner name or
     * - task deal name
     */
    if (searchText) {
      const searchTextLower = searchText.toLowerCase();
      filteredTasks = filteredTasks.filter(
        (task) =>
          task?.title?.toLowerCase().includes(searchTextLower) ||
          task?.description?.toLowerCase().includes(searchTextLower) ||
          task?.deal?.contacts?.firstname
            ?.toLowerCase()
            ?.includes(searchTextLower) ||
          task?.deal?.contacts?.lastname
            ?.toLowerCase()
            ?.includes(searchTextLower) ||
          task?.deal?.partners?.name.toLowerCase().includes(searchTextLower) ||
          task?.deal?.name.toLowerCase().includes(searchTextLower)
      );
    }

    filteredTasks.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

    // Update the state with the filtered tasks
    setFilteredTasks(filteredTasks);
  };

  const handleRemoveFilter = (key: string) => {
    const filtersCopy = _.omit(filters, key);
    setFilters(filtersCopy);
    handleSetTabFilters();
    setIsFilterBadgeClicked(true);
  };

  // Memoized pagination logic
  const paginatedTasks = useMemo(() => {
    const startIndex = itemsPerPage * (activePage - 1);
    return filteredTasks.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredTasks, activePage, itemsPerPage]);

  const totalPages = Math.ceil(filteredTasks.length / itemsPerPage);

  useEffect(() => {
    if (paginatedTasks.length === 0 && filteredTasks.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedTasks]);

  return (
    <div className={clsx("card border-0", isMenuPoint && "px-0 pt-8 mt-4")}>
      {showAddTaskModal.show && (
        <AddTaskModal
          modalProps={showAddTaskModal}
          closeModal={() => {
            setShowAddTaskModal((prev) => ({
              ...prev,
              show: false,
              action: "",
              taskDetails: undefined,
            }));
          }}
          getTasksListAPI={getTasksListAPI}
        />
      )}
      {showTasksFilterModal.show && (
        <TasksFilterModal
          modalProps={showTasksFilterModal}
          filters={filters}
          setFilters={setFilters}
          closeModal={() => {
            setShowTasksFilterModal((prev) => ({ ...prev, show: false }));
          }}
        />
      )}
      {showAllUsersModal.show && (
        <TaskResponsibleUsersModal
          show={showAllUsersModal.show}
          closeModal={() => {
            setShowAllUsersModal({
              ...showAllUsersModal,
              show: false,
            });
          }}
          selectedUsers={showAllUsersModal.users}
          isSelectionModal={false}
        />
      )}
      {showDatePicker && (
        <DateRangeFilterModal
          filters={filters}
          setTabFilters={setTabFilters}
          closeModal={() => setShowDatePicker(false)}
        />
      )}

      <div
        className={clsx("card-header d-block border-0", !isMenuPoint && "px-2")}
      >
        <div className="d-flex justify-content-between align-items-end">
          {/* Search Box */}
          <div className="d-flex align-items-center" style={{ borderWidth: 1 }}>
            <div className="position-relative">
              {/* Clear Search Icon */}
              {tempSearchText && (
                <div
                  className="position-absolute"
                  style={{
                    right: 8,
                    top: 10,
                    zIndex: 99,
                  }}
                  role={"button"}
                  onClick={() => {
                    setTempSearchText("");
                    setSearchText("");
                  }}
                >
                  <SVGICON src={CloseIcon} className="svg-icon svg-icon-1 " />
                </div>
              )}
              {/* Search Input */}
              <input
                type="text"
                className="form-control form-control-solid w-250px position-relative"
                placeholder={intl.formatMessage({
                  id: "PARTNERS_LIST_SEARCH_PLACEHOLDER",
                })}
                onChange={(e) => {
                  onSearchTextChange(e.target.value);
                }}
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  paddingRight: "30px",
                }}
                value={tempSearchText}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (tempSearchText && tempSearchText.length >= 3) {
                      setSearchText(tempSearchText);
                    }
                  }
                }}
              />
              {/* search text error */}
              {tempSearchText && tempSearchText.length < 3 && (
                <div
                  className="text-danger position-absolute"
                  style={{ top: "50px", width: "max-content" }}
                >
                  <div role="alert">
                    {intl.formatMessage({
                      id: "PARTNERS_LIST_SEARCH_VALIDATION_MESSAGE",
                    })}
                  </div>
                </div>
              )}
            </div>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => {
                if (tempSearchText && tempSearchText.length >= 3) {
                  setSearchText(tempSearchText);
                }
              }}
              style={{
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
                padding: "11px",
              }}
            >
              <SVGICON src={SearchIcon} className="svg-icon svg-icon-2" />
            </button>
          </div>

          {/* Filters Badges and Actions Buttons */}
          <div className="d-flex justify-content-end align-items-center">
            {/* Filters Badges -> visible above 1300px width screen */}
            <div className="justify-content-end align-items-center gap-2 ms-0 me-4 filter-badges-container-top">
              {!showTasksFilterModal.show &&
                Object.keys(filters).map((key, i) => {
                  if (filters[key] !== null) {
                    return (
                      <FilterTag
                        key={`filter-top-${i}`}
                        keyName={key}
                        value={key}
                        onRemove={handleRemoveFilter}
                      />
                    );
                  }
                  return null;
                })}
            </div>
            {/* Action Buttons: Add Btn, Filter Btn */}
            <div className="d-flex">
              <>
                {crudPermission?.create && (
                  <button
                    type="button"
                    className="btn btn-primary me-4 px-4"
                    style={{ minWidth: "125px" }}
                    onClick={() => {
                      setShowAddTaskModal((prev) => ({
                        ...prev,
                        show: true,
                        action: "new",
                      }));
                    }}
                  >
                    <SVGICON src={PlusIcon} className="svg-icon-2" />
                    <span className="align-middle">
                      {intl.formatMessage({ id: "TASKS_ADD_NEW_TASK" })}
                    </span>
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-light px-4"
                  onClick={() => {
                    setShowTasksFilterModal((prev) => ({
                      ...prev,
                      show: true,
                    }));
                  }}
                >
                  <SVGICON src={FilterIcon} className="svg-icon-2 me-0" />
                </button>
              </>
            </div>
          </div>
        </div>

        {/* Filters Badges -> visible below 1300px width screen */}
        <div className="justify-content-end align-items-center gap-2 mt-4 filter-badges-container-bottom">
          {!showTasksFilterModal.show &&
            Object.keys(filters).map((key, i) => {
              if (filters[key] !== null) {
                return (
                  <FilterTag
                    key={`filter-bottom-${i}`}
                    keyName={key}
                    value={key}
                    onRemove={handleRemoveFilter}
                  />
                );
              }
              return null;
            })}
        </div>

        {/* Filter Tabs */}
        <div className="d-flex justify-content-end">
          <div className="border-bottom border-1 w-100 d-flex justify-content-end align-items-center pt-5 pb-0">
            {/* Debugging */}
            {/*
            <div>
              <span className="me-4">{activeTabIndex}</span>
              <span className="me-4">{JSON.stringify(filters)}</span>
              <span className="me-4">{JSON.stringify(tabFilters)}</span>
            </div>
            */}
            <Tabs
              className="justify-content-end"
              activeIndex={activeTabIndex}
              onActiveIndexChange={(index) => onTabChange(index)}
              tabs={[
                isSuperAdmin && FILTER_TABS[0].name,
                ...FILTER_TABS.map((item) => item.name).slice(1, 7),
                <div className="d-flex align-items-center">
                  {tabFilters?.start_date && tabFilters?.end_date ? (
                    <div className="d-inline-flex">
                      {local === "de"
                        ? moment(tabFilters?.start_date).format("DD.MM.yyyy")
                        : moment(tabFilters?.start_date).format("YYYY-MM-DD")}
                      <div className="px-1">{"-"}</div>
                      {local === "de"
                        ? moment(tabFilters?.end_date).format("DD.MM.yyyy")
                        : moment(tabFilters?.end_date).format("YYYY-MM-DD")}
                    </div>
                  ) : (
                    // Select Date
                    FILTER_TABS[7].name
                  )}
                </div>,
              ]}
            />
          </div>
        </div>
      </div>

      <div className={clsx("card-body py-0", !isMenuPoint && "px-0")}>
        <div className="table-responsive" style={{ minHeight: "350px" }}>
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                <th className="w-25px pb-3 pt-4">
                  {/* <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <Checkbox
                      disabled={true}
                      // onChange={onAllItemsCheckChange}
                      // checked={checkAllItems}
                    />
                  </div> */}
                </th>
                <th className="min-w-125px pb-3 pt-4">
                  {intl.formatMessage({ id: "TASKS_COLUMN_DUE_DATE" })}
                </th>
                <th className="min-w-125px pb-3 pt-4">
                  {intl.formatMessage({ id: "TASKS_COLUMN_TASK_NAME" })}
                </th>
                <th className="min-w-125px pb-3 pt-4">
                  {intl.formatMessage({ id: "TASKS_COLUMN_DEAL" })}
                </th>
                <th className="min-w-125px pb-3 pt-4">
                  {intl.formatMessage({ id: "TASKS_COLUMN_CONTACT" })}
                </th>
                <th className="min-w-125px pb-3 pt-4">
                  {intl.formatMessage({ id: "TASKS_COLUMN_PHONE" })}
                </th>
                <th className="min-w-125px pb-3 pt-4">
                  {intl.formatMessage({ id: "TASKS_COLUMN_RESPONSIBLE" })}
                </th>
                <th className="min-w-100px text-end pb-3 pt-4">
                  {intl.formatMessage({ id: "TASKS_COLUMN_ACTIONS" })}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td valign="top" colSpan={7} className="dataTables_empty">
                    <SimpleLoader />
                  </td>
                </tr>
              ) : (
                paginatedTasks.map((task, i) => (
                  <TasksListItem
                    key={i}
                    task={task}
                    setShowAllUsersModal={setShowAllUsersModal}
                    getTasksListAPI={updateTasksListOnStatusChangeAPI}
                    setShowAddTaskModal={setShowAddTaskModal}
                  />
                ))
              )}

              {/* no data */}
              {!loading && paginatedTasks.length === 0 && (
                <tr className="odd">
                  <td valign="top" colSpan={7} className="dataTables_empty">
                    <div className="d-flex flex-column flex-center">
                      <img
                        alt="not-found"
                        src={notFound}
                        className="mw-400px"
                      />
                      <div className="fs-1 fw-bolder text-dark mb-4">
                        {intl.formatMessage({
                          id: "TASKS_LIST_NOITEMS_FOUND",
                        })}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          {filteredTasks.length > 0 && (
            <Pagination
              totalPages={totalPages}
              activePage={activePage}
              onPageClick={onPageClick}
              showItemsPerPage
              itemsPerPage={itemsPerPage}
              customItems={customPaginationItems}
              setItemsPerPage={(count) => {
                setItemsPerPage(count);
                setActivePage(1);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TasksList;
