import axios from "axios";
import {
  DealPipelineDetailsResponseType,
  DealPipelineResponseType,
  DealResponseType,
  LogResponseType,
  PhaseLogResponseType,
} from "../types/DealResponseType";
import { TabsByIdResponseType } from "../../projectMasterData/types/TabsResponseType";

export function getDealPipelines() {
  return axios.get<DealPipelineResponseType>(`/pipelines/deals`);
}

export function getDealPipelineDetailsById(pipelines_id: number,fromTask?:string,deal_id?:number,pipelines_phases_id?: number) {
  let url = `/pipelines/deals/${pipelines_id}`;

  if (fromTask) url += `/${fromTask}`;
  if (deal_id) url += `/${deal_id}`;
  if (pipelines_phases_id) url += `/${pipelines_phases_id}`;
  return axios.post<DealPipelineDetailsResponseType>(
    url
  );
}

export function addDeal(
  pipelines_id: number,
  name: string,
  partners_id: number | undefined,
  contacts_id: number | undefined
) {
  return axios.post(`/deals`, {
    pipelines_id,
    name,
    partners_id,
    contacts_id,
  });
}

export function getDealDetails(deal_id: number) {
  return axios.get<DealResponseType>(`/deals/${deal_id}`);
}

export function deleteDeal(deal_id: number,pipeline_id:number) {
  return axios.delete(`/deals/${deal_id}/${pipeline_id}`);
}

export function getDealsTabByID(
  id: number,
  deal_id: number,
  pipeline_phase_tab_id: number
) {
  return axios.get<TabsByIdResponseType>(
    `/deals/tabs/${id}/deals/${deal_id}/${pipeline_phase_tab_id}`
  );
}

export function storeDealFieldValue(
  deal_id: number,
  pipeline_id: number,
  pipelines_phases_tabs_id: number,
  fields_id: number,
  value?: any,
  value_json?: any
) {
  return axios.post(
    `/deals/fields/${deal_id}/${pipeline_id}`,
    {
      pipelines_phases_tabs_id,
      fields_id,
      value,
      value_json,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        // console.log('progressEventprogressEventprogressEvent', progressEvent)
      },
    }
  );
}

export function storeDealFieldFiles(deal_id: number, value_json: any) {
  return axios.post(`/deals/fields/${deal_id}`, value_json, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      // console.log('progressEventprogressEventprogressEvent', progressEvent)
    },
  });
}

export function changePhase(
  deal_id: number,
  from_phase_id: number,
  to_phase_id: number,
  message?: string | null,
  isBackWard?: number
) {
  return axios.post(`/deals/move/${deal_id}${isBackWard ? "/backward" : ""}`, {
    from_phase_id,
    to_phase_id,
    message,
  });
}

export function deleteFieldFile(
  deal_id: number,
  pipeline_id: number,
  pipelines_phases_tabs_id: number,
  tabs_panels_fields_id: number,
  file_id: number
) {
  return axios.post(`/deals/fields/delete/file/${deal_id}/${pipeline_id}`, {
    pipelines_phases_tabs_id,
    tabs_panels_fields_id,
    file_id,
  });
}

export function addresponsible_Persons(deal_id: number, users_id: any[]) {
  return axios.post(`/deals/responsibles/${deal_id}`, {
    users_id,
  });
}

export function deleteresponsible_Persons(deal_id: number, users_id: any[]) {
  return axios.post(`/deals/delete/responsibles/${deal_id}`, {
    users_id,
  });
}

export function updateDeal(deal_id: number, name: string) {
  return axios.post(`/deals/${deal_id}`, {
    name,
  });
}

export function dealMoveToPipeline(deal_id: number, pipeline_id: number) {
  return axios.get(`/deals/move/pipeline/${deal_id}/${pipeline_id}`);
}

export function getPhaseLogs(deal_id: number) {
  return axios.get<PhaseLogResponseType>(`/deals/phases/logs/${deal_id}`);
}

export function getTabFieldLogs(
  deal_id: number,
  pipeline_phase_tab_id: number
) {
  return axios.get<LogResponseType>(
    `/deals/fields/logs/${deal_id}/${pipeline_phase_tab_id}`
  );
}

export function dealsFilter(
  pipeline_id: number,
  responsible_user: number[],
  status: string,
  partners_id: string,
  contacts_id: string,
  tags_id: number | undefined
) {
  return axios.post<DealPipelineDetailsResponseType>(
    `/pipelines/deals/${pipeline_id}`,
    {
      responsible_user,
      status,
      partners_id,
      contacts_id,
      tags_id,
    }
  );
}

export function dealsSort(
  pipeline_id: number,
  order: string,
  column_name: string,
  responsible_user?: number[],
  status?: string,
  partners_id?: string,
  contacts_id?: string
) {
  return axios.post<DealPipelineDetailsResponseType>(
    `/pipelines/deals/${pipeline_id}`,
    {
      order,
      column_name,
      responsible_user,
      status,
      partners_id,
      contacts_id,
    }
  );
}

export function updateTag(deal_id: number, tags_id: number) {
  return axios.post(`/deals/updateTag/${deal_id}`, {
    tags_id,
  });
}

export function AcceptApproval(
  approval_id: number,
  is_approved: number,
  notes: string
) {
  return axios.post(`/deals/acceptApprovals/${approval_id}`, {
    is_approved,
    notes,
  });
}
