import { useDrop } from "react-dnd";
import { dealProps } from "../types/DealPropsType";
import clsx from "clsx";

const PhaseColumn = ({
  // @ts-ignore
  children,
  // @ts-ignore
  className,
  // @ts-ignore
  title,
  // @ts-ignore
  index,
  // @ts-ignore
  count,
  // @ts-ignore
  phase,
  // @ts-ignore
  userData,
}) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "deal",
    drop: () => ({ name: title, index: index }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    // Override monitor.canDrop() function
    canDrop: (item: dealProps) => {
      //   const { currentPhaseName } = item;
      return true;
    },
  });

  const getBackgroundColor = () => {
    if (isOver) {
      if (canDrop) {
        return "#f1faff";
      } else if (!canDrop) {
        return "rgb(255,188,188)";
      }
    } else {
      return "";
    }
  };

  const getMinHeight = () => {
    if (isOver) {
      if (canDrop) {
        return "150px";
      } else if (!canDrop) {
        return "0px";
      }
    } else {
      return "";
    }
  };

  return (
    <>
      <div
        ref={drop}
        className={clsx(
          className,
          isOver && canDrop
            ? "bg-light-primary rounded border-primary border border-dashed"
            : ""
        )}
        style={{
          backgroundColor: getBackgroundColor(),
          flex: 1,
          minHeight: getMinHeight(),
        }}
      >
        <div>
        <div className="phase-title">
          <div className="card p-4 my-3">
            <h4 className="fs-5 m-0 me-2">{title}</h4>
            {/* <div
                className="text-muted badge bg-light-secondary"
                style={{
                  border: "0.5px dotted #ccc",
                  borderRadius: "50px",
                }}
              >
                {count}
              </div> */}
          </div>
        </div>
        </div>
        {children}
      </div>
    </>
  );
};

export default PhaseColumn;
