import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useIntl } from "react-intl";

import { PartnerType } from "../types/getPartnersListResponseType";
import { getPartnerDetails } from "../redux";
import { RootState } from "../../../../setup";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import { boolean } from "yup";
import { isAccessable } from "../../../utils/PermisisionEnabledForResource";
import { SVGICON } from "../../../../_metronic/helpers";
import FilemanagerIcon from "../../../../_metronic/assets/icons/filemanager.svg";
import Tabs from "../../../sharedComponents/tabs/Tabs";
import PartnerCodeAndCountry from "./PartnerCodeAndCountry";
import PartnerLogoSquare from "./PartnerLogoSquare";
import PartnerTagsDetails from "./PartnerTagsDetails";
import PartnerGeneralDetails from "./PartnerGeneralDetails";
import PartnerFinanceDetails from "./PartnerFinanceDetails";
import PartnerContactTypes from "./PartnerContactTypes";
import PartnerNotes from "./PartnerNotes";
import PartnerContacts from "./PartnerContacts";

const EditPartnerDetails = () => {
  const { state = {} } = useLocation();
  const history = useHistory();
  const intl = useIntl();
  // @ts-ignore
  const partnerDetails: PartnerType = state.partnerDetails || {};

  //state
  const [partner, setPartner] = useState<PartnerType>(partnerDetails);
  const [activeTab, setActiveTab] = useState<number>(0);
  const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;
  const [hasAccess, setHassAccess] = useState<boolean>(
    isAccessable(userData, ["is_admin"])
  );

  const getPartnerDetailsAPI = () => {
    partnerDetails.id &&
      getPartnerDetails(partnerDetails.id).then(
        ({ data: { partnerDetails } }) => {
          setPartner(partnerDetails);
          history.replace({ state: { partnerDetails: partnerDetails } });
        }
      );
  };

  const onTabChange = (index: number) => {
    if (index !== activeTab) {
      setActiveTab(index);
    }
  };

  return (
    <>
      <div className="card sababab bgi-position-y-center bgi-no-repeat mt-3">
        <div className="p-8 d-flex flex-row align-items-center">
          <div className="me-5">
            <SVGICON
              src={FilemanagerIcon}
              className="svg-icon-primary svg-icon-3x"
            />
          </div>
          <div className="me-5">
            <span className="text-gray-800 fs-2 fw-bolder ">
              {activeTab === 0
                ? intl.formatMessage({ id: "PARTNERS_EDIT_HEADER" })
                : intl.formatMessage({ id: "PARTNERS_EDIT_CONTACTS_HEADER" })}
            </span>
          </div>
        </div>
        <div className="px-10 mt-2 ml-10">
          <Tabs
            tabs={[
              intl.formatMessage({ id: "PARTNERS_EDIT_TAB_CONTENT" }),
              intl.formatMessage({ id: "PARTNERS_EDIT_TAB_CONTACT" }),
            ]}
            activeIndex={activeTab}
            onActiveIndexChange={(index) => {
              onTabChange(index);
            }}
          />
        </div>
      </div>
      {activeTab === 0 ? (
        <div className="d-flex flex-column">
          <div className="d-flex flex-column flex-lg-row   mt-10">
            <div
              className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10"
              style={{ flex: "0 0 300px" }}
            >
              {/* <PartnerCodeAndCountry partner={partner} /> */}
              <PartnerLogoSquare
                partner={partner}
                getPartnerDetailsAPI={getPartnerDetailsAPI}
              />
              <PartnerTagsDetails
                partner={partner}
                getPartnerDetailsAPI={getPartnerDetailsAPI}
              />
            </div>
            <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
              <PartnerGeneralDetails
                partner={partner}
                getPartnerDetailsAPI={getPartnerDetailsAPI}
              />
              {/* <PartnerFinanceDetails
                partner={partner}
                getPartnerDetailsAPI={getPartnerDetailsAPI}
              /> */}
              {/* <PartnerContactTypes
                partner={partner}
                getPartnerDetailsAPI={getPartnerDetailsAPI}
              /> */}
              <PartnerNotes
                partner={partner}
                getPartnerDetailsAPI={getPartnerDetailsAPI}
              />
            </div>
          </div>
          <div className="d-flex flex-column gap-7 gap-lg-10 mt-10"></div>
        </div>
      ) : (
        <div className="d-flex flex-column flex-lg-row">
          <div className="d-flex flex-column flex-row-fluid">
            <PartnerContacts
              partner={partner}
              getPartnerDetailsAPI={getPartnerDetailsAPI}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default EditPartnerDetails;
