import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

// custom

import { updateCustomerPersonalDetails } from "../redux";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import { CustomerType, UserType } from "../../auth/types/LoginResponseTypes";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";

interface Props {
  userDetails: UserType;
  customerDetails: CustomerType;
  getCustomerDetailsAPI: (callback?: (customer: CustomerType) => void) => void;
}

const UserProfileDetails: FC<Props> = ({
  userDetails,
  customerDetails,
  getCustomerDetailsAPI,
}) => {
  const intl = useIntl();

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, 'contacts'))

  // state
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<UserType>(userDetails);

  const SALUTATION_TYPES = JSON.parse(
    intl.formatMessage({
      id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_SALUTATION_TYPES",
    })
  ) as any[];

  const TITLE_TYPES = JSON.parse(
    intl.formatMessage({ id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_TITLE_TYPES" })
  ) as any[];

  useEffect(() => {
    setUser(userDetails);
  }, [userDetails]);

  const initialValues = {
    salutation: user?.salutation_id || 1,
    title: user?.title_id || 0,
    firstname: user?.firstname || "",
    lastname: user?.lastname || "",
    username: user?.username || "",
    email: user?.email || "",
    telephone: user?.telephone || "",
  };

  const EditCustomerProfileSchema = Yup.object().shape({
    salutation: Yup.string().required(
      intl.formatMessage({
        id: "CUSTOMER_PROFILE_SALUATION_VALIDATION_MESSAGE",
      })
    ),
    firstname: Yup.string().required(
      intl.formatMessage({
        id: "CUSTOMER_PROFILE_FIRSTNAME_VALIDATION_MESSAGE",
      })
    ),
    lastname: Yup.string().required(
      intl.formatMessage({ id: "CUSTOMER_PROFILE_LASTNAME_VALIDATION_MESSAGE" })
    ),
    email: Yup.string().email(intl.formatMessage({ id: "WRONG_EMAIL_FORMAT" })),

    // Optional country code starting with +, followed by 1 to 4 digits.
    // Optionally includes parentheses, spaces, dashes, and dots as separators.
    // Allows for phone numbers with 1 to 15 digits.
    telephone: Yup.string().matches(
      /^(\+?(\d{1,4}))?([-. \(\)]*\d{1,5})?([-. \(\)]*\d{1,5})?([-. \(\)]*\d{1,15})$/,
      intl.formatMessage({
        id: "CUSTOMER_PROFILE_TELEPHONE_VALIDATION_MESSAGE",
      })
    ),
    // username: Yup.string()
    //   .matches(
    //     /^[a-zA-Z0-9]*$/,
    //     intl.formatMessage({ id: "USERS_USERNAME_FIELD_ERROR" })
    //   )
    //   .required(
    //     intl.formatMessage({ id: "CUSTOMER_MANAGEMENT_USERNAME_REQUIRED" })
    //   ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: EditCustomerProfileSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      updateCustomerPersonalDetails(
        user.id,
        values.salutation,
        values.title,
        values.firstname,
        values.lastname,
        values.email,
        values.telephone
        // values.username
      )
        .then(() => {
          SuccessAlert(
            intl.formatMessage({
              id: "CUSTOMER_MANAGEMENT_PROFILE_ADD_SUCCESS_MESSAGE",
            }),
            () => {},
            intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
          );
        })
        .catch(() => {
          errorAlert(
            intl.formatMessage({
              id: "CUSTOMER_MANAGEMENT_PROFILE_ADD_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          setLoading(false);
          getCustomerDetailsAPI();
        });
    },
  });

  return (
    <div className="card card-flush border-0">
      <div className="card-header px-0">
        <div className="card-title">
          <h2>
            {intl.formatMessage({
              id: "CUSTOMER_MANAGEMENT_PROFILE_DETAILS_TITLE",
            })}
          </h2>
        </div>
      </div>

      <div className="card-body pt-3 px-0">
        {/* salutation */}
        <div className="mb-8">
          <label className="form-label fw-bold fs-6">
            <span className="required">
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_PROFILE_SALUTATION",
              })}
            </span>
          </label>

          <div className="fv-row">
            <DropDown
              id="customer_saluation"
              hideSearch
              items={SALUTATION_TYPES}
              displayFunc={(item) => {
                return item.name;
              }}
              displayValue={
                SALUTATION_TYPES.find(
                  (item) => item.id === formik.values.salutation
                )?.name || ""
              }
              onSelectItem={(item) => {
                //@ts-ignore
                formik.setFieldValue("salutation", item.id);
              }}
              selectedItem={SALUTATION_TYPES.find(
                (item) => item.id === formik.values.salutation
              )}
            />
            {/* <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                onChange={(e) => {
                  formik.setFieldValue(
                    'salutation',
                    e.target.value ? parseInt(e.target.value) : e.target.value,
                    true
                  )
                }}
                value={formik.getFieldProps('salutation').value}
              >
                <option value={1}>
                  {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_PROFILE_SALUTATION_MR'})}
                </option>
                <option value={2}>
                  {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_PROFILE_SALUTATION_MRS'})}
                </option>
              </select> */}
            {formik.touched.salutation && formik.errors.salutation && (
              <div className="text-danger">
                <span role="alert">{formik.errors.salutation}</span>
              </div>
            )}
          </div>
        </div>

        {/* title */}
        <div className="mb-8">
          <label className="form-label">
            <span className="">
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_PROFILE_TITLE",
              })}
            </span>
          </label>
          <DropDown
            id="customer_titles"
            hideSearch
            items={TITLE_TYPES}
            displayFunc={(item) => {
              return item.name;
            }}
            displayValue={
              TITLE_TYPES.find((item) => item.id === formik.values.title)
                ?.name || ""
            }
            onSelectItem={(item) => {
              //@ts-ignore
              formik.setFieldValue("title", item.id);
            }}
            selectedItem={TITLE_TYPES.find(
              (item) => item.id === formik.values.title
            )}
          />
          {/* <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              onChange={(e) => {
                formik.setFieldValue(
                  'title',
                  e.target.value ? parseInt(e.target.value) : e.target.value,
                  true
                )
              }}
              value={formik.getFieldProps('title').value}
            >
              <option value={0}></option>
              <option value={1}>
                {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_PROFILE_TITLE_DR'})}
              </option>
              <option value={2}>
                {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_PROFILE_TITLE_PROF'})}
              </option>
              <option value={3}>
                {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_PROFILE_TITLE_PROF_DR'})}
              </option>
            </select> */}
          {formik.touched.title && formik.errors.title && (
            <div className="text-danger">
              <span role="alert">{formik.errors.title}</span>
            </div>
          )}
        </div>

        <div className="row">
          {/* firstname */}
          <div className="col-lg-6 mb-8">
            <label className="form-label required">
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_PROFILE_FIRST_NAME",
              })}
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              {...formik.getFieldProps("firstname")}
            />
            {formik.touched.firstname && formik.errors.firstname && (
              <div className="text-danger">
                <span role="alert">{formik.errors.firstname}</span>
              </div>
            )}
          </div>

          {/* lastname */}
          <div className="col-lg-6 mb-8">
            <label className="form-label required">
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_PROFILE_LAST_NAME",
              })}
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              {...formik.getFieldProps("lastname")}
            />
            {formik.touched.lastname && formik.errors.lastname && (
              <div className="text-danger">
                <span role="alert">{formik.errors.lastname}</span>
              </div>
            )}
          </div>
        </div>

        {/* email */}
        <div className="mb-8">
          <label className="form-label">
            {intl.formatMessage({
              id: "CUSTOMER_MANAGEMENT_PROFILE_EMAIL",
            })}
          </label>
          <input
            type="email"
            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="text-danger">
              <span role="alert">{formik.errors.email}</span>
            </div>
          )}
        </div>

        {/* telephone */}
        <div className="mb-8">
          <label className="form-label">
            {intl.formatMessage({
              id: "CUSTOMER_MANAGEMENT_PROFILE_PHONE",
            })}
          </label>
          <input
            type="tel"
            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            {...formik.getFieldProps("telephone")}
          />
          {formik.touched.telephone && formik.errors.telephone && (
            <div className="text-danger">
              <span role="alert">{formik.errors.telephone}</span>
            </div>
          )}
        </div>

        {/* submit button */}
        <div className="d-flex justify-content-end mt-5">
          {(crudPermission?.edit || crudPermission?.create )&& <button
            className="btn btn-primary min-w-100px"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {!loading &&
              intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_PROFILE_SAVE_BTN",
              })}
            {loading && (
              <span className="d-block indicator-progress">
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_PROFILE_LOADING_MESSAGE",
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>}
        </div>
      </div>
    </div>
  );
};

export default UserProfileDetails;
