import React, { FC, useState } from "react";
import { Modal, Button } from "react-bootstrap";
// import * as Yup from 'yup'
// import {Formik, FormikHelpers, useFormik} from 'formik'
// import clsx from 'clsx'

// images
// import EditIcon from '../../../../_metronic/assets/icons/edit.svg'
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
// import SearchIcon from '../../../../_metronic/assets/icons/search.svg'
// import PlusIcon from '../../../../_metronic/assets/icons/plus.svg'

// custom
// import Constants from '../../../config/Constants'
// import DropDown from '../../../sharedComponents/dropdown/Dropdown'
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
// import {useDispatch} from 'react-redux'
// import {useHistory} from 'react-router'
import { useIntl } from "react-intl";
import { deleteIndustrySector } from "../redux/PartnersMasterdataAPI";
import {
  IndustryGroupType,
  IndustrySectorType,
} from "../types/getIndustryGroupsResponseType";
import { SVGICON } from "../../../../_metronic/helpers";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import AddNewIndustryGroupModal from "./AddNewIndustryGroupModal";

interface ModalProps {
  show: boolean;
  industryGroup: IndustryGroupType;
}

// interface IndustrySectorModalProps {
//   show: boolean
//   industryGroup: IndustryGroupType | undefined
// }

interface IndustryGroupModalProps {
  show: boolean;
  type: "edit" | "new";
  industryGroup: IndustryGroupType | undefined;
}

interface Props {
  modalProps: ModalProps;
  closeModal: () => void;
  getIndustryGroupsAPI: () => void;
}

const DisplayIndustryGroupModal: FC<Props> = ({
  modalProps,
  closeModal,
  getIndustryGroupsAPI,
}) => {
  const intl = useIntl();

  const { show, industryGroup } = modalProps;

  //   state
  // const [industryGroups, setIndustryGroups] = useState<IndustryGroupType[]>([])
  // const [displayIndustryGroups, setDisplayIndustryGroups] = useState<IndustryGroupType[]>([])
  // const [searchText, setSearchText] = useState<string>('')
  // const [showIndustrySectorModalProps, setShowIndustrySectorModalProps] =
  //   useState<IndustrySectorModalProps>({show: false, industryGroup: undefined})
  const [showIndustryGroupModalProps, setShowIndustryGroupModalProps] =
    useState<IndustryGroupModalProps>({
      show: false,
      type: "new",
      industryGroup: undefined,
    });

  // context
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  // const deleteSelectedIndustryGroup = (industryGroup: IndustryGroupType) => {
  //   setDeleteModalLoading(true)
  //   deleteIndustryGroup(industryGroup.id)
  //     .then(() => {
  //       closeModal()
  //       SuccessAlert('Group deleted successfully')
  //     })
  //     .catch(() => {
  //       errorAlert('There was an issue deleting this group')
  //     })
  //     .finally(() => {
  //       setDeleteModalLoading(false)
  //       hideDeleteConfirmModal()
  //       getIndustryGroupsAPI()
  //     })
  // }

  const deleteSelectedIndustrySector = (
    industryGroupSector: IndustrySectorType
  ) => {
    setDeleteModalLoading(true);
    deleteIndustrySector(industryGroupSector.id)
      .then(() => {
        closeModal();
        SuccessAlert(
          intl.formatMessage({
            id: "PARTNERS_INDUSTRY_SECTOR_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "PARTNERS_INDUSTRY_SECTOR_DELETE_ERROR_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getIndustryGroupsAPI();
      });
  };

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      backdrop="static"
      onHide={closeModal}
    >
      {showIndustryGroupModalProps.show && (
        <AddNewIndustryGroupModal
          modalProps={showIndustryGroupModalProps}
          closeModal={() => {
            setShowIndustryGroupModalProps({
              show: false,
              type: "new",
              industryGroup: undefined,
            });
            closeModal();
          }}
          getIndustryGroupsAPI={getIndustryGroupsAPI}
        />
      )}
      <Modal.Header closeButton>
          <Modal.Title>
            {intl.formatMessage({ id: "PARTNERS_INDUSTRY_GROUP_MODAL_TITLE" })}
          </Modal.Title>
      </Modal.Header>

      <div className="overflow-auto">
        <Modal.Body className="overflow-inherit">
          <div className="py-4 px-6">
            <div className="d-flex justify-content-between align-items-center">
              <h2>{industryGroup.name}</h2>
              <div></div>
            </div>

            <div>
              {(!industryGroup.sectors ||
                industryGroup.sectors.length === 0) && (
                <div className="fw-bold text-gray-600 mb-5">
                  {intl.formatMessage({
                    id: "PARTNERS_INDUSTRY_GROUP_NO_SECTORS",
                  })}
                </div>
              )}

              <div className="d-flex flex-column text-gray-600">
                {industryGroup.sectors.map((industryGroupSector, i) => {
                  return (
                    <div className="d-flex align-items-center py-3" key={i}>
                      <div
                        role="button"
                        onClick={() => {
                          showDeleteConfirmModal(
                            intl.formatMessage({
                              id: "PARTNERS_INDUSTRY_SECTOR_DELETE_CONFIRMATION_MESSAGE",
                            }),
                            () => {
                              deleteSelectedIndustrySector(industryGroupSector);
                            },
                            intl.formatMessage({ id: "COMMON_YES_DELETE" })
                          );
                        }}
                      >
                        <SVGICON
                          src={DeleteIcon}
                          className="svg-icon-2 svg-icon-danger me-2"
                        />
                      </div>
                      <span className="fs-6 text-gray-700">
                        {industryGroupSector.name}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DisplayIndustryGroupModal;
