import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// custom
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import config from "../../config";
import IndustriesAndSectors from "../partnersMasterData/components/IndustriesAndSectors";
import Categories from "./components/Categories";
import ContactTypes from "./components/ContactTypes";
import ContactTitles from "./components/ContactTitles";
import Groups from "./components/Groups";
import Labels from "./components/Labels";
import Tags from "./components/Tags";
import ProductDetails from "./components/ProductTypes";
import ProductTypes from "./components/ProductTypes";
import SystemMessages from "./components/SystemMessages";
import ScrollToTop from "../../sharedComponents/ScrollToTop/ScrollToTop";

const MasterdataPage: React.FC = () => {
  const intl = useIntl();

  const masterdataBreadCrumbs: Array<PageLink> = [
    // {
    //   title: intl.formatMessage({ id: "MENU_DASHBOARD" }),
    //   path: "/dashboard",
    //   isSeparator: false,
    //   isActive: false,
    // },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
    {
      title: intl.formatMessage({ id: "MENU_MASTERDATA" }),
      path: "/master-data",
      isSeparator: false,
      isActive: false,
    },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
  ];

  return (
    <div>
      <Helmet>
        <title>
          {config.APP_NAME}-{intl.formatMessage({ id: "MENU_MASTERDATA" })}
        </title>
      </Helmet>
      {/* <AccountHeader /> */}
      <ScrollToTop>
      <Switch>
        <Route path="/master-data/categories">
          <PageTitle breadcrumbs={masterdataBreadCrumbs}>
            {intl.formatMessage({ id: "MASRERDATA_CATEGORIES_TITLE" })}
          </PageTitle>
          <Categories />
        </Route>
        <Route path="/master-data/groups">
          <PageTitle breadcrumbs={masterdataBreadCrumbs}>
            {intl.formatMessage({ id: "MASTERDATA_GROUPS_TTLE" })}
          </PageTitle>
          <Groups />
        </Route>
        <Route path="/master-data/tags">
          <PageTitle breadcrumbs={masterdataBreadCrumbs}>
            {intl.formatMessage({ id: "MASTERDATA_TAGS_TITLE" })}
          </PageTitle>
          <Tags />
        </Route>
        <Route path="/master-data/contact-types">
          <PageTitle breadcrumbs={masterdataBreadCrumbs}>
            {intl.formatMessage({ id: "MASTERDATA_CONTACT_TYPES_TITLE" })}
          </PageTitle>
          <ContactTypes />
        </Route>
        <Route path="/master-data/contact-titles">
          <PageTitle breadcrumbs={masterdataBreadCrumbs}>
            {intl.formatMessage({ id: "MASTERDATA_CONTACT_TITLES_TITLE" })}
          </PageTitle>
          <ContactTitles />
        </Route>
        <Route path="/master-data/industries-sectors">
          <PageTitle breadcrumbs={masterdataBreadCrumbs}>
            {intl.formatMessage({ id: "MASTERDATA_INDUSTRIESSECTORS_TITLE" })}
          </PageTitle>
          <IndustriesAndSectors />
        </Route>
        <Route path="/master-data/labels">
          <PageTitle breadcrumbs={masterdataBreadCrumbs}>
            {intl.formatMessage({ id: "MASTERDATA_ROUTE_LABELS_TITLE" })}
          </PageTitle>
          <Labels />
        </Route>
        <Route path="/master-data/product-types">
          <PageTitle breadcrumbs={masterdataBreadCrumbs}>
            {intl.formatMessage({ id: "MASTERDATA_PRODUCT_TYPES_TITLE" })}
          </PageTitle>
          <ProductTypes />
        </Route>
        <Route path="/master-data/system-messages">
          <PageTitle breadcrumbs={masterdataBreadCrumbs}>
            {intl.formatMessage({ id: "MASTERDATA_SYSTEM_MESSAGES_TITLE" })}
          </PageTitle>
          <SystemMessages />
        </Route>

        <Redirect
          from="/master-data"
          exact={true}
          to="/master-data/categories"
        />
        <Redirect to="/master-data/categories" />
      </Switch>
      </ScrollToTop>
    </div>
  );
};

export default MasterdataPage;
