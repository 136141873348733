import React, { FC, useState } from "react";
import { ProductType } from "../types/ProductsResponseType";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { useFormik } from "formik";
import { updateProductManufacturerDetails } from "../redux/ProductsAPI";
import { SuccessAlert, errorAlert } from "../../../sharedComponents/Alert";
import clsx from "clsx";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";

interface Props {
  product: ProductType;
  getProductDetailsAPI: () => void;
}

const ProductManufactur: FC<Props> = ({ product, getProductDetailsAPI }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const intl = useIntl();

  const initialValues = {
    storage_location: product?.storage_location || "",
    manufacturer_number: product?.manufacturer_number || "",
    ean: product?.ean || "",
    article_number: product?.article_number || "",
  };
  
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, "products"))
  const EditProductSchema = Yup.object().shape({});

  const formik = useFormik({
    initialValues,
    validationSchema: EditProductSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      updateProductManufacturerDetails(
        product.id,
        values.storage_location,
        values.manufacturer_number,
        values.ean,
        values.article_number
      )
        .then(() => {
          SuccessAlert(
            intl.formatMessage({
              id: "PRODUCT_MANUFACTURER_DETAILS_SUCCESS_MESSAGE",
            }),
            () => {},
            intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
          );
        })
        .catch(() => {
          errorAlert(
            intl.formatMessage({
              id: "PRODUCT_MANUFACTURER_DETAILS_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          setLoading(false);
          getProductDetailsAPI();
        });
    },
  });

  return (
    <div className={loading ? "pe-none" : ""}>
      <div className="card card-flush py-4">
        <div className="card-header">
          <div className="card-title">
            <h2>
              {intl.formatMessage({
                id: "PRODUCTS_MANUFACTURER_DETAILS_TTILE",
              })}
            </h2>
          </div>
        </div>
        <div className={clsx("card-body pt-0")}>
          {/* storage location */}
          <div className="mb-8">
            <label className="form-label ">
              {intl.formatMessage({
                id: "PRODUCT_MANUFACTURER_STORAGE_TITLE",
              })}
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              {...formik.getFieldProps("storage_location")}
            />
            {formik.touched.storage_location &&
              formik.errors.storage_location && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.storage_location}</span>
                </div>
              )}
          </div>

          {/* manufacturer number */}
          <div className="mb-8">
            <label className="form-label ">
              {intl.formatMessage({
                id: "PRODUCT_MANUFACTURER_TITLE",
              })}
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              {...formik.getFieldProps("manufacturer_number")}
            />
            {formik.touched.manufacturer_number &&
              formik.errors.manufacturer_number && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.manufacturer_number}</span>
                </div>
              )}
          </div>

          {/* EAN */}
          <div className="mb-8">
            <label className="form-label ">
              {intl.formatMessage({
                id: "PRODUCT_MANUFACTURER_EAN_TITLE",
              })}
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              {...formik.getFieldProps("ean")}
            />
            {formik.touched.ean && formik.errors.ean && (
              <div className="text-danger">
                <span role="alert">{formik.errors.ean}</span>
              </div>
            )}
          </div>

          {/* ARTICLE NUMBER */}
          <div className="mb-8">
            <label className="form-label ">
              {intl.formatMessage({
                id: "PRODUCT_MANUFACTURER_ARTICLE_NUMBER_TITLE",
              })}
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              {...formik.getFieldProps("article_number")}
            />
            {formik.touched.article_number && formik.errors.article_number && (
              <div className="text-danger">
                <span role="alert">{formik.errors.article_number}</span>
              </div>
            )}
          </div>

          {/* submit button */}
          <div className="d-flex justify-content-end mt-5">
           {crudPermission?.edit &&  <button
              className="btn btn-primary min-w-100px"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {!loading &&
                intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_PROFILE_SAVE_BTN",
                })}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "CUSTOMER_MANAGEMENT_PROFILE_LOADING_MESSAGE",
                  })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductManufactur;
