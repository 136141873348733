import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

// custom
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";

import ActionsDropdown from "../../../sharedComponents/ActionsDropdown/ActionsDropdown";
import { DealType } from "../../deals/types/DealResponseType";
import { forceDeleteDeal, restoreDeal } from "../redux/ArchiveAPI";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";

interface Props {
  dealDetails: DealType;
  getArchivedDealsListAPI?: () => void;
}

const ArchivedListActionsDropdown: FC<Props> = ({
  dealDetails,
  getArchivedDealsListAPI,
}) => {
  const intl = useIntl();
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, 'archived-deals'))

  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const onRestoreDealClick = () => {
    restoreDeal(dealDetails.id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "ARCHIVED_RESTORE_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "ARCHIVED_RESTORE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        getArchivedDealsListAPI?.();
      });
  };

  const onDeleteDeleteClick = () => {
    setDeleteModalLoading(true);
    forceDeleteDeal(dealDetails.id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "ARCHIVED_DELETE_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "ARCHIVED_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getArchivedDealsListAPI?.();
      });
  };

  const dropdownOptions = [
   ...(crudPermission?.edit ? [ {
    name: intl.formatMessage({ id: "ARCHIVED_DEAL_RESTORE" }),
    onClick: onRestoreDealClick,
  },]: []),
   ...(crudPermission?.delete ? [ {
    name: intl.formatMessage({ id: "COMMON_DELETE" }),
    onClick: () => {
      showDeleteConfirmModal(
        intl.formatMessage({ id: "ARCHIVED_DELETE_CONFIRM_MESSAGE" }),
        () => {
          onDeleteDeleteClick();
        }
      );
    },
    className: "text-danger",
  },]:[])
  ];

  return (
    <div className="dropdown d-flex flex-row justify-content-end">
      <ActionsDropdown options={dropdownOptions} resource_slug="archived-deals"/>
    </div>
  );
};

export default ArchivedListActionsDropdown;
