import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";

// custom
import SunEditorComponent from "../../../sharedComponents/textEditor/SunEditor";
import { ProductType } from "../types/ProductsResponseType";
import { updateProductAssembleInstructions } from "../redux/ProductsAPI";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";

interface Props {
  product: ProductType;
  getProductDetailsAPI: () => void;
}

const ProductAssembleInstructions: FC<Props> = ({
  product,
  getProductDetailsAPI,
}) => {
  const intl = useIntl();
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, "products"))

  // state
  const [loading, setLoading] = useState<boolean>(false);

  const onNotestextEditorSubmit = (html: string = "") => {
    // const resHtml = html.replaceAll('<p><br/><p>', '')
    if (html) {
      setLoading(true);
      updateProductAssembleInstructions(product.id, html)
        .then(() => {
          SuccessAlert(
            intl.formatMessage({ id: "PRODUCTS_NOTES_UPDATE_SUCCESS" }),
            () => {},
            intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
          );
        })
        .catch(() => {
          errorAlert(
            intl.formatMessage({ id: "PRODUCTS_NOTES_UPDATE_FAILURE" })
          );
        })
        .finally(() => {
          setLoading(false);
          getProductDetailsAPI();
        });
    }
  };

  return (
    <div className="card card-flush">
      <div className="card-header">
        <div className="card-title">
          <h3 className="fw-bolder m-0">
            {intl.formatMessage({ id: "PRODUCTS_NOTES_HEADER" })}
          </h3>
        </div>
      </div>
      <div className="card-body pt-0">
        {/* <Editor
          size={'xsmall'}
          onSubmit={(html) => {
            onNotestextEditorSubmit(html)
          }}
          initHtml={partner.notes || ''}
          loading={loading}
        /> */}
        {/* <RichEditor
          size={'xsmall'}
          onSubmit={(html) => {
            onNotestextEditorSubmit(html)
          }}
          initHtml={partner.notes || ''}
          loading={loading}
          hideSubmit={true}
        /> */}
        <SunEditorComponent
          initHtml={product.assembly_instructions || ""}
          enablePermission = {!crudPermission?.edit}
          onSubmit={(html) => {
            onNotestextEditorSubmit(html);
          }}
          wrapperPosition={0}
          loading={loading}
          hideSubmit={true}
        />
      </div>
    </div>
  );
};

export default ProductAssembleInstructions;
