import clsx from "clsx";
import { Formik, useFormik } from "formik";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
// @ts-ignore
import DragSortableList from "react-drag-sortable";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import Constants from "../../../config/Constants";

// images
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

// custom
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { SVGICON } from "../../../../_metronic/helpers";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import { actions } from "../redux";

import { GroupType } from "../types/GetGroupsListResponseType";
import { sortGroupsParams } from "../types/request";
import {
  createNewProductType,
  deleteProductType,
  getProductTypesList,
  getProductUnits,
  massDeleteProductTypes,
  sortProductTypes,
  updateMasterProductType,
} from "../redux/ProductTypesAPI";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";

interface ModelComponentProps {
  ModalProps: ProductTypeModalProps;
  loading: boolean;
  closeModal: () => void;
  onFormSubmit: (
    type: string,
    productType: GroupType,
    productUnit: any,
    values: FormValuesType,
    setStatus: (msg: any) => void
  ) => void;
}

interface ProductTypeModalProps {
  show: boolean;
  type?: string;
  productType?: GroupType;
  productUnit: [];
}

interface FormValuesType {
  productTypeName?: string | undefined;
  productUnit?: string
}

interface SortedObject {
  productType: GroupType;
}

const ProductTypesModal: FC<ModelComponentProps> = ({
  ModalProps,
  closeModal,
  loading,
  onFormSubmit,
}) => {
  const intl = useIntl();
  const { show, type, productType } = ModalProps;
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, "products-types"))
  const initialValues = {
    productTypeName: type === "edit" ? productType?.name : "",
    // @ts-ignore
    productUnit: type === "edit" ? productType?.unitDetails : ""
  };
  const [units,setUnits] = useState([])

  const AddNewProductTypeSchema = Yup.object().shape({
    productTypeName: Yup.string()
      .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
      .required(
        intl.formatMessage({
          id: "MASTERDATA_CONTACT_TYPE_CONTACT_NAME_REQUIRED",
        })
      ),
    productUnit: Yup.object()
  });

  useEffect(()=>{
    getProductUnits().then((data)=>setUnits(data?.data?.data))
  },[])

  return (
    <Modal
      show={show}
      centered
      contentClassName={loading ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({ id: "MASTERDATA_PRODUCT_TYPE_EDIT_TITLE" })
            : intl.formatMessage({
                id: "MASTERDATA_PRODUCT_TYPE_NEW_TITLE",
              })}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={AddNewProductTypeSchema}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          // @ts-ignore
          onFormSubmit(type, productType, values, setStatus);
        }}
      >
        {({
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          status,
        }) => (
          <>
            <div className="">
              <Modal.Body className={`overflow-inherit  ${(!crudPermission?.edit && type === "edit") && "pe-none"}`}>
                <div className="p-4">
                  {status && (
                    <div className="mb-10 alert alert-danger">
                      <div className="alert-text font-weight-bold">
                        {status}
                      </div>
                    </div>
                  )}
                  <div className="mb-8">
                  <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                    <span className="required">
                      {intl.formatMessage({
                        id: "MASTERDATA_CONTACT_TYPE_CONTACT_NAME_FIELD_NAME",
                      })}
                    </span>
                  </label>
                  <input
                    placeholder={intl.formatMessage({
                      id: "MASTERDATA_PRODUCT_TYPE_PRODUCT_NAME_PLACEHOLDER",
                    })}
                    // {...formik.getFieldProps('productTypeName')}
                    className={clsx(
                      "form-control form-control-lg form-control-solid"
                    )}
                    name="productTypeName"
                    autoComplete="off"
                    onChange={handleChange("productTypeName")}
                    value={values.productTypeName}
                  />
                  {touched.productTypeName && errors.productTypeName && (
                    <div className="text-danger">
                      <span role="alert">{errors.productTypeName}</span>
                    </div>
                  )}
                 </div>
                 <div className=" mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span >
                  {intl.formatMessage({ id: "PRODUCTS_UNITS_DROPDOWN" })}
                </span>
              </label>
              <div className="fv-row">
                <DropDown
                  hideSearch
                  id="units-dropdown"
                  items={units}
                  displayFunc={(item) => {
                    // @ts-ignore
                    return item.name;
                  }}
                  displayValue={
                    units.find(
                      (unit) =>
                        // @ts-ignore
                        unit?.name === values.productUnit?.name
                          // @ts-ignore
                    )?.name || ""
                  }
                  onSelectItem={(item) => {
                    //@ts-ignore
                    setFieldValue("productUnit", item);
                  }}
                  selectedItem={units.find(
                    (unit) =>
                       //@ts-ignore
                      unit.name === values.productUnit
                  )}
                />
              </div>

              </div>
                </div>
              </Modal.Body>
            </div>

            <Modal.Footer className="justify-content-center">
              <Button variant="secondary" onClick={closeModal}>
                {intl.formatMessage({ id: "CLOSE_BUTTON" })}
              </Button>
            {(crudPermission?.edit || crudPermission?.create) &&   <Button
                variant="primary"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({
                      id: "MASTERDATA_CONTACT_TYPE_LOADING_MESSAGE",
                    })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </Button>}
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

const ProductTypes: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const productTypesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.productTypesList
  ) as GroupType[];
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, "products-types"))

  // state
  const [loading, setLoading] = useState(false);
  const [displayProductTypesList, setDisplayProductTypesList] =
    useState(productTypesList);
  const [showProductTypesModal, setShowProductTypesModal] =
    useState<any>({
      show: false,
    });
  const [checkedProductTypes, setCheckedProductTypes] = useState<GroupType[]>(
    []
  );
  const [searchText, setSearchText] = useState<string>("");
  const [checkAllProductTypes, setCheckAllProductTypes] =
    useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );

  const getProductTypesListAPI = () => {
    getProductTypesList()
      .then(({ data: { data } }) => {
        dispatch(actions.setProductTypesList(data));
      })
      .catch((e) => {
        setLoading(false);
        // console.log(`ERROR: getProductTypesListAPI`, e)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updatedisplayProductTypesList = () => {
    const updatedProductTypesList = productTypesList.filter((productType) => {
      if (
        (productType.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayProductTypesList(updatedProductTypesList);
  };

  useEffect(() => {
    setLoading(true);
    // get productTypes API call
    getProductTypesListAPI();
  }, []);

  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  useEffect(() => {
    updatedisplayProductTypesList();

    return () => {
      setDisplayProductTypesList(productTypesList);
    };
  }, [productTypesList, searchText]);

  useEffect(() => {
    if (
      checkAllProductTypes &&
      checkedProductTypes.length !== productTypesList.length
    ) {
      setCheckAllProductTypes(false);
    }
    if (
      checkedProductTypes.length > 0 &&
      checkedProductTypes.length === productTypesList.length
    ) {
      setCheckAllProductTypes(true);
    }
  }, [checkedProductTypes]);

  const closeProductTypesModal = () => {
    setShowProductTypesModal({
      show: false,
      type: "",
      productType: undefined,
      productUnit: [],
    });
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const onFormSubmit = (
    type: string,
    productType: GroupType,
    values: FormValuesType,
    productUnit: any,
    setStatus: (msg: any) => void
  ) => {
    if (type === "new") {
      if (values.productTypeName) {
        setLoading(true);
        // @ts-ignore
        createNewProductType(values.productTypeName,values?.productUnit?.id)
          .then(() => {
            closeProductTypesModal();
            SuccessAlert(
              intl.formatMessage({
                id: "MASTERDATA_PRODUCT_TYPES_CREATE_SUCCESS_MESSAGE",
              }),
              () => {},
              intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
            );
          })
          .catch((err) => {
            err.response?.data?.errors?.name
              ? setStatus(err.response.data?.errors?.name)
              : setStatus(
                  intl.formatMessage({
                    id: "MASTERDATA_PRODUCT_TYPE_CREATE_FAILURE_MESSAGE",
                  })
                );
          })
          // .catch((e) => {
          //   closeProductTypesModal();
          //   errorAlert(
          //     intl.formatMessage({
          //       id: "MASTERDATA_PRODUCT_TYPE_CREATE_FAILURE_MESSAGE",
          //     })
          //   );
          // })
          .finally(() => {
            // recall  get productTypes list API
            getProductTypesListAPI();
            setLoading(false);
          });
      }
    } else {
      if (values.productTypeName) {
        setLoading(true);
         // @ts-ignore
        updateMasterProductType(productType.id, values.productTypeName,values?.productUnit?.id)
          .then(() => {
            closeProductTypesModal();
            SuccessAlert(
              intl.formatMessage({
                id: "MASTERDATA_PRODUCT_TYPE_EDIT_SUCCESS_MESSAGE",
              }),
              () => {},
              intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
            );
          })
          .catch((e) => {
            closeProductTypesModal();
            errorAlert(
              intl.formatMessage({
                id: "MASTERDATA_PRODUCT_TYPE_EDIT_FAILURE_MESSAGE",
              })
            );
          })
          .finally(() => {
            // recall  get productTypes list API
            getProductTypesListAPI();
            setLoading(false);
          });
      }
    }
  };

  const deleteExistingProductType = (id: number) => {
    setDeleteModalLoading(true);
    setLoading(true);
    deleteProductType(id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "MASTERDATA_PRODUCT_TYPE_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "MASTERDATA_PRODUCT_TYPE_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        closeProductTypesModal();
        setCheckAllProductTypes(false);
        setCheckedProductTypes([]);
        // recall  get productTypes list API
        getProductTypesListAPI();
        setLoading(false);
      });
  };

  const onProductTypesSortChange = (sortedList: Array<SortedObject>) => {
    const newSortedProductTypesArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedProductTypesArr.push({
        id: sortedListItem.productType.id,
        newposition: startIndex + i + 1,
      });
    });
    // sort existing productTypes API Call
    sortProductTypes(newSortedProductTypesArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getProductTypesListAPI();
      });
  };

  //
  const onProductTypeCheckChange = (productType: GroupType) => {
    let checkedProductTypesCopy = _.clone(checkedProductTypes);
    // check if already exists in the checked list
    const index = checkedProductTypesCopy.findIndex(
      (checkedProductType) => checkedProductType.id === productType.id
    );
    // if exists remove
    if (index > -1) {
      checkedProductTypesCopy.splice(index, 1);
    }
    // if doesnt exist push to checked productTypes
    else {
      checkedProductTypesCopy.push(productType);
    }
    setCheckedProductTypes(checkedProductTypesCopy);
  };

  const isProductTypeChecked = (productType: GroupType) => {
    const index = checkedProductTypes.findIndex(
      (checkedProductType) => checkedProductType.id === productType.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const deleteMultipleProductTypes = () => {
    setDeleteModalLoading(true);
    const ids = checkedProductTypes.map((checkedProductType) => {
      return checkedProductType.id;
    });
    massDeleteProductTypes(ids)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "MASTERDATA_PRODUCT_TYPE_MASS_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "MASTERDATA_PRODUCT_TYPE_MASS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getProductTypesListAPI();
        setCheckedProductTypes([]);
      });
  };

  const onAllProductTypesCheckChange = () => {
    const updatedCheckAllProductTypes = !checkAllProductTypes;
    setCheckAllProductTypes(updatedCheckAllProductTypes);
    if (updatedCheckAllProductTypes) {
      setCheckedProductTypes(productTypesList);
    } else {
      setCheckedProductTypes([]);
    }
  };

  const ProductTypeItem = (productType: GroupType, index: number) => {
    const {
      showDeleteConfirmModal,
      hideDeleteConfirmModal,
      setDeleteModalLoading,
    } = useGlobalModalContext();
    return (
      <div
        className="card mt-5 p-4 d-flex flex-row align-items-center justify-content-between"
        key={index}
      >
        <div className="d-flex flex-row align-items-center">
          {crudPermission?.delete && <div className="form-check form-check-sm form-check-custom form-check-solid ">
            <input
              onChange={(e) => {
                onProductTypeCheckChange(productType);
              }}
              className="form-check-input widget-9-check"
              type="checkbox"
              checked={isProductTypeChecked(productType)}
            />
          </div>}
          {crudPermission?.edit && <div className="mx-5">
            {/* <button className='btn btn-icon btn-light  btn-sm'> */}
            <SVGICON src={DragIcon} className="svg-icon-2 svg-hover-primary" />
            {/* </button> */}
          </div>}

          <div
            className={`fw-bold mx-5`}
            role="button"
            onClick={() => {
              setShowProductTypesModal({
                show: true,
                type: "edit",
                productType: productType,
              });
            }}
          >
            {productType.name}
          </div>
        </div>

        <div>
          {crudPermission?.edit && <button
            className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
            onClick={() => {
              setShowProductTypesModal({
                show: true,
                type: "edit",
                productType: productType,
              });
            }}
          >
            <SVGICON src={EditIcon} className="svg-icon-3" />
          </button>}
        {crudPermission?.delete &&   <button
            className="btn btn-icon btn-light btn-active-light-primary btn-sm"
            onClick={() => {
              showDeleteConfirmModal(
                intl.formatMessage({
                  id: "MASTERDATA_PRODUCT_TYPE_DELETE_CONFIRM_MESSAGE",
                }),
                () => {
                  deleteExistingProductType(productType.id);
                }
              );
            }}
          >
            <SVGICON src={DeleteIcon} className="svg-icon-3" />
          </button>}
        </div>
      </div>
    );
  };

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedProductTypes =
    displayProductTypesList && displayProductTypesList.length > 10
      ? displayProductTypesList.slice(startIndex, startIndex + itemsPerPage)
      : displayProductTypesList;

  useEffect(() => {
    if (
      paginatedProductTypes.length === 0 &&
      displayProductTypesList.length > 0
    ) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedProductTypes]);

  // draggable productTypes list
  const list = paginatedProductTypes
    ? paginatedProductTypes.map((productType, i) => {
        return {
          content: (
            <div className={(searchText || !crudPermission?.edit) ? "no-drag" : ""}>
              {ProductTypeItem(productType, i)}
            </div>
          ),
          productType: productType,
        };
      })
    : [];

  return (
    <div>
      <ProductTypesModal
        ModalProps={showProductTypesModal}
        closeModal={closeProductTypesModal}
        loading={loading}
        onFormSubmit={onFormSubmit}
      />

      {/* search productTypes */}
      <div className="card p-4 d-flex flex-row justify-content-between mt-3">
        <div className="d-flex flex-row search-container mt-2">
          {crudPermission?.delete && <div className="form-check form-check-sm form-check-custom form-check-solid ">
            <input
              onChange={onAllProductTypesCheckChange}
              className="form-check-input widget-9-check"
              type="checkbox"
              checked={checkAllProductTypes}
            />
          </div>}
          <div className="d-flex align-items-center">
            <SVGICON
              src={SearchIcon}
              className="svg-icon svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              className="form-control form-control-solid w-250px ps-15 mx-4"
              placeholder={intl.formatMessage({
                id: "MASTERDATA_CONTACT_TYPE_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
            />
          </div>
        </div>

        {/* add new productType button */}
        <div className="d-flex align-items-center mt-2">
          {checkedProductTypes.length > 0 ? (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedProductTypes.length}{" "}
                {intl.formatMessage({ id: "SELECTED" })}
              </span>
              <button
                className="btn btn-danger d-flex align-items-center"
                onClick={() => {
                  showDeleteConfirmModal(
                    intl.formatMessage({
                      id: "MASTERDATA_PRODUCT_TYPE_DELETE_CONFIRM_MESSAGE",
                    }),
                    () => {
                      deleteMultipleProductTypes();
                    }
                  );
                }}
              >
                <i className={"bi bi-trash3-fill fs-6"}></i>
                {intl.formatMessage({ id: "DELETE_BUTTON" })}
              </button>
            </>
          ) : (
            <>
            {crudPermission?.create && <button
              className="btn  btn-primary"
              onClick={() => {
                setShowProductTypesModal({
                  show: true,
                  type: "new",
                });
              }}
            >
              <SVGICON src={PlusIcon} className="svg-icon-2" />
              {intl.formatMessage({
                id: "MASTERDATA_PRODUCT_TYPE_CREATE_NEW_BUTTON",
              })}
            </button>}
            </>
          )}
        </div>
      </div>

      <DragSortableList
        items={list}
        // @ts-ignore
        onSort={(sortedList, dropEvent) => {
          onProductTypesSortChange(sortedList);
        }}
        type="vertical"
      />
      {/* no data */}
      {!loading && displayProductTypesList.length === 0 && (
        <table className="d-flex justify-content-center align-items-center mt-2">
          <tbody>
            <tr>
              <td valign="top" colSpan={7} className="dataTables_empty">
                <div className="d-flex flex-column flex-center">
                  <img
                    src={notFound}
                    className="mh-400px"
                    alt=""
                    style={{ color: "inherit" }}
                  />
                  <div className="fs-1 fw-bolder text-dark mb-4">
                    {intl.formatMessage({ id: "NO_ITEMS_FOUND" })}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {displayProductTypesList.length > 0 && (
        <div className="card mt-5 pe-3">
          <Pagination
            totalPages={Math.ceil(
              displayProductTypesList.length / itemsPerPage
            )}
            activePage={
              Math.ceil(displayProductTypesList.length / itemsPerPage) === 1
                ? 1
                : activePage
            }
            onPageClick={onPageClick}
            noCard
            itemsPerPage={itemsPerPage}
            showItemsPerPage
            setItemsPerPage={(count) => {
              setItemsPerPage(count);
              setActivePage(1);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProductTypes;
