import React, { useEffect } from "react";
import { useLocation } from "react-router";
import clsx from "clsx";
import { useLayout } from "../core";
import { DrawerComponent } from "../../assets/ts/components";
import { DefaultTitle } from "./header/page-title/DefaultTitle";

const Content: React.FC = ({ children }) => {
  const { classes } = useLayout();
  const location = useLocation();
  useEffect(() => {
    DrawerComponent.hideAll();
    const tooltipElement = document.querySelector(".gantt_tooltip");
    if (tooltipElement) {
      //@ts-ignore
      tooltipElement.parentNode.removeChild(tooltipElement);
    }
  }, [location]);

  return (
    <>
      <div
        className="toolbar mx-5  customToolbar1 rounded"
        id="kt_toolbar"
        style={{
          background: "unset",
          // opacity:'revert-layer'
          // borderTopLeftRadius: 9,
          // borderTopRightRadius: 9,
          height: '85px',
          marginTop: "15px",
          backgroundColor: "#F5F5F5",
        }}
        // style={location.pathname?.includes("device") ? { top: "115px" } : {}}
      >
        {/* begin::Container */}
        <div
          id="kt_toolbar_container"
          className={clsx(
            classes.toolbarContainer.join(" "),

            "d-flex flex-column justify-content-start mt-2 py-2"
          )}
        >
          <DefaultTitle />

          {/* begin::Actions */}

          {/* end::Actions */}
        </div>
        {/* end::Container */}
      </div>
      <div
        id="kt_content_container"
        style={
          location.pathname?.includes("device")
            ? { position: "relative", marginTop: "60px" }
            : { position: "relative" }
        }
        className={`${clsx(classes.contentContainer.join(" "))} mt-5 minWidth100 p-0`}
      >
        {children}
      </div>
    </>
  );
};

export { Content };
