import React, { FC, useEffect, useState, useCallback, useMemo } from "react";
import "./Notes.scss";
import Constants from "../../config/Constants";
import AddNotesForm from "./components/AddNotesForm";
import EditNoteModal from "./components/EditNotesModal";
import Loader from "./components/Loader";
import Pagination from "../../sharedComponents/pagination/Pagination";
import NoteCard from "./components/NoteCard";
import { getNotes } from "./redux";
import {
  NoteModalProps,
  UserNotesType,
  DealNotesType,
  NoteDetailsType,
} from "./types/getNotesResponseTypes";

interface Props {
  users_id?: number | null;
  deals_id?: number | null;
}

const Notes: FC<Props> = ({ users_id, deals_id }) => {
  const [notes, setNotes] = useState<UserNotesType | DealNotesType | null>(
    null
  );
  const [showNoteModal, setShowNoteModal] = useState<NoteModalProps>({
    show: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );

  // Fetch notes list with memoization to avoid unnecessary re-creation
  const getNotesListAPI = useCallback(() => {
    setLoading(true);
    getNotes(users_id, deals_id)
      .then(({ data }) => {
        setNotes(data?.data);
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [users_id, deals_id]);

  // Close Note Modal
  const closeNoteModal = useCallback(() => {
    setShowNoteModal((prevState) => ({
      ...prevState,
      show: false,
      note: undefined,
    }));
  }, []);

  useEffect(() => {
    getNotesListAPI();
  }, [getNotesListAPI]);

  // Memoized filtered notes
  const displayNotes = useMemo(() => {
    const notesList = notes?.notes_details ?? [];
    // @ts-ignore
    const userNotes = notes?.user_notes?.notes_details ?? [];

    const mergedNotes = [...notesList, ...userNotes].sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

    let pinnedNotes: NoteDetailsType[] = [];
    let unpinnedNotes: NoteDetailsType[] = [];
    if (users_id && userNotes.length === 0) {
      // for contacts edit page
      pinnedNotes = mergedNotes.filter((note) => note.users_id && note.is_pinned);
      unpinnedNotes = mergedNotes.filter((note) => note.users_id && !note.is_pinned);
    } else {
      // for deals edit page
      pinnedNotes = mergedNotes.filter((note) => note.deals_id && note.is_pinned);
      unpinnedNotes = mergedNotes.filter((note) => (note.users_id && note.is_pinned) || !note.is_pinned);
    }

    return [...pinnedNotes, ...unpinnedNotes];
  }, [notes]);

  // Memoized pagination logic
  const paginatedNotes = useMemo(() => {
    const startIndex = itemsPerPage * (activePage - 1);
    return displayNotes.slice(startIndex, startIndex + itemsPerPage);
  }, [displayNotes, activePage, itemsPerPage]);

  const totalPages = Math.ceil(displayNotes.length / itemsPerPage);

  useEffect(() => {
    if (paginatedNotes.length === 0 && displayNotes.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedNotes]);

  return (
    <div>
      {showNoteModal.show && (
        <EditNoteModal
          ModalProps={showNoteModal}
          closeModal={closeNoteModal}
          getNotesListAPI={getNotesListAPI}
        />
      )}

      <AddNotesForm
        users_id={users_id}
        deals_id={deals_id}
        getNotesListAPI={getNotesListAPI}
      />

      {paginatedNotes.length > 0 && (
        <>
          <hr className="border-dark mb-8" />
          <div className="timeline customer-notes">
            {paginatedNotes.map((note) => (
              <NoteCard
                module={deals_id ? "deals" : users_id ? "contacts" : ""}
                key={note.notes_id}
                note={note}
                onEdit={(note) => setShowNoteModal(note)}
                getNotesListAPI={getNotesListAPI}
              />
            ))}
          </div>
        </>
      )}

      {loading && paginatedNotes.length === 0 && <Loader />}

      {displayNotes.length > 0 && (
        <div className="mt-5 pe-3">
          <Pagination
            totalPages={totalPages}
            activePage={activePage}
            onPageClick={setActivePage}
            noCard
            itemsPerPage={itemsPerPage}
            showItemsPerPage
            setItemsPerPage={(count) => {
              setItemsPerPage(count);
              setActivePage(1); // Reset to first page
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Notes;
