// import _ from "lodash";
import { Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

// custom

import { FallbackView } from "../../_metronic/partials";
import AccountPage from "../modules/accounts/AccountPage";

import UserManagement from "../modules/userManagement/UserManagement";
import PartnerManagement from "../modules/partnerManagement/PartnerManagement";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import FileManagerPage from "../modules/filemanager/FilemanagerPage";
import RolesAndPermissionsPage from "../modules/RolesAndPermissions/RolesAndPermissionsPage";
import {
  getResourcesList,
  getRoleDetails,
  getRolesList,
  actions as rolesAndPermissionsActions,
} from "../modules/RolesAndPermissions/redux";
import { useDispatch } from "react-redux";
import { getGroupsList } from "../modules/masterdata/redux/GroupsAPI";
import { getCategoriesList } from "../modules/masterdata/redux/CategoriesAPI";
import { getTagsList } from "../modules/masterdata/redux/TagsAPI";
import { getIndustryGroups } from "../modules/partnersMasterData/redux/PartnersMasterdataAPI";
import { getLabelsList } from "../modules/masterdata/redux/LabelsAPI";
import { actions as masterdataActions } from "../modules/masterdata/redux";
import { actions as projectmasterDataActions } from "../modules/projectMasterData/redux";
import MasterdataPage from "../modules/masterdata/MasterdataPage";
import CustomerManagement from "../modules/customerManagement/CustomerManagement";
import TeamsPage from "../modules/teams/TeamsPage";
import ProductsPage from "../modules/products/ProductsPage";
import { getProductTypesList } from "../modules/masterdata/redux/ProductTypesAPI";
import ProjectsMasterData from "../modules/projectMasterData/ContentMasterPage";
import PipelinesPage from "../modules/pipelines/PipelinesPage";
import { getFieldsList } from "../modules/projectMasterData/redux/FieldsAPI";
import { getTabsList } from "../modules/projectMasterData/redux/TabsAPI";
import { getPipelinesList } from "../modules/pipelines/redux/PipelinesAPI";
import { actions as PipelineAction } from "../modules/pipelines/redux/PipelinesRedux";
import DealsPage from "../modules/deals/DealsPage";
import { useSelector } from "react-redux";
import { RootState } from "../../setup";
import { UserType } from "../modules/userManagement/types/getUsersListResponseType";
import { actions } from "../modules/auth";
import { getDealPipelines } from "../modules/deals/redux/DealsAPI";
import { actions as DealActions } from "../modules/deals/redux/DealsRedux";
import ArchivedPage from "../modules/archived/ArchivedPage";
import GooglePage from "../modules/google/GooglePage";
import CalendarPage from "../modules/calendar/CalendarPage";
import TasksPage from "../modules/tasks/TasksPage";

export function PrivateRoutes() {
  const user = useSelector<RootState>(({ auth }) => auth.user) as UserType;
  const dispatch = useDispatch();
  useEffect(() => {
    getPipelinesList()
      .then(({ data: { pipelines } }) => {
        dispatch(PipelineAction.setPipelinesList(pipelines));
      })
      .catch((error) => console.log(error?.response?.data?.message));
    getTabsList()
      .then(({ data: { data } }) => {
        dispatch(projectmasterDataActions.setTabsList(data));
      })
      .catch((error) => console.log(error?.response?.data?.message));
    getProductTypesList()
      .then(({ data: { data } }) => {
        dispatch(masterdataActions.setProductTypesList(data));
      })
      .catch((error) => console.log(error?.response?.data?.message));
    getGroupsList()
      .then(({ data: { data } }) => {
        dispatch(masterdataActions.setGroupsList(data));
      })
      .catch((error) => console.log(error?.response?.data?.message));
    getCategoriesList()
      .then(({ data: { data } }) => {
        dispatch(masterdataActions.setCategoriesList(data));
      })
      .catch((error) => console.log(error?.response?.data?.message));
    getTagsList()
      .then(({ data: { data } }) => {
        dispatch(masterdataActions.setTagsList(data));
      })
      .catch((error) => console.log(error?.response?.data?.message));
    getIndustryGroups()
      .then(({ data: { data = [] } }) => {
        dispatch(masterdataActions.setIndustryGroupsList(data));
      })
      .catch((error) => console.log(error?.response?.data?.message));
    getLabelsList()
      .then(({ data: { data = [] } }) => {
        dispatch(masterdataActions.setLabelsList(data));
      })
      .catch((error) => console.log(error?.response?.data?.message));
    getFieldsList()
      .then(({ data: { fields } }) => {
        dispatch(projectmasterDataActions.setFieldssList(fields));
      })
      .catch((error) => console.log(error?.response?.data?.message));
    getRoleDetails(user?.role.id)
      .then(({ data: { rolesDetails } }) => {
        dispatch(actions.setRoleDetails(rolesDetails));
      })
      .catch((error) => console.log(error?.response?.data?.message));
    getDealPipelines()
      .then(({ data: { pipelines } }) => {
        dispatch(DealActions.setDealPipelines(pipelines));
      })
      .catch((error) => console.log(error?.response?.data?.message));
    getRolesList()
      .then(({ data: { rolesDetails = [] } }) => {
        dispatch(rolesAndPermissionsActions.setRolesList(rolesDetails));
      })
      .catch((error) => console.log(error?.response?.data?.message));
    getResourcesList();
  }, []);

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/dashboard" component={DashboardWrapper} />
        <Route path="/account" component={AccountPage} />
        <Route path="/system/user" component={UserManagement} />
        <Route path="/crm/organisation" component={PartnerManagement} />
        <Route path="/crm/contacts" component={CustomerManagement} />
        <Route path="/file-manager" component={FileManagerPage} />
        <Route
          path="/system/roles-permissions"
          component={RolesAndPermissionsPage}
        />
        <Route path="/system/teams" component={TeamsPage} />
        <Route path="/master-data" component={MasterdataPage} />
        <Route path="/projects/master-data" component={ProjectsMasterData} />
        <Route path="/projects/pipelines" component={PipelinesPage} />
        <Route path="/sales/products" component={ProductsPage} />
        <Route path="/content/deals" component={DealsPage} />
        <Route path="/deals/archived" component={ArchivedPage} />
        <Route path="/interfaces/google" component={GooglePage} />
        <Route path="/calendar" component={CalendarPage} />
        <Route path="/tasks" component={TasksPage} />
        <Redirect from="/" to="/dashboard" />
        <Redirect to="/error/404" />
      </Switch>
    </Suspense>
  );
}
