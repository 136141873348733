import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// images
import BlankAvatar from "../../../../_metronic/assets/icons/blankAvatar.svg";
import CloseIcon from "../../../../_metronic/assets/icons/close.svg";
import FilterIcon from "../../../../_metronic/assets/icons/filter.svg";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

// custom
import clsx from "clsx";
import { useIntl } from "react-intl";
import { RootState } from "../../../../setup";
import { SVGICON } from "../../../../_metronic/helpers";
import Constants from "../../../config/Constants";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import Checkbox from "../../../sharedComponents/Checkbox/Checkbox";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import { useAddress } from "../../../utils/getAddress";
import {
  PaginationType,
  PartnersFilterType,
  PartnerType,
} from "../types/getPartnersListResponseType";
import { actions, getPartners, massDeletePartners } from "../redux";
import AddPartnerModal from "./AddPartnerModal";
import PartnersListFilterModal from "./PartnersListFilterModal";
import PartnersListActionsDropdown from "./PartnersListActionsDropdown";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";

interface Props {
  type?: string;
  onSelectPartner?: (partners: PartnerType[]) => void;
  loading?: boolean;
  isSelectionModal?: boolean;
  hideMainModal?: (val: boolean) => void;
}

const PartnersList: FC<Props> = ({
  type,
  onSelectPartner,
  loading,
  isSelectionModal,
  hideMainModal,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const getAddress = useAddress();

  // context
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  // selectors
  const filters = useSelector<RootState>(
    ({ partners }) => partners.filters
  ) as PartnersFilterType;

  // state
  const [partnersList, setPartnersList] = useState<PartnerType[]>([]);
  const [checkedPartners, setCheckedPartners] = useState<PartnerType[]>([]);
  const [checkAllItems, setCheckAllItems] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [getPartnersAPICompleted, setGetPartnersAPICompleted] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [showAddPartnerModal, setShowAddPartnerModal] =
    useState<boolean>(false);
  const [showPartnersFilterModal, setShowshowPartnersFilterModal] =
    useState<boolean>(false);
  const [paginationDetails, setPaginationDetails] = useState<PaginationType>();
  const [tempSearchText, setTempSearchText] = useState<string>("");
  const [APIloading, setAPILoading] = useState(false);
  const [newOrganisationCreated, setNewOrganisationCreated] = useState<any>([]);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;

  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "partners"
  );

  // data API
  const getPartnersListAPI = (page?: number) => {
    // fallback to  the current page
    if (page && activePage !== page) {
      setActivePage(page);
    }
    // reset checked items
    setCheckAllItems(false);

    setGetPartnersAPICompleted(false);
    setAPILoading(true);
    const labels =
      filters.labels && filters.labels.length > 0
        ? filters.labels.map((label) => {
            return label.id;
          })
        : null;
    const sectors =
      filters.sectors && filters.sectors.length > 0
        ? filters.sectors.map((sector) => {
            return sector.id;
          })
        : null;
    getPartners(
      filters.country?.id || null,
      filters.city,
      filters.zip_code,
      itemsPerPage,
      searchText,
      page ? page : activePage
    )
      .then(({ data: { partnerDetails = [], partners_pagination } }) => {
        setPartnersList(partnerDetails);
        setPaginationDetails(partners_pagination);
      })
      .finally(() => {
        setAPILoading(false);
        setGetPartnersAPICompleted(true);
        if (isSelectPartnerType()) {
          hideMainModal?.(false);
        }
      });
  };

  // effects
  useEffect(() => {
    // set initial page to 1
    getPartnersListAPI(1);

    return () => {
      setPartnersList([]);
    };
  }, [searchText, filters, itemsPerPage]);

  useEffect(() => {
    // update checked items
    checkedPartners.length === 0 && setCheckAllItems(false);
    checkedPartners.length !== 0 &&
      checkedPartners.length === partnersList.length &&
      setCheckAllItems(true);
  }, [checkedPartners]);

  const onPageClick = (page: number) => {
    setActivePage(page);
    getPartnersListAPI(page);
  };

  const onSearchTextChange = (text: string) => {
    setTempSearchText(text || "");
  };

  const onAllItemsCheckChange = () => {
    const updatedCheckAllItems = !checkAllItems;
    setCheckAllItems(updatedCheckAllItems);
    if (updatedCheckAllItems) {
      setCheckedPartners(partnersList);
    } else {
      setCheckedPartners([]);
    }
  };

  const onCheckedChange = (partner: PartnerType) => {
    setNewOrganisationCreated([]);
    if (isSelectPartnerType()) {
      setCheckedPartners([partner]);
    } else {
      let checkedPartnersCopy = _.clone(checkedPartners);
      const index = checkedPartnersCopy.findIndex(
        (checkedpartner) => checkedpartner.id === partner.id
      );
      if (index > -1) {
        checkedPartnersCopy.splice(index, 1);
        setCheckAllItems(false);
      } else {
        checkedPartnersCopy.push(partner);
      }
      setCheckedPartners(checkedPartnersCopy);
    }
  };

  const isChecked = (partner: PartnerType) => {
    const index = checkedPartners.findIndex(
      (checkedPartner) => checkedPartner.id === partner.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const deleteSelectedPartners = () => {
    const partners_id = checkedPartners.map((checkedPartner) => {
      return checkedPartner.id;
    });
    setDeleteModalLoading(true);
    massDeletePartners(partners_id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "ORGANISATIONS_MASS_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
        setCheckedPartners([]);
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "ORGANISATIONS_MASS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getPartnersListAPI(1);
      });
  };

  const isSelectPartnerType = () => {
    return type === "select";
  };

  const hasFilters = (filters: PartnersFilterType) => {
    if (
      (filters.labels && filters.labels.length > 0) ||
      (filters.sectors && filters.sectors.length > 0) ||
      filters.country ||
      filters.city ||
      filters.zip_code
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (tempSearchText.length === 0) {
      setTempSearchText("");
      setSearchText("");
    }
  }, [tempSearchText]);

  const handleCheckPartners = (partner: PartnerType) => {
    if (partner) {
      setCheckedPartners([partner]);
      setNewOrganisationCreated(partner);
    }
  };

  return (
    <div className="borderNone card mt-3">
      {showAddPartnerModal && (
        <AddPartnerModal
          show={showAddPartnerModal}
          closeModal={() => {
            setShowAddPartnerModal(false);
            if (isSelectPartnerType()) {
              hideMainModal?.(false);
            }
          }}
          getPartnersListAPI={getPartnersListAPI}
          isSelectPartnerType={isSelectPartnerType()}
          onChange={(partner: PartnerType) => handleCheckPartners(partner)}
        />
      )}

      {showPartnersFilterModal && (
        <PartnersListFilterModal
          show={showPartnersFilterModal}
          closeModal={() => {
            setShowshowPartnersFilterModal(false);
            if (isSelectPartnerType()) {
              hideMainModal?.(false);
            }
          }}
          getPartnersListAPI={getPartnersListAPI}
        />
      )}

      {/* begin::Header */}
      <div
        className={`card-header border-0 pt-8 d-flex flex-row justify-content-between mb-2 ${
          isSelectionModal ? "pb-8" : ""
        }`}
      >
        <div className="d-flex align-items-center" style={{ borderWidth: 1 }}>
          <div className="position-relative">
            {tempSearchText && (
              <div
                className="position-absolute"
                style={{
                  right: 8,
                  top: 10,
                  zIndex: 99,
                }}
                role={"button"}
                onClick={() => {
                  setTempSearchText("");
                  setSearchText("");
                }}
              >
                <SVGICON src={CloseIcon} className="svg-icon svg-icon-1 " />
              </div>
            )}
            <input
              type="text"
              className="form-control form-control-solid w-250px position-relative"
              placeholder={intl.formatMessage({
                id: "PARTNERS_LIST_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                paddingRight: "30px",
              }}
              value={tempSearchText}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (tempSearchText && tempSearchText.length >= 3) {
                    setSearchText(tempSearchText);
                  }
                }
              }}
            />
            {tempSearchText &&
              tempSearchText.length < 3 &&
              isSelectionModal && (
                <div
                  className="text-danger position-absolute"
                  style={{ top: "50px", width: "max-content" }}
                >
                  <div role="alert">
                    {intl.formatMessage({
                      id: "PARTNERS_LIST_SEARCH_VALIDATION_MESSAGE",
                    })}
                  </div>
                </div>
              )}
          </div>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {
              if (tempSearchText && tempSearchText.length >= 3) {
                setSearchText(tempSearchText);
              }
            }}
            style={{
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              padding: "11px",
            }}
          >
            <SVGICON src={SearchIcon} className="svg-icon svg-icon-2" />
          </button>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          {hasFilters(filters) && (
            <>
              {Object.keys(filters).map((key, i) => {
                if (
                  // @ts-ignore
                  !_.isEmpty(filters[key])
                ) {
                  return (
                    <button
                      key={i}
                      id="filter"
                      className={clsx(
                        "btn   btn-light-primary btn-active-light-primary  symbol  btn-sm text-primary mx-2"
                      )}
                      style={{ borderRadius: "20px" }}
                      onClick={() => {
                        const filtersCopy = { ...filters };
                        // @ts-ignore
                        filtersCopy[key] = null;
                        dispatch(actions.setFilters(filtersCopy));
                      }}
                    >
                      <SVGICON src={CloseIcon} className="svg-icon-3" />
                      {key}
                    </button>
                  );
                } else {
                  return null;
                }
              })}
            </>
          )}

          {checkedPartners.length > 0 ? (
            <>
              {isSelectPartnerType() ? (
                <>
                  <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                    {checkedPartners?.length}{" "}
                    {intl.formatMessage({ id: "SELECTED" })}
                  </span>
                  <button
                    className="btn  btn-primary"
                    onClick={() => {
                      onSelectPartner?.(checkedPartners);
                    }}
                  >
                    {!loading && (
                      <span className="indicator-label">
                        {intl.formatMessage({
                          id: "APPLY_BUTTON",
                        })}
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        {intl.formatMessage({
                          id: "EVENTS_FOLLOW_UP_ADD_LOADING",
                        })}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </>
              ) : (
                <>
                  <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                    {checkedPartners.length}{" "}
                    {intl.formatMessage({ id: "PARTNERS_LIST_SELECTED" })}
                  </span>
                  <button
                    className={"btn btn-danger"}
                    onClick={() => {
                      showDeleteConfirmModal(
                        intl.formatMessage({
                          id: "PARTNERS_LIST_DELETE_CONFIRM_MESSAGE",
                        }),
                        deleteSelectedPartners
                      );
                    }}
                  >
                    {intl.formatMessage({
                      id: "PARTNERS_LIST_DELETE_SELECTED",
                    })}
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-primary me-4"
                onClick={() => {
                  setShowshowPartnersFilterModal(true);
                  if (isSelectPartnerType()) {
                    hideMainModal?.(true);
                  }
                }}
              >
                <SVGICON src={FilterIcon} className="svg-icon-2" />
                {intl.formatMessage({ id: "FILTER_BUTTON" })}
              </button>

              {/* add new post */}
              {crudPermission?.create && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setShowAddPartnerModal(true);
                    if (isSelectPartnerType()) {
                      hideMainModal?.(true);
                    }
                  }}
                >
                  <SVGICON src={PlusIcon} className="svg-icon-2" />
                  {intl.formatMessage({ id: "PARTNERS_ADD_NEW_PARTNER" })}
                </button>
              )}
            </>
          )}
        </div>
      </div>
      {/* end::Header */}

      {/* search text error */}
      <div className="text-danger " style={{ padding: "0 2.25rem" }}>
        <div role="alert">
          {tempSearchText && tempSearchText.length < 3 && !isSelectionModal
            ? intl.formatMessage({
                id: "PARTNERS_LIST_SEARCH_VALIDATION_MESSAGE",
              })
            : ""}
        </div>
      </div>

      {/* begin::Body */}
      <div className="overflow-auto">
        <div
          className="card-body py-3"
          style={
            isSelectionModal
              ? { overflow: "inherit !important", maxHeight: "calc(100vh - 400px)" }
              : { overflow: "inherit !important" }
          }
        >
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
              {/* begin::Table head */}
              <thead>
                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                  {crudPermission?.delete && (
                    <th className="w-25px">
                      <Checkbox
                        onChange={onAllItemsCheckChange}
                        checked={checkAllItems}
                        disabled={isSelectPartnerType()}
                      />
                    </th>
                  )}
                  <th className="min-w-125px">
                    {intl.formatMessage({ id: "PARTNERS_LIST_TITLE" })}
                  </th>
                  <th className="min-w-125px">
                    {intl.formatMessage({ id: "PARTNERS_LIST_ADDRESS" })}
                  </th>
                  <th className="min-w-125px">
                    {intl.formatMessage({ id: "PARTNERS_LIST_LABELS" })}
                  </th>
                  <th className="min-w-125px">
                    {intl.formatMessage({ id: "PARTNERS_LIST_SECTORS" })}
                  </th>
                  {!isSelectPartnerType() && <th className="min-w-100px"></th>}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {APIloading ? (
                  <tr>
                    <td valign="top" colSpan={7} className="dataTables_empty">
                      <SimpleLoader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {partnersList.map((partner, i) => {
                      return (
                        <tr key={i}>
                          {/* checkbox*/}
                          {crudPermission?.delete && (
                            <td>
                              <div className="form-check form-check-sm form-check-custom form-check-solid me-2">
                                <Checkbox
                                  onChange={() => {
                                    onCheckedChange(partner);
                                  }}
                                  checked={
                                    isChecked(partner) ||
                                    partner?.id === newOrganisationCreated?.id
                                  }
                                  // disabled={isSelectPartnerType() && checkedPartners.length > 0}
                                />
                              </div>
                            </td>
                          )}

                          {/* Title */}
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                <div className="symbol-label">
                                  {partner.logo_url ? (
                                    <img
                                      src={partner.logo_url}
                                      alt={partner.name || undefined}
                                      style={{
                                        // minHeight: "100%",
                                        minWidth: "100%",
                                        objectFit: "cover",
                                      }}
                                      className="w-100"
                                    />
                                  ) : (
                                    <div className="symbol-label fs-3 bg-light-danger text-danger text-uppercase">
                                      {(partner.name || " ")[0]}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="d-flex flex-column">
                                <span
                                  //className="text-gray-800 fw-bold text-hover-primary  mb-1"
                                  className={clsx(
                                    "text-gray-800 fw-bold text-hover-primary mb-1"
                                  )}
                                  role="button"
                                  onClick={() => {
                                   
                                      history.push(`/crm/organisation/edit`, {
                                        partnerDetails: partner,
                                      });
                                    
                                  }}
                                >
                                  {partner.name}
                                </span>
                                {/* <span className="text-gray-600 fw-bold">
                                  {partner.code || ""}
                                </span> */}
                              </div>
                            </div>
                          </td>

                          {/* Address */}
                          <td className="mw-250px">
                            {/* @ts-ignore */}
                            {getAddress(partner).length > 0 ? (
                              <span className="text-gray-800">
                                {/* @ts-ignore */}
                                {getAddress(partner)}
                              </span>
                            ) : (
                              <span className="text-muted">
                                {intl.formatMessage({
                                  id: "PARTNER_NO_ADDRESS_MESSAGE",
                                })}
                              </span>
                            )}
                          </td>

                          {/* Labels */}
                          <td className="mw-150px">
                            <div className="d-flex flex-fill flex-row flex-wrap ">
                              {(partner.labels || [])?.map((label, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="badge badge-light fw-bolder me-2 mb-2"
                                  >
                                    {label.name || ""}
                                  </div>
                                );
                              })}
                            </div>
                          </td>

                          {/* Sectors */}
                          <td className="mw-150px">
                            <div className="d-flex flex-fill flex-row flex-wrap ">
                              {(partner.industries_sectors || [])?.map(
                                (industries_sector, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className="badge badge-light fw-bolder me-2 mb-2"
                                    >
                                      {industries_sector.name || ""}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </td>

                          {/* actions */}
                          {!isSelectPartnerType() && (
                            <td>
                              <PartnersListActionsDropdown
                                partner={partner}
                                getPartnersListAPI={getPartnersListAPI}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </>
                )}

                {/* no data */}
                {getPartnersAPICompleted && partnersList.length === 0 && (
                  <tr className="odd">
                    <td valign="top" colSpan={7} className="dataTables_empty">
                      <div className="d-flex flex-column flex-center">
                        <img
                          alt="not-found"
                          src={notFound}
                          className="mw-400px"
                        />
                        <div className="fs-1 fw-bolder text-dark mb-4">
                          {intl.formatMessage({
                            id: "PARTNERS_LIST_NOITEMS_FOUND",
                          })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>

            {/* end::Table */}
            {partnersList.length > 0 && (
              <Pagination
                totalPages={paginationDetails?.number_of_pages || 1}
                activePage={activePage}
                onPageClick={onPageClick}
                showItemsPerPage
                itemsPerPage={itemsPerPage}
                setItemsPerPage={(count) => {
                  setItemsPerPage(count);
                }}
              />
            )}
          </div>
          {/* end::Table container */}
        </div>
      </div>
    </div>
  );
};

export default PartnersList;
